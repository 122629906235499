import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DoorPlanKpis } from '@shared/interfaces/door-plan-kpis.interface';

@Injectable({ providedIn: 'root' })
export class DoorPlanKpisService {
  private defaultValue = {
    remainingCubeLane: 0,
    remainingWeightLane: 0,
    remainingBillCountLane: 0,
    remainingMMLane: 0,
    totalCubeLane: 0,
    totalWeightLane: 0,
    totalBillCountLane: 0,
    totalMMLane: 0,
  };
  private _doorPlanKpiSubject = new BehaviorSubject<DoorPlanKpis>(this.defaultValue);

  doorPlanKpi$ = this._doorPlanKpiSubject.asObservable();

  setDoorPlanKpis(kpis: DoorPlanKpis): void {
    this._doorPlanKpiSubject.next(kpis);
  }
}
