import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  RECOMMENDATION_CODES_MAP,
  RECOMMENDATION_DESCRIPTIONS_MAP,
} from '@app/door-plan-common/constants/static-recommendations-map.record';
import { StaticPlanRecommendationTypes } from '@app/door-plan-common/enums/static-plan-recommendations/static-plan-recommendation-types.enum';
import { AppCacheKeys } from '@app/shared/enums/app-cache-keys.enum';
import { shiftCodesMap } from '@app/shared/enums/shifts';
import { RecommendationBeacon } from '@app/shared/models/static-recommendations/recommendation.beacon.model';
import { RepairBeacon } from '@app/shared/models/static-recommendations/repair-beacon.model';
import { RequirementBeacon } from '@app/shared/models/static-recommendations/requirement-beacon.model';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { NavbarFiltersService } from '@app/shared/services/navbar-filters-service';
import { ListFilteredDoorplanBeacons } from '@xpo-ltl/sdk-beacon';
import { DoorPlanRecommendation, LoadLane } from '@xpo-ltl/sdk-dockoperations';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import {
  GroupedRecommendations,
  GroupedRepairs,
} from '../static-door-recommendations/static-door-recommendations.component';

enum CARD_TYPE {
  FIX = 'FIX',
  REQUIREMENT = 'REQUIREMENT',
  RECOMMENDATION = 'RECOMMENDATION',
}
@Component({
  selector: 'app-recommendations-card',
  templateUrl: './recommendations-card.component.html',
  styleUrls: ['./recommendations-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecommendationsCardComponent implements OnInit {
  @Input()
  fixBeacon: GroupedRepairs;
  @Input()
  recommendationBeacon: DoorPlanRecommendation;
  @Input()
  groupedRecommendations: GroupedRecommendations;
  @Input()
  type: string;

  @Output()
  addDoorMenu = new EventEmitter<any>();

  @Output()
  addDeepLoadingMenu = new EventEmitter<any>();

  @Output()
  fixConfirmed = new EventEmitter<any>();

  description: string = '';
  cardItems: RepairBeacon[] | RequirementBeacon | RecommendationBeacon[];

  title: string = '';
  cardType: CARD_TYPE;
  hideAutoFix: boolean = false;

  private destinations: string[] = [];

  readonly CARD_TYPES = CARD_TYPE;
  readonly RECOMMENDATION_TYPE = StaticPlanRecommendationTypes;
  readonly SHIFT_MAP = shiftCodesMap;

  private _isPanelExpanded = new BehaviorSubject<boolean>(false);
  isPanelExpanded$ = this._isPanelExpanded.asObservable();

  constructor(private localStorageService: LocalStorageService, private navBarFilterService: NavbarFiltersService) {}

  ngOnInit(): void {
    switch (this.type) {
      case StaticPlanRecommendationTypes.FIX_LANE:
        this.title = RECOMMENDATION_CODES_MAP[this.type];
        this.cardType = CARD_TYPE.FIX;
        this.description = RECOMMENDATION_DESCRIPTIONS_MAP[this.fixBeacon?.recommendationTypeCd];
        this.cardItems = this.fixBeacon?.recommendations.map(
          (recommendation: ListFilteredDoorplanBeacons) => new RepairBeacon(recommendation.beacon.message)
        );

        const itemsWithChanges = this.cardItems.filter((item) => item.changes);
        this.checkAutoFixConfirmation();
        this.hideAutoFix = this.hideAutoFix || itemsWithChanges.length === 0;
        break;
      case StaticPlanRecommendationTypes.DELETE_DESTINATIONS:
      case StaticPlanRecommendationTypes.DELETE_DOOR:
      case StaticPlanRecommendationTypes.DELETE_P2_DOOR:
        this.title = RECOMMENDATION_CODES_MAP[this.type];
        this.cardType = CARD_TYPE.FIX;
        this.description = RECOMMENDATION_DESCRIPTIONS_MAP[this.fixBeacon?.recommendationTypeCd];
        this.cardItems = this.fixBeacon?.recommendations.map(
          (recommendation: ListFilteredDoorplanBeacons) => new RepairBeacon(recommendation.beacon.message)
        );
        this.checkAutoFixConfirmation();
        break;
      case StaticPlanRecommendationTypes.INVALID_LANE:
      case StaticPlanRecommendationTypes.MISSING_DESTINATIONS:
        this.cardType = CARD_TYPE.REQUIREMENT;
        this.title = RECOMMENDATION_CODES_MAP[this.type];
        this.description = RECOMMENDATION_DESCRIPTIONS_MAP[this.type];
        this.cardItems = new RequirementBeacon(this.recommendationBeacon.recommendationJson);
        break;
      case StaticPlanRecommendationTypes.DEEP_LOADING:
      case StaticPlanRecommendationTypes.MERGE_LANE:
        this.cardType = CARD_TYPE.RECOMMENDATION;
        this.title = RECOMMENDATION_CODES_MAP[this.type];
        this.description = RECOMMENDATION_DESCRIPTIONS_MAP[this.type];
        this.cardItems = this.groupedRecommendations.recommendations.map(
          (recommendation: DoorPlanRecommendation) => new RecommendationBeacon(recommendation.recommendationJson)
        );
        break;
      case StaticPlanRecommendationTypes.HOLD_TO_DOOR:
        this.cardType = CARD_TYPE.RECOMMENDATION;
        this.title = RECOMMENDATION_CODES_MAP[this.type];
        this.cardItems = this.groupedRecommendations.recommendations.map(
          (recommendation: DoorPlanRecommendation) => new RecommendationBeacon(recommendation.recommendationJson)
        );
        this.description = RECOMMENDATION_DESCRIPTIONS_MAP[this.type];
        break;
    }
  }

  private checkAutoFixConfirmation(): void {
    this.hideAutoFix = !!this.localStorageService.getValue(
      `${AppCacheKeys.CONFIRMED_FIX}-${this.fixBeacon?.profileId}-${this.type}`
    );
  }

  onAssignDoor(event: MouseEvent, destinations: string[]): void {
    this.addDoorMenu.emit({ event, destinations });
  }
  onAssignDeepLoading(event: MouseEvent, loadLane: any): void {
    this.addDeepLoadingMenu.emit({ event, loadLane });
  }

  onOpened(): void {
    this._isPanelExpanded.next(true);
  }

  onClosed(): void {
    this._isPanelExpanded.next(false);
  }

  onConfirm(): void {
    this.localStorageService.setLocalStorage(
      `${AppCacheKeys.CONFIRMED_FIX}-${this.fixBeacon?.profileId}-${this.type}`,
      {
        value: true,
        expiration: moment().endOf('day'),
      }
    );
    this.hideAutoFix = true;
    this.fixConfirmed.emit();
  }
}
