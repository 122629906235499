import { LoadLevelsEnum } from '@shared/enums/load-lane';

export class LoadLaneInfo {
  remainingCubeLane: number;
  remainingWeightLane: number;
  remainingBillCountLane: number;
  remainingMMLane: number;
  totalCubeLane: number;
  totalWeightLane: number;
  totalBillCountLane: number;
  totalMMLane: number;
  loadLevel: LoadLevelsEnum;
  pupCount: number;
}
