import { StaticPlanRecommendationTypes } from '../enums/static-plan-recommendations/static-plan-recommendation-types.enum';

export const RECOMMENDATION_CODES_MAP: Record<string, string> = {
  // Required Fixes
  [StaticPlanRecommendationTypes.INVALID_LANE]: 'Lane Mismatch',
  [StaticPlanRecommendationTypes.MISSING_DESTINATIONS]: 'Missing Destinations',
  // Autofix
  [StaticPlanRecommendationTypes.FIX_LANE]: 'Freight Flow Updates',
  [StaticPlanRecommendationTypes.DELETE_DESTINATIONS]: 'Removed Destinations',
  [StaticPlanRecommendationTypes.DELETE_DOOR]: 'Door Plan Removed',
  [StaticPlanRecommendationTypes.DELETE_P2_DOOR]: 'Door Plan Removed',
  // Recommended changes
  [StaticPlanRecommendationTypes.HOLD_TO_DOOR]: 'Shift Consistency',
  [StaticPlanRecommendationTypes.MERGE_LANE]: 'Possible Merge',
  [StaticPlanRecommendationTypes.DEEP_LOADING]: 'Bypass Opportunity',
};
export const RECOMMENDATION_DESCRIPTIONS_MAP: Record<string, string> = {
  // Required Fixes
  [StaticPlanRecommendationTypes.INVALID_LANE]: 'Your Static plan needs to be updated due to changes in freight flow',
  [StaticPlanRecommendationTypes.MISSING_DESTINATIONS]:
    'Your Static plan needs to be updated due to missing destinations',
  // Autofix
  [StaticPlanRecommendationTypes.FIX_LANE]: 'Your Static plan was updated due to changes in freight flow',
  [StaticPlanRecommendationTypes.DELETE_DOOR]: 'Your Static plan was updated due to removed destinations',
  [StaticPlanRecommendationTypes.DELETE_P2_DOOR]:
    'Your Static plan was updated to remove these mismatched Priority 2 doors',
  [StaticPlanRecommendationTypes.DELETE_DESTINATIONS]: 'Your Static plan was updated due to removed destinations',
  // Recommended changes
  [StaticPlanRecommendationTypes.HOLD_TO_DOOR]: `Static plans on these shifts have mismatched door plans`,
  [StaticPlanRecommendationTypes.MERGE_LANE]: `Your shift usually doesn’t have enough freight for these bypass plans`,
  [StaticPlanRecommendationTypes.DEEP_LOADING]: 'Your shift usually has enough freight for these bypass plans',
};
