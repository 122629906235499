import { Injectable } from '@angular/core';
import { LevelOfDetailEnum } from '@app/shared/enums/level-of-detail.enum';
import {
  FreightFlowApiService,
  GetFreightFlowPathsPath,
  GetFreightFlowPathsQuery,
  GetFreightFlowPathsResp,
  GetMoveToLegsPath,
  GetMoveToLegsResp,
} from '@xpo-ltl-2.0/sdk-freightflow';
import { DataOptions } from '@xpo-ltl/data-api';
import { ListInfo, ShiftCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FreightFlowPathService {
  constructor(private freightFlowApiService: FreightFlowApiService) {}

  getFreightFlowPaths(
    sicCd: string,
    shiftCd: ShiftCd,
    destinationSicCds: string[]
  ): Observable<GetFreightFlowPathsResp> {
    const pathParams = new GetFreightFlowPathsPath();
    const queryParams = new GetFreightFlowPathsQuery();
    pathParams.sicCd = sicCd;
    pathParams.shiftCd = shiftCd;
    queryParams.destinationSicCds = destinationSicCds;
    queryParams.includeMoveLegsInd = true;
    queryParams.listInfo = new ListInfo();
    queryParams.listInfo.levelOfDetail = LevelOfDetailEnum.BASIC;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.freightFlowApiService.getFreightFlowPaths(pathParams, queryParams, dataOptions);
  }

  getMoveToLegs(sicCd: string, shiftCd: ShiftCd): Observable<GetMoveToLegsResp> {
    const pathParams = new GetMoveToLegsPath();
    pathParams.sicCd = sicCd;
    pathParams.shiftCd = shiftCd;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };
    return this.freightFlowApiService.getMoveToLegs(pathParams, dataOptions);
  }
}
