import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { XpoConfirmDialogModule, XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';

import { DoorPlanCommonModule } from '@app/door-plan-common';
import { BulkEditWarningComponent } from '@dialogs/bulk-edit-warning/bulk-edit-warning.component';
import { MultipleFreightFlowPathDialogComponent } from '@dialogs/multiple-freight-flow-path/multiple-freight-flow-path.component';
import { NotAuthorizedComponent } from '@dialogs/not-authorized/not-authorized.component';
import { RemovedStaticPlansDialogComponent } from '@dialogs/removed-static-plans-dialog/removed-static-plans-dialog.component';
import { TrailerLoadingWarningDialogComponent } from '@dialogs/trailer-loading-warning/trailer-loading-warning.component';
import { SharedModule } from '@shared/shared.module';
import { ActiveProfileDialogComponent } from './active-profile/active-profile.component';
import { GenerateProfileDialogComponent } from './generate-profile/generate-profile.component';
import { MisloadDialogComponent } from './misload-dialog/misload-dialog.component';
import { RemoveP1DoorDialogComponent } from './remove-p1-door-dialog/remove-p1-door-dialog.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  TrailerLoadingWarningDialogComponent,
  RemovedStaticPlansDialogComponent,
  MultipleFreightFlowPathDialogComponent,
  BulkEditWarningComponent,
  GenerateProfileDialogComponent,
  ActiveProfileDialogComponent,
];

@NgModule({
  declarations: [...dialogs, RemoveP1DoorDialogComponent, MisloadDialogComponent],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MatIconModule,
    XpoConfirmDialogModule,
    SharedModule,
    DoorPlanCommonModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
