import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { PageEvent as PageChanged } from './models/page-event.model';

@Component({
  selector: 'xpo-paginator',
  templateUrl: './xpo-paginator.component.html',
  styleUrls: ['./xpo-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoPaginatorComponent implements OnInit {
  @Input() length: number = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = Math.max(value, 0);
    this.updateDisplayedPageSizeOptions();
  }
  private _pageSize: number;

  @Input()
  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = value || [];
    this.updateDisplayedPageSizeOptions();
  }

  @Output() readonly pageChanged: EventEmitter<PageChanged> = new EventEmitter<PageChanged>();

  private _pageSizeOptions: number[] = [];
  private readonly DEFAULT_PAGE_SIZE = 10;
  displayedPageSizeOptions: number[] = [];

  pageIndex = 0;
  totalPages: number = 0;
  fromIndicator: number = 0;
  toIndicator: number = 0;

  constructor() {}

  ngOnInit() {
    this.updateDisplayedPageSizeOptions();
    this.updateFromToLabel();
  }

  private updateDisplayedPageSizeOptions() {
    if (!this.pageSize) {
      this._pageSize = this.pageSizeOptions.length !== 0 ? this.pageSizeOptions[0] : this.DEFAULT_PAGE_SIZE;
    }

    this.displayedPageSizeOptions = this.pageSizeOptions.slice();

    if (this.displayedPageSizeOptions.indexOf(this.pageSize) === -1) {
      this.displayedPageSizeOptions.push(this.pageSize);
    }
    this.displayedPageSizeOptions.sort((a, b) => a - b);
    this.totalPages = this.getNumberOfPages();
  }

  changePageSize(pageSize: number) {
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this.totalPages = this.getNumberOfPages();
    this.updateFromToLabel();
    this.emitPageEvent(previousPageIndex);
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex++;
    this.updateFromToLabel();
    this.emitPageEvent(previousPageIndex);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex--;
    this.updateFromToLabel();
    this.emitPageEvent(previousPageIndex);
  }

  firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this.updateFromToLabel();
    this.emitPageEvent(previousPageIndex);
  }

  lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages() - 1;
    this.updateFromToLabel();
    this.emitPageEvent(previousPageIndex);
  }

  private hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  private hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  private getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    return Math.ceil(this.length / this.pageSize);
  }

  private updateFromToLabel() {
    this.fromIndicator = this.pageIndex * this.pageSize + 1;
    const calculatedToIndicator = this.pageIndex * this.pageSize + this.pageSize;
    this.toIndicator = calculatedToIndicator <= this.length ? calculatedToIndicator : this.length;
  }

  private emitPageEvent(previousPageIndex: number) {
    this.pageChanged.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }
}
