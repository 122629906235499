import { Equipment } from '@xpo-ltl/sdk-dockoperations';
import { Route as DockRoute } from '@xpo-ltl/sdk-dockoperations';

export class Route extends DockRoute {
  trailerInfo: Equipment;

  static createComposedRoute(dockRoute: DockRoute, trailerInfo: Equipment): Route {
    return { ...dockRoute, trailerInfo };
  }
}
