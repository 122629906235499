import { FormBuilder, FormGroup } from '@angular/forms';

import { DeepLoadingFormNames } from '@app/door-plan-common/enums/deep-loading/deep-loading-form-names.enum';
import { DeepLoadingDefaultFilterValues } from '@shared/enums/deep-loading-default-values.enum';

export class DeepLoadingFormBuilder {
  static create(fb: FormBuilder): FormGroup {
    return fb.group({
      [DeepLoadingFormNames.weightThreshold]: fb.control(DeepLoadingDefaultFilterValues.DEFAULT_WEIGHT),
      [DeepLoadingFormNames.cubeThreshold]: fb.control(DeepLoadingDefaultFilterValues.DEFAULT_CUBE),
      [DeepLoadingFormNames.moveTo]: fb.control(null),
      [DeepLoadingFormNames.sector]: fb.control(''),
    });
  }
}
