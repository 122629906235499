import { TemplateRef } from '@angular/core';

export abstract class XpoBaseContextMenuItem<T> {
  icon?: string;
  xpoIcon?: string;
  /** Should be unique */
  name?: string;
  label?: string;
  labelTpl?: TemplateRef<any>;
  action?: (contextData: T) => void;
  isCustom?: boolean;
  disable?: boolean = false;
}
