import { Injectable } from '@angular/core';
import { DateHelper } from '@app/shared/classes/date-helper';
import { DataOptions } from '@xpo-ltl/data-api';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import {
  BulkUpdateEquipmentStatusRemarkResp,
  BulkUpdateEquipmentStatusRemarkRqst,
  BulkUpdateTrailerLoadStatusDoorRemarksPath,
  BulkUpdateTrailerLoadStatusDoorRemarksResp,
  BulkUpdateTrailerLoadStatusDoorRemarksRqst,
  DockOperationsApiService,
  Equipment,
  ListDockShipmentSummariesPath,
  ListDockShipmentSummariesQuery,
  ListEquipmentBySicPath,
  ListEquipmentBySicQuery,
  ListEquipmentBySicResp,
  LoadLaneDestination,
} from '@xpo-ltl/sdk-dockoperations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EquipmentHelper } from '../../classes/equipment-helper';
import { BulkUpdateEquipment } from '../../models/bulk-update-equipment.model';
import { BulkUpdateTrailer } from '../../models/bulk-update-trailer.model';
import { TransactionTimestampInterceptor } from '../interceptors/transaction-timestamp.interceptor';

@Injectable({
  providedIn: 'root',
})
export class DockOperationsService {
  constructor(private dockOperationsApiService: DockOperationsApiService) {}

  listEquipmentBySic(
    sicCode: string,
    queryParams?: Partial<ListEquipmentBySicQuery>
  ): Observable<ListEquipmentBySicResp> {
    const pathParams = new ListEquipmentBySicPath();
    pathParams.sicCd = sicCode;
    const _queryParams = <ListEquipmentBySicQuery>queryParams || new ListEquipmentBySicQuery();
    const dataOptions: DataOptions = {
      // To avoid double spinner in the screen when using board
      loadingOverlayEnabled: false,
    };
    return this.dockOperationsApiService.listEquipmentBySic(pathParams, _queryParams, dataOptions);
  }

  bulkUpdateEquipmentStatusRemark(
    bulkUpdateEquipment: BulkUpdateEquipment,
    equipmentsList: Equipment[]
  ): Observable<BulkUpdateEquipmentStatusRemarkResp> {
    const request: BulkUpdateEquipmentStatusRemarkRqst = {
      equipmentStatusRemarks: equipmentsList.map((equipment) =>
        EquipmentHelper.mapEquipmentWithData(equipment, bulkUpdateEquipment)
      ),
    };
    return this.dockOperationsApiService.bulkUpdateEquipmentStatusRemark(
      request,
      null,
      TransactionTimestampInterceptor.useTransactionTimestampHeader
    );
  }

  bulkUpdateTrailerLoadStatusDoorRemarks(
    bulkUpdateTrailer: BulkUpdateTrailer,
    trailerLoadList: Equipment[]
  ): Observable<BulkUpdateTrailerLoadStatusDoorRemarksResp> {
    const request = new BulkUpdateTrailerLoadStatusDoorRemarksRqst();
    const currentSic = trailerLoadList[0].currentSic;
    const trailerLoadStatusDoorRemarksList = trailerLoadList.map((equipment) =>
      EquipmentHelper.mapTrailerWithData(equipment, bulkUpdateTrailer)
    );
    request.trailerLoadStatusDoorRemarks = trailerLoadStatusDoorRemarksList;
    const params = new BulkUpdateTrailerLoadStatusDoorRemarksPath();
    params.sicCd = currentSic;
    return this.dockOperationsApiService.bulkUpdateTrailerLoadStatusDoorRemarks(
      request,
      params,
      null,
      TransactionTimestampInterceptor.useTransactionTimestampHeader
    );
  }

  listDockShipmentSummaries(sic: string, shift: ShiftCd, planDate: Date): Observable<LoadLaneDestination[]> {
    const path = new ListDockShipmentSummariesPath();
    path.sicCd = sic;
    path.shiftCd = shift;
    const query = new ListDockShipmentSummariesQuery();
    query.planDate = DateHelper.getFormatDate(planDate, 'YYYY-MM-DD');
    return this.dockOperationsApiService.listDockShipmentSummaries(path, query).pipe(
      map((response) => {
        if (!response || !response.dockShipmentSummaries) {
          return [];
        }
        return response.dockShipmentSummaries;
      })
    );
  }
}
