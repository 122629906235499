import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  BeaconApiService,
  ListFilteredDoorplanBeaconsResp,
  ListFilteredDoorplanBeaconsRqst,
} from '@xpo-ltl/sdk-beacon';
import { FilterCriteria, FilterDate, FilterDateBetween, FilterNumber } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BeaconService {
  constructor(private beaconApiService: BeaconApiService) {}

  getStaticRepairBeaconMessages(doorPlanProfileId: number, date: Date): Observable<ListFilteredDoorplanBeaconsResp> {
    const filterDateBetween = new FilterDateBetween();
    filterDateBetween.from = date;
    const filterDate = new FilterDate();
    filterDate.field = 'crteTmst';
    filterDate.between = filterDateBetween;

    const numberFilter = new FilterNumber();
    numberFilter.field = 'doorplanProfileId';
    numberFilter.values = [doorPlanProfileId];

    const request = new ListFilteredDoorplanBeaconsRqst();
    request.filter = new FilterCriteria();
    request.filter.dateFilters = [filterDate];
    request.filter.numberFilters = [numberFilter];

    const dataOptions: DataOptions = {
      loadingOverlayEnabled: true,
    };
    return this.beaconApiService.listFilteredDoorplanBeacons(request, dataOptions);
  }
}
