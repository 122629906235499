import { TrailerLoad } from '@xpo-ltl/sdk-dockoperations';

import { cloneDeep as _cloneDeep } from 'lodash';

import { DoorPlanActionsEnum } from '@shared/enums/door-plan';
import { DoorPlanItem, GTActionData } from '@shared/models/door-plan';
import { AppAction } from '@shared/types/actions.type';

export class DoorPlanActionHelper {
  static getToastMessageByActionGTA(actionData: GTActionData, action: AppAction): string {
    let toastMessage: string = '';

    switch (action) {
      case DoorPlanActionsEnum.PERFORM_COPY:
      case DoorPlanActionsEnum.PERFORM_MOVE:
      case DoorPlanActionsEnum.PERFORM_MERGE:
      case DoorPlanActionsEnum.PERFORM_SWAP:
        const doorSectorNumberFrom = `${actionData.firstRow.doorSectorNbr} - ${actionData.firstRow.doorNbr}`;
        const doorSectorNumberTo = `${actionData.secondRow.doorSectorNbr} - ${actionData.secondRow.doorNbr}`;

        switch (action) {
          case DoorPlanActionsEnum.PERFORM_COPY:
            toastMessage = `Door ${doorSectorNumberFrom} Plan has been copied to Door ${doorSectorNumberTo} Plan`;
            break;

          case DoorPlanActionsEnum.PERFORM_MOVE:
            toastMessage = `Door ${doorSectorNumberFrom} Plan has been moved to Door ${doorSectorNumberTo} Plan`;
            break;

          case DoorPlanActionsEnum.PERFORM_MERGE:
            toastMessage = `Door ${doorSectorNumberFrom} Plan has been merged with Door ${doorSectorNumberTo} Plan`;
            break;

          case DoorPlanActionsEnum.PERFORM_SWAP:
            toastMessage = `Door ${doorSectorNumberFrom} Plan has been switched with Door ${doorSectorNumberTo} Plan`;
            break;
        }
        break;
      case DoorPlanActionsEnum.PERFORM_REMOVE:
        toastMessage = `Door ${actionData.firstRow.doorSectorNbr} - ${actionData.firstRow.doorNbr} Plan has been removed`;
        break;
      case DoorPlanActionsEnum.PERFORM_ADD:
        toastMessage = `Door ${actionData.firstRow.doorNbr} has been created`;
        break;
      case DoorPlanActionsEnum.REMOVE_STATIC:
        toastMessage = `Door ${actionData.firstRow.doorNbr} has been removed from static profile`;
        break;
      case DoorPlanActionsEnum.MAKE_STATIC:
        toastMessage = `Door ${actionData.firstRow.doorNbr} has been added to static profile`;
        break;
    }

    return toastMessage;
  }

  static getToastMessageByActionDoorPlanItem(
    doorPlans: DoorPlanItem[],
    action: AppAction,
    actionData?: GTActionData
  ): string {
    let toastMessage: string = '';
    let complement: string = '';

    const sectorDoors = doorPlans.map((doorPlanItem) => doorPlanItem.doorNbr).sort();
    switch (action) {
      case DoorPlanActionsEnum.PERFORM_RESET:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'has been reset to its Default Plan',
          'have been reset to their Default Plan',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER:
        complement = actionData?.firstRow?.doorPreference?.stepSaverExcludeInd ? 'no longer' : '';
        toastMessage = this.getMessageByActionDoorPlanItem(
          `is ${complement} excluded from step saver`,
          'are excluded from step saver',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT:
        complement = actionData?.firstRow?.doorPreference?.trapInd
          ? 'no longer held'
          : 'now being held for the next shift';
        toastMessage = this.getMessageByActionDoorPlanItem(
          `is ${complement}`,
          'are now being held for the next shift',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.EMBARGO_DOOR:
        toastMessage = this.getMessageByActionDoorPlanItem(`is now embargoed`, 'are now embargoed', sectorDoors);
        break;
      case DoorPlanActionsEnum.REMOVE_EMBARGO:
        toastMessage = this.getMessageByActionDoorPlanItem(
          `is no longer embargoed`,
          'are no longer embargoed',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.MARK_UNLOAD:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'has been mark as unload',
          'have been mark as unload',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.REMOVE_HOLD:
        toastMessage = this.getMessageByActionDoorPlanItem('is no longer held', 'are no longer held', sectorDoors);
        break;
      case DoorPlanActionsEnum.REMOVE_EXCLUSION:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'is no longer excluded from step saver',
          'are no longer excluded from step saver',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.REMOVE_UNLOAD:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'is no longer mark as unload',
          'are no longer mark as unload',
          sectorDoors
        );
        break;
      case DoorPlanActionsEnum.REMOVE_STATIC:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'has been removed from static profile',
          'have been removed from static profile',
          sectorDoors,
          sectorDoors.length
        );
        break;
      case DoorPlanActionsEnum.MAKE_STATIC:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'has been added to static profile',
          'have been added to static profile',
          sectorDoors,
          sectorDoors.length
        );
        break;
    }

    return toastMessage;
  }

  static getToastMessageByActionTrailer(trailer: TrailerLoad, action: AppAction): string {
    let toastMessage: string = '';

    switch (action) {
      case DoorPlanActionsEnum.ASSIGN_TRAILER:
        toastMessage = `Trailer ${trailer.equipmentIdPrefix}-${trailer.equipmentIdSuffixNbr} assigned to Door ${trailer.evntDoor}`;
        break;
      case DoorPlanActionsEnum.UPDATE_TRAILER:
        toastMessage = `Successfully updated status for trailer ${trailer.equipmentIdPrefix}-${trailer.equipmentIdSuffixNbr}`;
        break;
    }

    return toastMessage;
  }

  static getToastErrorMessageByActionDoorPlanItem(doorPlans: DoorPlanItem[], action: AppAction): string {
    let toastMessage: string = '';
    const sectorDoors = doorPlans.map((doorPlanItem) => `${doorPlanItem.doorNbr}`).sort();

    switch (action) {
      case DoorPlanActionsEnum.MAKE_STATIC:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'was not added to static profile as a plan already exists for these door on static profile',
          'were not added to static profile as a plan already exists for these door on static profile',
          sectorDoors,
          sectorDoors.length
        );
        break;
      case DoorPlanActionsEnum.REMOVE_STATIC:
        toastMessage = this.getMessageByActionDoorPlanItem(
          'could not be removed from static profile',
          'could not be removed from static profile',
          sectorDoors,
          sectorDoors.length
        );
        break;
    }

    return toastMessage;
  }

  static getInfoToast(doorPlanItem: DoorPlanItem, action: AppAction) {
    const doorSectorNumber = `${doorPlanItem.doorSectorNbr} - ${doorPlanItem.doorNbr}`;
    let toastMessage: string = '';

    switch (action) {
      case DoorPlanActionsEnum.PERFORM_COPY:
        toastMessage = `Select destination to copy Door ${doorSectorNumber}`;
        break;

      case DoorPlanActionsEnum.PERFORM_MOVE:
        toastMessage = `Select destination to move Door ${doorSectorNumber}`;
        break;

      case DoorPlanActionsEnum.PERFORM_MERGE:
        toastMessage = `Select destination to merge Door ${doorSectorNumber}`;
        break;
    }

    return toastMessage;
  }

  static getDoorsForDockDoorPrerencesAction(action: DoorPlanActionsEnum, doors: DoorPlanItem[]): DoorPlanItem[] {
    let processedDoors: DoorPlanItem[];

    switch (action) {
      case DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER:
        processedDoors = doors.map((door) => {
          const newDoor: DoorPlanItem = _cloneDeep(door);
          newDoor.doorPreference.stepSaverExcludeInd = true;
          return newDoor;
        });
        break;
      case DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT:
        processedDoors = doors
          .filter((door) => !door.doorPreference?.stepSaverExcludeInd)
          .map((door) => {
            const newDoor: DoorPlanItem = _cloneDeep(door);
            newDoor.doorPreference.trapInd = true;
            return newDoor;
          });
        break;
      case DoorPlanActionsEnum.EMBARGO_DOOR:
        processedDoors = doors.map((door) => {
          const newDoor: DoorPlanItem = _cloneDeep(door);
          newDoor.doorPreference.embargoInd = true;
          return newDoor;
        });
        break;
      case DoorPlanActionsEnum.REMOVE_EMBARGO:
        processedDoors = doors.map((door) => {
          const newDoor: DoorPlanItem = _cloneDeep(door);
          newDoor.doorPreference.embargoInd = false;
          return newDoor;
        });
        break;
      case DoorPlanActionsEnum.MARK_UNLOAD:
        /** We will ignore empty & on-hold doors for this action, without warning.
         * See https://jira.xpo.com/browse/LDOC-2175 */
        processedDoors = doors
          .filter((door) => !door.isEmpty() && !door.doorPreference?.trapInd)
          .map((door) => {
            const newDoor: DoorPlanItem = _cloneDeep(door);
            newDoor.doorPreference.loadUnloadInd = true;
            return newDoor;
          });
        break;
      case DoorPlanActionsEnum.REMOVE_EXCLUSION:
        processedDoors = doors.map((door) => {
          const newDoor: DoorPlanItem = _cloneDeep(door);
          newDoor.doorPreference.stepSaverExcludeInd = false;
          return newDoor;
        });
        break;
      case DoorPlanActionsEnum.REMOVE_HOLD:
        processedDoors = doors.map((door) => {
          const newDoor: DoorPlanItem = _cloneDeep(door);
          newDoor.doorPreference.trapInd = false;
          return newDoor;
        });
        break;
      case DoorPlanActionsEnum.REMOVE_UNLOAD:
        /** We will ignore empty doors for this action, without warning. See https://jira.xpo.com/browse/LDOC-2175 */
        processedDoors = doors
          .filter((door) => !door.isEmpty())
          .map((door) => {
            const newDoor: DoorPlanItem = _cloneDeep(door);
            newDoor.doorPreference.loadUnloadInd = false;
            return newDoor;
          });
        break;
    }

    return processedDoors;
  }

  private static getMessageByActionDoorPlanItem(
    singleDoorMessage: string,
    multipleDoorsMessage: string,
    sectorDoors: string[],
    multipleRecordsMessageMin = 5
  ): string {
    if (sectorDoors.length === 1) {
      return `Door ${sectorDoors[0]} ${singleDoorMessage}`;
    } else if (sectorDoors.length <= multipleRecordsMessageMin) {
      const sDlast = sectorDoors.length - 1;
      const sDPenultimate = sDlast - 1;
      const complement = sectorDoors
        .map((sectorDoor, index) => {
          if (index === sDlast) {
            return `and ${sectorDoor} `;
          } else if (index === sDPenultimate) {
            return `${sectorDoor} `;
          } else {
            return `${sectorDoor}, `;
          }
        })
        .join('');

      return `Doors ${complement} ${multipleDoorsMessage}`;
    } else {
      return `The Doors ${multipleDoorsMessage}`;
    }
  }
}
