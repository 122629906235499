import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PageEvent } from '@angular/material/paginator';

import { FreightFlowPath } from '@xpo-ltl-2.0/sdk-freightflow';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { MoveModeCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LaneTypeApp } from '@shared/enums/lane-type.enum';
import { DoorPlanDestination, DoorPlanItem } from '@shared/models/door-plan';
import { CurrentDoorPlanProfileService } from '@shared/services/current-door-plan-profile/current-door-plan-profile.service';
import { FreightFlowPathService } from '@shared/services/freight-flow-path/freight-flow-path.service';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service/filters-service.service';
import { DestinationsFilterComponent } from '../destinations-filter/destinations-filter.component';

@Component({
  selector: 'app-door-plan-card',
  templateUrl: './door-plan-card.component.html',
  styleUrls: ['./door-plan-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-door-plan-card' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoorPlanCardComponent implements OnInit, OnDestroy {
  @Input() doorPlan: DoorPlanItem = null;
  @Input() doorPlanArray: DoorPlanItem[] = null;
  @Input() loadLaneView: boolean = false;

  @HostBinding('class.app-door-plan-card_condensed')
  @Input()
  get condensed(): boolean {
    return this.condensedValue;
  }
  set condensed(v: boolean) {
    this.condensedValue = v;
  }
  private condensedValue = false;

  @Output()
  contextMenu = new EventEmitter<MouseEvent>();
  @Output()
  closeMenu = new EventEmitter<MouseEvent>();
  @Output()
  addDoorMenu = new EventEmitter<MouseEvent>();
  @Output()
  trailerMenu = new EventEmitter<MouseEvent>();
  @Output()
  select = new EventEmitter<DoorPlanItem>();

  @ViewChild(DestinationsFilterComponent) destinationFilter: DestinationsFilterComponent;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  private _isPanelExpanded = new BehaviorSubject<boolean>(false);
  isPanelExpanded$ = this._isPanelExpanded.asObservable();
  private cancelRequest$ = new Subject<void>();
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable();

  readonly MOVE_MODE_CD = MoveModeCd;
  readonly LANE_TYPES = LaneTypeApp;

  destinationsString: string = '';
  priorityIndicator: string;
  laneTypeCode: string;
  listedDestinations: DoorPlanDestination[] = [];
  selectedDestinations: DoorPlanDestination[] = [];
  isCurrentProfileStatic: boolean;
  // This happens when the doorNbr does not exist in the SIC
  hasDoorNumber: boolean;

  readonly defaultPageSize = 10;
  readonly sizeOptions = [10, 15, 20, 25];

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(
    private freightFlowPathService: FreightFlowPathService,
    private filtersService: NavbarFiltersService,
    private snackBar: XpoSnackBar,
    private currentProfileService: CurrentDoorPlanProfileService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.cancelRequest$.complete();
  }

  ngOnInit() {
    this.getDestinationsList();
    this.priorityIndicator = this.doorPlan.getPriorityIndicator(this.doorPlanArray);
    this.laneTypeCode = this.doorPlan.getLaneTypeIcon();
    this.selectedDestinations = this.doorPlan.scoDoorPlanDestination;
    this.hasDoorNumber = !!this.doorPlan.doorNbr;
    this.isCurrentProfileStatic = this.currentProfileService.getCurrentDoorPlanProfile()?.isStaticDoorPlanProfile();
  }

  onCardContextMenu(event: MouseEvent) {
    if (!this.hasDoorNumber) {
      return;
    }
    this.contextMenu.emit(event);
  }

  onOpened(): void {
    this._isPanelExpanded.next(true);
    this.listedDestinations = this.getListedDestinations(0, this.defaultPageSize);
    this.getFreightFlowPaths(this.selectedDestinations, this.defaultPageSize);
  }

  onClosed(): void {
    this._isPanelExpanded.next(false);
    this.cancelRequest$.next();
  }

  onCollapsed(): void {
    if (this.destinationFilter) {
      this.destinationFilter.clearFilter();
    }
  }

  pageEvent(event: PageEvent): void {
    this.cancelRequest$.next();
    this.getFreightFlowPaths(this.selectedDestinations, event.pageSize, event.pageIndex);
    this.listedDestinations = this.getListedDestinations(event.pageIndex, event.pageSize);
  }

  setFilteredDestinations(selectedDestinations: DoorPlanDestination[]): void {
    this.selectedDestinations =
      selectedDestinations && selectedDestinations.length ? selectedDestinations : this.doorPlan.scoDoorPlanDestination;
    this.listedDestinations = this.selectedDestinations.slice(0, this.defaultPageSize);
    this.getFreightFlowPaths(this.selectedDestinations, this.defaultPageSize);
  }

  onAddDoorClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.addDoorMenu.emit(event);
  }

  sortDestinations(sortedDestinations: DoorPlanDestination[]): void {
    // We can override the original, because there is not option to revert the sort
    this.doorPlan.scoDoorPlanDestination = sortedDestinations;
    // To Sort the filter destinations
    this.setFilteredDestinations(this.selectedDestinations);
  }

  onOpenTrailerMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.trailerMenu.emit(event);
  }

  onSelectCard(event: MouseEvent): void {
    this.select.emit(this.doorPlan);
    this.closeMenu.emit(event);
    event.stopPropagation();
  }

  togglePanel(event: MouseEvent): void {
    this.expansionPanel.toggle();
    this.closeMenu.emit(event);
    event.stopPropagation();
  }

  private getFreightFlowPaths(
    destinationList: DoorPlanDestination[],
    currentPageSize: number,
    currentPageIndex = 0
  ): void {
    const skip = currentPageSize * currentPageIndex;
    const limit = currentPageSize;
    if (!destinationList || !destinationList.length) {
      return;
    }

    const destinationsChunk: DoorPlanDestination[] = destinationList
      .slice(skip, skip + limit)
      .filter((destination) => !destination.freightFlowPath);

    if (!destinationsChunk || !destinationsChunk.length) {
      return;
    }

    this._isLoading.next(true);
    this.freightFlowPathService
      .getFreightFlowPaths(
        this.filtersService.selectedSic,
        this.filtersService.selectedShift,
        destinationsChunk.map(({ destinationSicCode }) => destinationSicCode)
      )
      .pipe(takeUntil(this.unsubscriber.done$), takeUntil(this.cancelRequest$))
      .subscribe(
        (response) => {
          this.setFreightFlowPaths(destinationsChunk, response.freightFlowPaths);
          this._isLoading.next(false);
          this.cd.markForCheck();
        },
        () => {
          this._isLoading.next(false);
          this.snackBar.error('Error found while loading Freight Flow Paths');
          this.cd.markForCheck();
        }
      );
  }

  private setFreightFlowPaths(destinations: DoorPlanDestination[], freightFlowPaths: FreightFlowPath[]): void {
    destinations.map((destination) => destination.setFreightFlowPath(freightFlowPaths));
  }

  private getListedDestinations(pageIndex: number, pageSize: number): DoorPlanDestination[] {
    if (!this.selectedDestinations) {
      return;
    }
    return this.selectedDestinations.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
  }

  private getDestinationsList(): void {
    if (this.doorPlan && this.doorPlan.scoDoorPlanDestination) {
      this.destinationsString = this.doorPlan.scoDoorPlanDestination.reduce(
        (destinationCodes, destination, index, destinationArray) => {
          destinationCodes += destination.destinationSicCode;
          return index < destinationArray.length - 1 ? (destinationCodes += ', ') : destinationCodes;
        },
        ''
      );
    }
  }
}
