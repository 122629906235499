import { Injectable } from '@angular/core';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationsService } from '../locations-services/locations.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarFiltersService {
  private selectedSicSubject = new BehaviorSubject<string>(undefined);
  private selectedShiftSubject = new BehaviorSubject<ShiftCd>(undefined);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  selectedSic$: Observable<string> = this.selectedSicSubject.asObservable();
  selectedShift$: Observable<ShiftCd> = this.selectedShiftSubject.asObservable();
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(private locationService: LocationsService) {}

  get selectedSic(): string {
    return this.selectedSicSubject.value;
  }

  get selectedShift(): ShiftCd {
    return this.selectedShiftSubject.value;
  }

  get loadingState(): boolean {
    return this.isLoadingSubject.value;
  }

  setSelectedSic(sicCd: string): void {
    if (sicCd !== this.selectedSic) {
      this.locationService.locationFeatures('STATIC_DOORPLAN', sicCd).subscribe(() => {
        this.selectedSicSubject.next(sicCd);
        this.setLoadingState(true);
      });
    }
  }

  setSelectedShift(shiftCd: ShiftCd): void {
    if (shiftCd !== this.selectedShift) {
      this.selectedShiftSubject.next(shiftCd);
      this.setLoadingState(true);
    }
  }

  setLoadingState(state: boolean) {
    this.isLoadingSubject.next(state);
  }
}
