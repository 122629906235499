import { LoadLane } from '@xpo-ltl/sdk-dockoperations';

export class RepairBeacon {
  doorNbr: string;
  oldLoadLane: LoadLane;
  newLoadLane: LoadLane;
  loadLane: LoadLane;
  destinationSicCodes: LoadLane;
  destinationsString: string;
  changes: boolean;

  constructor(beaconMessage: string) {
    if (beaconMessage) {
      const parsedBeacon = JSON.parse(beaconMessage);
      this.doorNbr = parsedBeacon.doorNumber;
      this.oldLoadLane = parsedBeacon.oldLoadLane;
      this.newLoadLane = parsedBeacon.newLoadLane;
      this.loadLane = parsedBeacon.loadLane;
      this.destinationSicCodes = parsedBeacon.destinationSicCodes;
      this.destinationsString = parsedBeacon.destinationSicCodes?.join(', ');
      this.changes =
        this.oldLoadLane && this.newLoadLane ? this.hasChanges(this.oldLoadLane, this.newLoadLane) : undefined;
    }
  }

  hasChanges(oldLane: any, newLane: any): boolean {
    // If changes in closeTo and moveTo between old and new lanes.
    return oldLane.closeToSicCode === newLane.closeToSicCode && oldLane.moveToSicCode === newLane.moveToSicCode
      ? false
      : true;
  }
}
