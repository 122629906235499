import { TitleCasePipe } from '@angular/common';

import { ActiveSic, ListLocationsResp, LocationSic } from '@xpo-ltl-2.0/sdk-location';

export class LocationHelper {
  /** Output eg: SIC - Portland, OR */
  static getVisibleName(location: LocationSic | ActiveSic, titleCasePipe?: TitleCasePipe): string {
    if (!location) {
      return '';
    }
    let text = location.sicCd;
    if (location.city) {
      const city = titleCasePipe ? titleCasePipe.transform(location.city) : location.city;
      text += ` - ${city}`;
    }
    if (location.state) {
      text += `, ${location.state}`;
    }
    return text;
  }

  /**
   * Get firt zone or terminal from child to parent, having a location
   */
  static getFirsParentSatelliteSicCdFromLocation(location: LocationSic): string {
    if (location.aSatelliteSic || location['asatelliteSic']) {
      // There is a sdk issue
      return location.parentSatelliteSic;
    }

    return location.sicCd;
  }

  /**
   * Get firt zone or terminal from child to parent, having a sicCd
   */
  static getFirsParentSatelliteSicCd(sicCd: string, { locationSic }: ListLocationsResp): string {
    if (!sicCd) {
      return;
    }
    const location = locationSic.find((activeSic) => activeSic.sicCd === sicCd);
    if (!location) {
      return;
    }

    return LocationHelper.getFirsParentSatelliteSicCdFromLocation(location);
  }

  /**
   * Get last parent or grand parent (terminal) from child to parent, having a location
   */
  static getLastParentSatelliteSicCdCdFromLocation(location: LocationSic, { locationSic }: ListLocationsResp): string {
    let parentSatelliteSic: string;

    if (location.aSatelliteSic || location['asatelliteSic']) {
      // There is a sdk issue
      parentSatelliteSic = location.parentSatelliteSic;
    } else if (location.aZoneSic || location['azoneSic']) {
      // There is a sdk issue
      parentSatelliteSic = location.zoneTerminalSic;
    }
    const grandParentSic = LocationHelper.getLastParentSatelliteSicCd(parentSatelliteSic, { locationSic });

    return grandParentSic ? grandParentSic : parentSatelliteSic;
  }

  /**
   * Get last parent or grand parent (terminal) from child to parent, having a sicCd
   */
  static getLastParentSatelliteSicCd(sicCd: string, { locationSic }: ListLocationsResp): string {
    if (!sicCd) {
      return;
    }
    const location = locationSic.find((activeSic) => activeSic.sicCd === sicCd);
    if (!location) {
      return;
    }

    return LocationHelper.getLastParentSatelliteSicCdCdFromLocation(location, { locationSic });
  }
}
