import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, Directive, ElementRef, Input, NgZone } from '@angular/core';
import { filter, take } from 'rxjs/operators';

@Directive({
  selector: '[appElementFocusOnEvent]',
})
export class AppElementFocusOnEventDirective implements AfterContentInit {
  private isInitialized: boolean = false;
  private shouldApplyFocus: boolean = false;

  @Input('appElementFocusOnEvent')
  set apply(value: boolean) {
    this.shouldApplyFocus = coerceBooleanProperty(value);
    this.focusElement();
  }

  constructor(private elRef: ElementRef, private zone: NgZone) {}

  ngAfterContentInit(): void {
    this.isInitialized = true;

    this.focusElement();
  }

  private focusElement(): void {
    this.zone.onStable
      .asObservable()
      .pipe(
        take(1),
        filter(() => this.isInitialized && this.shouldApplyFocus)
      )
      .subscribe(() => {
        this.elRef.nativeElement.focus();
      });
  }
}
