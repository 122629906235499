import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

import { LocationSic } from '@xpo-ltl-2.0/sdk-location';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { DynamicFilterFields } from '@app/shared/enums/door-plan/dynamic-filter-fields.enum';

export class ActiveSicFormHelper {
  static setDynamicFilter(sicCodes$: Observable<LocationSic[]>, controlArray: FormArray): Observable<LocationSic[]> {
    return sicCodes$.pipe(
      tap((filterSicCodes) => {
        const sortFilterSicCodes = _.sortBy(filterSicCodes, 'sicCd');
        const mappedFilterSicCodes = _.map(sortFilterSicCodes, 'sicCd');
        const currentFilterData = [];
        controlArray.value.forEach((control) => {
          currentFilterData.push(control.sicCd);
        });
        if (!_.isEqual(currentFilterData, mappedFilterSicCodes)) {
          controlArray.clear();
          for (const sic of sortFilterSicCodes) {
            controlArray.push(
              new FormGroup({
                [DynamicFilterFields.SIC_CD]: new FormControl(sic.sicCd),
                [DynamicFilterFields.CITY]: new FormControl(sic.city),
                [DynamicFilterFields.STATE]: new FormControl(sic.state),
                [DynamicFilterFields.SELECTED]: new FormControl(false),
                [DynamicFilterFields.INDEX]: new FormControl(`${controlArray.length}`),
              })
            );
          }
        }
      })
    );
  }

  static getFilteredActiveSics(formControl: AbstractControl, options: LocationSic[]): Observable<LocationSic[]> {
    return formControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : this.getVisibleName(value))),
      map((name: string) => this.filterObjects(name, options)),
      map((activeSics) => _.sortBy(activeSics, 'sicCd'))
    );
  }

  // Filter function object list
  static filterObjects(name: string, options: LocationSic[] = []): LocationSic[] {
    if (!name) {
      return !!options ? options.slice() : [];
    }
    const filterValue = name.toLowerCase();

    return options.filter((option) => {
      const filterName = this.getVisibleName(option);
      return filterName.toLowerCase().includes(filterValue);
    });
  }

  static getVisibleName(activeSic: LocationSic): string {
    if (!activeSic) {
      return '';
    }
    let visibleName = activeSic.sicCd;
    if (activeSic.city) {
      visibleName += ` - ${activeSic.city}`;
    }
    if (activeSic.state) {
      visibleName += `, ${activeSic.state}`;
    }
    return visibleName;
  }
}
