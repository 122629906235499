export enum LaneTypeApi {
  LINEHAUL = 'LINEHAUL',
  EXCLUSIVE = 'EXCLUSIVE_HSS',
  SUPPLEMENTAL = 'SUPPLEMENTAL_HSS',
}

export enum LaneTypeApp {
  XPO = 'XPO',
  EXC = 'EXC',
  SUP = 'SUP',
}

export enum LaneTypeIcons {
  // LINEHAUL - XPO is implied by default, so no icon is needed
  EXCLUSIVE = 'ss_exclusive_lane',
  SUPPLEMENTAL = 'ss_supplemental',
}
