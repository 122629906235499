import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router) {}

  startTracking(): void {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        // Don't include auth from SSO
        !event.urlAfterRedirects.includes('auth') &&
        this.history[this.history.length - 1] !== this.getUrl(event.urlAfterRedirects)
      ) {
        this.history.push(this.getUrl(event.urlAfterRedirects));
      }
    });
  }

  /** Return the URL without query params */
  private getUrl(fullUrl: string): string {
    if (!fullUrl) {
      return '';
    }
    const splitUrl = fullUrl.split('?');
    return splitUrl && splitUrl.length ? splitUrl[0] : fullUrl;
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      const url = this.history[this.history.length - 1];
      this.router.navigate([url]);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
