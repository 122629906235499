import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { DoorPlanDestination } from '@shared/models/door-plan';

@Component({
  selector: 'app-card-destinations',
  templateUrl: './card-destinations.component.html',
  styleUrls: ['./card-destinations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDestinationsComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() loadLaneView: boolean = false;
  @Input()
  set doorPlanDestination(doorPlanDestination: DoorPlanDestination) {
    this.destination = doorPlanDestination;
  }

  destination: DoorPlanDestination;

  constructor() {}

  ngOnInit() {}
}
