import { Injectable } from '@angular/core';
import {
  ListLocationFeaturesQuery,
  ListLocationFeaturesResp,
  ListLocationsQuery,
  ListLocationsResp,
  ListLocationsRqst,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private locationApiService: LocationApiService) {}

  private isPilot = new BehaviorSubject<boolean>(false);

  isPilot$: Observable<boolean> = this.isPilot.asObservable();

  listLocations(refSicCd: string[] = [], groupCategoryCd: string[] = []): Observable<ListLocationsResp> {
    const queryParams: ListLocationsQuery = new ListLocationsQuery();
    queryParams.activeInd = true;
    queryParams.meetAndTurnDesiredInd = true;
    queryParams.satelliteDesiredInd = true;
    queryParams.zoneDesiredInd = true;
    const rqst: ListLocationsRqst = {
      refSicCd,
      groupCategoryCd,
    };
    return this.locationApiService.listLocations(rqst, queryParams);
  }

  locationFeatures(featureCd: string, sicCd: string): Observable<ListLocationFeaturesResp> {
    const path = new ListLocationFeaturesQuery();
    path.featureCd = featureCd;
    return this.locationApiService
      .listLocationFeatures(path, { toastOnError: false, loadingOverlayEnabled: false })
      .pipe(
        tap((response) => {
          const filteredResponse = response.locationFeatures.filter(
            (locationfeat) =>
              locationfeat.settingValue === 'Y' && (locationfeat.sicCd === 'ALL' || locationfeat.sicCd === sicCd)
          );
          this.isPilot.next(filteredResponse?.length > 0);
        }),
        catchError((error) => {
          this.isPilot.next(false);
          return of(undefined);
        })
      );
  }
}
