import { Injectable } from '@angular/core';

import {
  FreightFlowApiService,
  GetFreightFlowDestinationSicsPath,
  GetFreightFlowDestinationSicsQuery,
  GetFreightFlowDestinationSicsResp,
} from '@xpo-ltl-2.0/sdk-freightflow';
import {
  ListAllActiveDoorsBySicPath,
  ListAllActiveDoorsBySicQuery,
  LocationApiService,
  LocationSic,
} from '@xpo-ltl-2.0/sdk-location';
import { DataOptions } from '@xpo-ltl/data-api';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { DoorPlanActionCd, DoorPlanProfileTypeCd, ListInfo, ShiftCd, TrailerStatusCd } from '@xpo-ltl/sdk-common';
import {
  AssignTrailerToDoorPath,
  AssignTrailerToDoorResp,
  AssignTrailerToDoorRqst,
  CreateDoorPlanPath,
  CreateDoorPlanResp,
  CreateDoorPlanRqst,
  DeleteDoorPlanPath,
  DeleteDoorPlanQuery,
  DeleteDoorPlanResp,
  DockDoorPlan,
  DockDoorPlanDestination,
  DockOperationsApiService,
  DoorPreference,
  GetDestinationsByLoadLanePath,
  GetDoorPlanByIdPath,
  GetDoorPlanMergeOptionsPath,
  GetDoorPlanMergeOptionsQuery,
  GetDoorPlanProfilePath,
  GetDoorPlanProfileResp,
  ListDockDoorPreferencesPath,
  ListDockDoorPreferencesQuery,
  ListDoorPlanRecommendationsPath,
  ListDoorPlanRecommendationsQuery,
  ListDoorPlanRecommendationsResp,
  MaintainDockDoorPreferencesResp,
  MaintainDockDoorPreferencesRqst,
  RevertDoorPlansPath,
  RevertDoorPlansResp,
  RevertDoorPlansRqst,
  ScoDoorPlanProfile,
  UpdateDoorPlansByActionPath,
  UpdateDoorPlansByActionResp,
  UpdateDoorPlansByActionRqst,
} from '@xpo-ltl/sdk-dockoperations';
import { chain, cloneDeep, get as _get } from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { AddUpdatePlanEvent } from '@app/door-plan-common/interfaces';
import { AppRoutesLabels } from '@shared/enums/app-routes.enum';
import { ProfileType } from '@shared/enums/door-plan';
import { AppCacheKeys } from '../../enums/app-cache-keys.enum';
import { ErrorCode } from '../../enums/error-codes.enum';
import { DoorPlanItem, DoorPlanProfile } from '../../models/door-plan';
import { AppCacheService } from '../cache-service/cache.service';
import { CurrentDoorPlanProfileService } from '../current-door-plan-profile/current-door-plan-profile.service';
import { TransactionTimestampInterceptor } from '../interceptors/transaction-timestamp.interceptor';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanService {
  private _doorPlanProfileUpdatedSubject = new Subject<ScoDoorPlanProfile>();
  doorPlanProfileUpdated$ = this._doorPlanProfileUpdatedSubject.asObservable();

  constructor(
    private cacheService: AppCacheService,
    private dockOperationsApiService: DockOperationsApiService,
    private locationApiService: LocationApiService,
    private freightFlowApiService: FreightFlowApiService,
    private toastService: XpoSnackBar,
    private currentDoorPlanProfileService: CurrentDoorPlanProfileService
  ) {}

  getDoorPlanById(
    doorPlanProfileId: number,
    selectedSic: string,
    loadingOverlayEnabled = true
  ): Observable<DoorPlanItem[]> {
    if (!doorPlanProfileId || !selectedSic) {
      return of([]);
    }
    const allActiveDoors$ = this.getAllActiveDoors(selectedSic, loadingOverlayEnabled);
    const defaultDoorPlans$ = this.getDoorPlanProfileById(doorPlanProfileId, loadingOverlayEnabled);
    return this.combineActiveDoorsWithDefaultDoorPlan(allActiveDoors$, defaultDoorPlans$);
  }

  getDoorPlanByFilters(
    selectedShift: ShiftCd,
    selectedSic: string,
    profileType: ProfileType,
    loadingOverlayEnabled = true
  ): Observable<DoorPlanItem[]> {
    if (!selectedShift || !selectedSic || !profileType) {
      return of([]);
    }
    const allActiveDoors$ = this.getAllActiveDoors(selectedSic, loadingOverlayEnabled);
    const defaultDoorPlans$ = this.getDoorPlanProfileByFilters(
      selectedSic,
      selectedShift,
      profileType,
      loadingOverlayEnabled
    );

    return this.combineActiveDoorsWithDefaultDoorPlan(allActiveDoors$, defaultDoorPlans$);
  }

  private getDoorPlanProfileById(
    doorPlanProfileId: number,
    loadingOverlayEnabled: boolean
  ): Observable<DoorPlanItem[]> {
    const path = new GetDoorPlanByIdPath();
    path.doorPlanProfileId = doorPlanProfileId;
    return this.dockOperationsApiService
      .getDoorPlanById(path, {
        toastOnError: false,
        loadingOverlayEnabled,
      })
      .pipe(
        map((listDoorPlanProfilesResp) => {
          if (!listDoorPlanProfilesResp || !listDoorPlanProfilesResp.doorPlanProfile) {
            return [];
          }
          this._doorPlanProfileUpdatedSubject.next(listDoorPlanProfilesResp.doorPlanProfile);
          return listDoorPlanProfilesResp.doorPlanProfile.scoDoorPlan.map((scoDoorPlan) =>
            DoorPlanItem.createFromScoDoorPlan(scoDoorPlan, this.cacheService.getCacheValue(AppCacheKeys.ACTIVE_SICS))
          );
        })
      );
  }

  private combineActiveDoorsWithDefaultDoorPlan(
    allActiveDoors$: Observable<DoorPlanItem[]>,
    defaultDoorPlans$: Observable<DoorPlanItem[]>
  ): Observable<DoorPlanItem[]> {
    return combineLatest([allActiveDoors$, defaultDoorPlans$]).pipe(
      map(([allActiveDoors, doorPlans]) => {
        // workaround to use sector number from locations endpoint until doorPlan endpoint will have
        // all sector numbers fixed and db updated
        doorPlans = this.sectorWorkaround(doorPlans, allActiveDoors);

        const completedDoorPlan: DoorPlanItem[] = this.createDoorPlanArray(allActiveDoors, doorPlans);

        const fullDPByDisplayNbrWithBlanks: DoorPlanItem[] = this.getFullDoorPlansWithBlanks(completedDoorPlan);
        return fullDPByDisplayNbrWithBlanks;
      }),
      catchError((error) => {
        if (error.code !== ErrorCode.NotFound) {
          this.toastService.error(error.message || error.error.message);
        }
        return of([]);
      })
    );
  }

  private createDoorPlanArray(allActiveDoors: DoorPlanItem[], doorPlans: DoorPlanItem[]): DoorPlanItem[] {
    const clonedDoorPlans: DoorPlanItem[] = cloneDeep(doorPlans);
    const newDoorPlanArray: DoorPlanItem[] = [];

    allActiveDoors.map((activeDoor) => {
      const doorPlanMatchIndex = clonedDoorPlans.findIndex((doorPlan) => doorPlan.doorNbr === activeDoor.doorNbr);
      if (doorPlanMatchIndex > -1) {
        clonedDoorPlans[doorPlanMatchIndex].displaySequenceNbr = activeDoor.displaySequenceNbr;
        const doorPlanFound = clonedDoorPlans[doorPlanMatchIndex];
        newDoorPlanArray.push(doorPlanFound);
        clonedDoorPlans.splice(doorPlanMatchIndex, 1);
      } else {
        newDoorPlanArray.push(activeDoor);
      }
    });
    newDoorPlanArray.sort((a, b) => Number(a.displaySequenceNbr) - Number(b.displaySequenceNbr));

    clonedDoorPlans.forEach((remainingDoorPlan) => {
      remainingDoorPlan.displaySequenceNbr = (
        Number(newDoorPlanArray[newDoorPlanArray.length - 1].displaySequenceNbr) + 1
      ).toString();
      newDoorPlanArray.push(remainingDoorPlan);
    });
    return newDoorPlanArray;
  }

  sectorWorkaround(doorPlans: DoorPlanItem[], activeDoors: DoorPlanItem[]): DoorPlanItem[] {
    // workaround to use sector number from locations endpoint until doorPlan endpoint will have
    // all sector numbers fixed and db updated
    return doorPlans.map((doorPlan) => {
      doorPlan.displaySequenceNbr = doorPlan.displaySequenceNbr?.toString();

      const activeDoorForDoorPlan = activeDoors.find((activeDoor) => activeDoor.doorNbr === doorPlan.doorNbr);
      doorPlan.doorSectorNbr = activeDoorForDoorPlan?.doorSectorNbr.toString();

      return doorPlan;
    });
  }

  getDoorPlanProfileByFilters(
    sicCode: string,
    shiftCode: ShiftCd,
    profileTypeCode: ProfileType,
    loadingOverlayEnabled: boolean
  ): Observable<DoorPlanItem[]> {
    const path = new GetDoorPlanProfilePath();
    path.sicCd = sicCode;
    path.shiftCd = shiftCode;
    path.profileTypeCd = profileTypeCode;
    return this.dockOperationsApiService
      .getDoorPlanProfile(path, {
        toastOnError: false,
        loadingOverlayEnabled,
      })
      .pipe(
        filter(
          (response: GetDoorPlanProfileResp) =>
            !!response && !!response.doorPlanProfile && !!response.doorPlanProfile.scoDoorPlan
        ),
        tap((data) => {
          this.currentDoorPlanProfileService.setCurrentDoorPlanProfile(new DoorPlanProfile(data.doorPlanProfile));
        }),
        map((response) =>
          response.doorPlanProfile.scoDoorPlan.map((scoDoorPlan) =>
            DoorPlanItem.createFromScoDoorPlan(scoDoorPlan, this.cacheService.getCacheValue(AppCacheKeys.ACTIVE_SICS))
          )
        )
      );
  }

  getAllActiveDoors(sicCode: string, loadingOverlayEnabled: boolean): Observable<DoorPlanItem[]> {
    if (!sicCode) {
      return of([]);
    }
    const activeDoorsBySicKey = `${AppCacheKeys.ACTIVE_DOORS_BY_SIC}${sicCode}`;
    const cacheActiveDoors: DoorPlanItem[] = this.cacheService.getCacheValue(activeDoorsBySicKey);
    if (cacheActiveDoors && cacheActiveDoors.length) {
      return of(cloneDeep(cacheActiveDoors));
    }
    const path: ListAllActiveDoorsBySicPath = {
      sicCd: sicCode,
    };

    const query: ListAllActiveDoorsBySicQuery = new ListAllActiveDoorsBySicQuery();

    const options: DataOptions = {
      toastOnError: false,
      loadingOverlayEnabled,
    };

    return this.locationApiService
      .listAllActiveDoorsBySic(path, query, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((list) => list.activeDoors.map((door) => DoorPlanItem.createEmptyDoorPlanItem(door))),
        tap((doors) => this.cacheService.setCacheValue(activeDoorsBySicKey, cloneDeep(doors)))
      );
  }

  private getFullDoorPlansWithBlanks(doorPlans: DoorPlanItem[]): DoorPlanItem[] {
    const firstDisplayNbr = Number(doorPlans[0].displaySequenceNbr);
    const lastDisplayNbr = Number(doorPlans[doorPlans.length - 1].displaySequenceNbr);

    const fullDPByDisplayNbrWithBlanks: DoorPlanItem[] = [];

    let i = firstDisplayNbr;

    for (; i <= lastDisplayNbr; i++) {
      const matchIndex = doorPlans.findIndex((doorPlan: DoorPlanItem) => {
        return Number(doorPlan.displaySequenceNbr) === i;
      });

      const hasRow = matchIndex !== -1;

      if (hasRow) {
        fullDPByDisplayNbrWithBlanks[i] = doorPlans[matchIndex];
      } else {
        const doorPlanEmpty = DoorPlanItem.createEmptyDoorPlanItem();
        doorPlanEmpty.displaySequenceNbr = i.toString();
        fullDPByDisplayNbrWithBlanks[i] = doorPlanEmpty;
      }
    }

    return fullDPByDisplayNbrWithBlanks;
  }

  private getEmptyActiveDoors(activeDoors: DoorPlanItem[], doorPlans: DoorPlanItem[]): DoorPlanItem[] {
    return activeDoors.filter((activeDoor) => {
      const doorPlanMatchIndex = doorPlans.findIndex((doorPlan) => doorPlan.doorNbr === activeDoor.doorNbr);

      const isEmptyActiveDoor = doorPlanMatchIndex === -1;

      return isEmptyActiveDoor;
    });
  }

  resetDoorPlans(
    doorPlanProfileId: number,
    doorPlans: DoorPlanItem[],
    revertAllDoorsInd = false
  ): Observable<RevertDoorPlansResp> {
    let doorNbrs = [];
    if (!revertAllDoorsInd) {
      // It is not necessary to send the door numbers
      doorNbrs = doorPlans.map((rowSelected) => rowSelected.doorNbr).sort();
    }
    const request: RevertDoorPlansRqst = {
      doorNbrs,
      revertAllDoorsInd,
    };

    const path: RevertDoorPlansPath = {
      doorPlanProfileId,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService.revertDoorPlans(
      request,
      path,
      options,
      TransactionTimestampInterceptor.useTransactionTimestampHeader
    );
  }

  removeDoorPlan(doorPlanProfileId: number, doorNbr: string, confirmActionInd = false): Observable<DeleteDoorPlanResp> {
    const path: DeleteDoorPlanPath = {
      doorPlanProfileId,
      doorNbr,
    };
    const queryParams: DeleteDoorPlanQuery = {
      confirmActionInd,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService
      .deleteDoorPlan(path, queryParams, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((response) => {
          if (response && response.warningMessage) {
            // So it can be handle in error function
            throw response.warningMessage;
          }
          return response;
        })
      );
  }

  addDoorPlan(
    doorPlanProfileId: number,
    doorNbr: string,
    additionalData: AddUpdatePlanEvent,
    confirmActionInd = false
  ): Observable<CreateDoorPlanResp> {
    const doorPlan: DockDoorPlan = new DockDoorPlan();
    doorPlan.doorNbr = doorNbr;
    doorPlan.applicationId = AppRoutesLabels.DOOR_PLAN_PAGE;

    if (additionalData.locations) {
      doorPlan.doorPlanDestinations = chain(additionalData.locations)
        .map((location: LocationSic) => {
          const doorPlanDestination = new DockDoorPlanDestination();
          doorPlanDestination.destinationSicCode = location.sicCd;
          return doorPlanDestination;
        })
        .uniqBy('destinationSicCode')
        .value();
    }

    if (additionalData.moveTo) {
      doorPlan.moveToSicCd = additionalData.moveTo.sicCd;
    }
    if (additionalData.closeTo) {
      doorPlan.closeToSicCd = additionalData.closeTo.sicCd;
    }
    if (additionalData.facSectorNbr) {
      doorPlan.facSectorNbr = additionalData.facSectorNbr;
    }
    if (additionalData.destinationsArray) {
      const newDestinations = additionalData.destinationsArray.map((destination) => {
        const newDest = new DockDoorPlanDestination();

        newDest.destinationSicCode = destination;
        return newDest;
      });
      doorPlan.doorPlanDestinations = newDestinations;
    }

    const rqst: CreateDoorPlanRqst = {
      doorPlan,
      confirmActionInd,
    };

    const path: CreateDoorPlanPath = {
      doorPlanProfileId,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService
      .createDoorPlan(rqst, path, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((response) => {
          if (response && response.warningMessage) {
            // So it can be handle in error function
            throw response.warningMessage;
          }
          return response;
        })
      );
  }

  getDoorPlanMergeOptions(
    doorNbr: string,
    sicCd: string,
    shiftCd: ShiftCd,
    profileTypeCd: DoorPlanProfileTypeCd
  ): Observable<DoorPlanItem[]> {
    const path = new GetDoorPlanMergeOptionsPath();
    path.doorNbr = doorNbr;
    path.shiftCd = shiftCd;
    path.sicCd = sicCd;
    const queryParams = new GetDoorPlanMergeOptionsQuery();
    queryParams.profileTypeCd = profileTypeCd;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };
    return this.dockOperationsApiService.getDoorPlanMergeOptions(path, queryParams, dataOptions).pipe(
      map((response) => {
        if (response && response.mergeToDoorPlans) {
          return response.mergeToDoorPlans.map((scoDoorPlan) => DoorPlanItem.createFromScoDoorPlan(scoDoorPlan));
        }
        return [];
      })
    );
  }

  swapDoorPlans(
    doorPlanId: number,
    fromDoorNumber: string,
    toDoorNumber: string,
    errorOverrideInd: boolean
  ): Observable<UpdateDoorPlansByActionResp> {
    return this.updateDoorPlansByAction(
      doorPlanId,
      fromDoorNumber,
      toDoorNumber,
      DoorPlanActionCd.SWAP,
      errorOverrideInd
    );
  }

  moveDoorPlan(
    doorPlanId: number,
    fromDoorNumber: string,
    toDoorNumber: string,
    errorOverrideInd: boolean
  ): Observable<UpdateDoorPlansByActionResp> {
    return this.updateDoorPlansByAction(
      doorPlanId,
      fromDoorNumber,
      toDoorNumber,
      DoorPlanActionCd.MOVE,
      errorOverrideInd
    );
  }

  copyDoorPlan(
    doorPlanId: number,
    fromDoorNumber: string,
    toDoorNumber: string,
    errorOverrideInd = false
  ): Observable<UpdateDoorPlansByActionResp> {
    return this.updateDoorPlansByAction(
      doorPlanId,
      fromDoorNumber,
      toDoorNumber,
      DoorPlanActionCd.COPY,
      errorOverrideInd
    );
  }

  mergeDoorPlan(
    doorPlanId: number,
    fromDoorNumber: string,
    toDoorNumber: string,
    errorOverrideInd: boolean
  ): Observable<UpdateDoorPlansByActionResp> {
    return this.updateDoorPlansByAction(
      doorPlanId,
      fromDoorNumber,
      toDoorNumber,
      DoorPlanActionCd.MERGE,
      errorOverrideInd
    );
  }

  private updateDoorPlansByAction(
    doorPlanId: number,
    fromDoorNumber: string,
    toDoorNumber: string,
    updateAction: DoorPlanActionCd,
    errorOverrideInd = false
  ): Observable<UpdateDoorPlansByActionResp> {
    const request: UpdateDoorPlansByActionRqst = {
      errorOverrideInd,
      supervisorEmplId: '',
      confirmActionInd: false,
    };

    const path: UpdateDoorPlansByActionPath = {
      doorPlanActionCd: updateAction,
      doorPlanProfileId: doorPlanId,
      fromDoorNbr: fromDoorNumber,
      toDoorNbr: toDoorNumber,
    };

    const options: DataOptions = {
      toastOnError: false,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService
      .updateDoorPlansByAction(request, path, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((response) => {
          if (response && response.warningMessage) {
            // So it can be handle in error function
            throw response.warningMessage;
          }
          return response;
        })
      );
  }

  getCloseToDestinations(
    sicCd: string,
    shiftCd: ShiftCd,
    moveToSicCd: string,
    closeToSicCd?: string
  ): Observable<GetFreightFlowDestinationSicsResp> {
    const pathParams = new GetFreightFlowDestinationSicsPath();
    pathParams.originSicCd = sicCd;
    pathParams.shiftCd = shiftCd;
    pathParams.moveToSicCd = moveToSicCd;
    const queryParams = new GetFreightFlowDestinationSicsQuery();
    queryParams.closeToSicCd = closeToSicCd;
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.freightFlowApiService.getFreightFlowDestinationSics(pathParams, queryParams, dataOptions);
  }

  getDestinationsByLoadLane(
    sicCd: string,
    shiftCd: ShiftCd,
    moveToSicCd: string,
    closeToSicCd: string
  ): Observable<any> {
    const pathParams = new GetDestinationsByLoadLanePath();
    pathParams.sicCd = sicCd;
    pathParams.shiftCd = shiftCd;
    pathParams.moveToSicCd = moveToSicCd;
    pathParams.closeToSicCd = closeToSicCd;

    return this.dockOperationsApiService.getDestinationsByLoadLane(pathParams);
  }

  createStaticDoorPlan(doorPlanItem: DoorPlanItem, confirmActionInd = false): Observable<CreateDoorPlanResp> {
    const doorPlan: DockDoorPlan = doorPlanItem.getDockDoorPlan();

    const rqst: CreateDoorPlanRqst = {
      doorPlan,
      confirmActionInd,
    };

    const path: CreateDoorPlanPath = {
      doorPlanProfileId: doorPlanItem.doorPlanProfileId,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService.createDoorPlan(rqst, path, options).pipe(
      map((response) => {
        if (response && response.warningMessage) {
          // So it can be handle in error function
          throw response.warningMessage;
        }
        return response;
      })
    );
  }

  getListDockDoorPreferences(
    sicCd: string,
    shiftCd: ShiftCd,
    profileTypeCd: DoorPlanProfileTypeCd
  ): Observable<DoorPreference[]> {
    if (!sicCd || !shiftCd || !profileTypeCd || profileTypeCd === DoorPlanProfileTypeCd.DEFAULT) {
      return of([]);
    }

    const path: ListDockDoorPreferencesPath = {
      sicCd,
      shiftCd,
      profileTypeCd,
    };
    const queryParams: ListDockDoorPreferencesQuery = {
      /* Embargo Type not ready yet from the BE to recieve this request
      doorPreferenceTypeCds: [
        DoorPreferenceTypeCd.STEP_SAVER_EXCLUDE,
        DoorPreferenceTypeCd.TRAP,
        DoorPreferenceTypeCd.LOAD_UNLOAD,
        DoorPreferenceTypeCd.EMBARGO
      ], */
      doorPreferenceTypeCds: null,
      listInfo: new ListInfo(),
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: false,
    };

    return this.dockOperationsApiService
      .listDockDoorPreferences(path, queryParams, options)
      .pipe(map((response) => response && response.doorPreferences));
  }

  maintainDockDoorPreference(
    sicCd: string,
    shiftCd: ShiftCd,
    profileTypeCd: DoorPlanProfileTypeCd,
    doorPlans: DoorPlanItem[]
  ): Observable<MaintainDockDoorPreferencesResp> {
    if (doorPlans?.length < 1) {
      return;
    }
    const doorPreferences = doorPlans.map((doorPlan) => {
      const doorPreference = new DoorPreference();
      doorPreference.sicCd = sicCd;
      doorPreference.shiftCd = shiftCd;
      doorPreference.profileTypeCd = profileTypeCd;
      doorPreference.doorNbr = doorPlan.doorNbr;
      doorPreference.stepSaverExcludeInd = !!doorPlan.doorPreference?.stepSaverExcludeInd;
      doorPreference.trapInd = !!doorPlan.doorPreference?.trapInd;
      doorPreference.loadUnloadInd = !!doorPlan.doorPreference?.loadUnloadInd;
      doorPreference.embargoInd = !!doorPlan.doorPreference?.embargoInd;
      return doorPreference;
    });

    const queryParams: MaintainDockDoorPreferencesRqst = {
      doorPreferences,
    };
    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService.maintainDockDoorPreferences(
      queryParams,
      options,
      TransactionTimestampInterceptor.useTransactionTimestampHeader
    );
  }

  assignTrailerToDoor(
    sicCd: string,
    shiftCd: ShiftCd,
    doorNbr: string,
    equipmentId: number,
    trailerNbr: string,
    trailerStatus: TrailerStatusCd
  ): Observable<AssignTrailerToDoorResp> {
    const request = new AssignTrailerToDoorRqst();
    request.doorNbr = doorNbr;
    request.equipmentId = equipmentId;
    request.trailerNbr = trailerNbr;
    request.shiftCd = shiftCd;
    request.newTrailerStatus = trailerStatus;

    const path: AssignTrailerToDoorPath = {
      sicCd,
    };
    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService.assignTrailerToDoor(
      request,
      path,
      options,
      TransactionTimestampInterceptor.useTransactionTimestampHeader
    );
  }

  listDoorPlanRecommendations(
    doorPlanProfileId: number,
    requiredChangesInd: boolean
  ): Observable<ListDoorPlanRecommendationsResp> {
    const path = new ListDoorPlanRecommendationsPath();
    path.doorPlanProfileId = doorPlanProfileId;
    const query = new ListDoorPlanRecommendationsQuery();
    query.requiredChangesInd = requiredChangesInd;
    return this.dockOperationsApiService.listDoorPlanRecommendations(path, query);
  }
}
