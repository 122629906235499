import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { MoreInfo } from '@xpo-ltl/sdk-common';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MisloadDialogComponent } from '@app/dialogs/misload-dialog/misload-dialog.component';
import { TrailerLoadingWarningDialogComponent } from '@dialogs/trailer-loading-warning/trailer-loading-warning.component';
import { DoorPlanActionsEnum } from '../enums/door-plan';
import { ErrorCode } from '../enums/error-codes.enum';
import { ActionsServiceEnum } from '../enums/service-actions.enum';
import { GTAction } from '../models/door-plan/grids-toolbar-actions.model';
import { AppAction } from '../types/actions.type';

export abstract class ActionsService {
  // #region ActionPerformed
  private _actionPerformedSubject = new BehaviorSubject<GTAction>(null);
  actionPerformed$: Observable<GTAction> = this._actionPerformedSubject.asObservable();
  get actionPerformed(): GTAction {
    return this._actionPerformedSubject.value;
  }
  // #endregion

  // #region ActionInProgress
  private _actionInProgressSubject = new BehaviorSubject<boolean>(false);
  actionInProgress$: Observable<boolean> = this._actionInProgressSubject.asObservable();
  get actionInProgress(): boolean {
    return this._actionInProgressSubject.value;
  }
  // #endregion

  constructor(public confirmDialog: XpoConfirmDialog, public snackBar: XpoSnackBar, public dialog: MatDialog) {
    this.onActionsChange();
  }

  private onActionsChange() {
    this.actionPerformed$
      .pipe(
        filter(
          (gridsToolbarAction: GTAction) =>
            this.actionInProgress === true && gridsToolbarAction && !!gridsToolbarAction.action
        )
      )
      .subscribe((action) => {
        this.handleActionChange(action);
      });
  }

  /** Emit ACTION_ENDED_WITH_ERRORS action to let others know for the error */
  emitError(error?: any, forceRefreshOnError = false): void {
    this.setActionPerformed({
      data: {
        firstRow: null,
        secondRow: null,
        additionalData: error,
      },
      action: ActionsServiceEnum.ACTION_ENDED_WITH_ERRORS,
      skipRefreshData: !forceRefreshOnError,
    });
    this.setActionInProgress(false);
  }

  protected forcePerformedAction(confirm: boolean, forceRefreshOnError = false) {
    if (confirm) {
      const actionPerformed = this.actionPerformed;
      // To force the action after user confirmation
      actionPerformed.errorOverrideInd = true;
      this.setActionPerformed(actionPerformed);
    } else {
      this.emitError(null, forceRefreshOnError);
    }
  }

  abstract handleActionChange(gridsToolbarAction: GTAction): void;

  setActionPerformed(gridsToolbarAction: GTAction): void {
    const actionPerformed = _.get(this.actionPerformed, 'action', null);
    if (
      (actionPerformed && gridsToolbarAction.action !== actionPerformed) ||
      _.get(gridsToolbarAction, 'action', false)
    ) {
      this._actionPerformedSubject.next(gridsToolbarAction);
    }
  }

  setActionInProgress(value: boolean): void {
    this._actionInProgressSubject.next(value);
  }

  onActionCancelled(): void {
    if (this.actionInProgress) {
      this.setActionPerformed({
        action: ActionsServiceEnum.ACTION_CANCELED,
        data: {
          firstRow: null,
          secondRow: null,
        },
      });
    }
  }

  onActionsError(error: any, action?: AppAction, forceRefreshOnError = false, isOverrideCall = false): void {
    // Generic error validation
    if (!error) {
      this.emitError(error, forceRefreshOnError);
    } else if (error.code === ErrorCode.BadRequest && error.error?.moreInfo) {
      this.showModalWarning(error.error.moreInfo, action, forceRefreshOnError, isOverrideCall);
    } else if (error.error?.moreInfo?.length) {
      this.snackBar.error(`${error.error.errorCode}: ${error.error.moreInfo[0].message}`);
      this.emitError(error, forceRefreshOnError);
    } else if (error.moreInfo?.length) {
      this.showModalWarning(error.moreInfo, action, forceRefreshOnError, isOverrideCall);
    } else {
      this.emitError(error, forceRefreshOnError);
    }
  }

  showModalWarning(
    moreInfo: MoreInfo[],
    action?: AppAction,
    forceRefreshOnError = false,
    isOverrideCall = false
  ): void {
    /** use action to custom handle modal */
    this.showMisloadWarning(moreInfo, forceRefreshOnError, isOverrideCall, action);
  }

  showTrailerloadingDialog(moreInfo: MoreInfo[], forceRefreshOnError = false): boolean {
    const destinations = _.chain(moreInfo)
      .filter(({ errorCode }) => errorCode === ErrorCode.trailerLoadingAtDestination)
      .groupBy('message')
      .map((value, key) => ({
        sicCd: key,
        doors: value.map(({ location }) => location),
      }))
      .value();

    if (destinations && destinations.length) {
      const config = {
        width: '600px',
        disableClose: true,
        data: {
          destinations,
        },
      };

      this.dialog
        .open(TrailerLoadingWarningDialogComponent, config)
        .afterClosed()
        .subscribe((confirm) => this.forcePerformedAction(confirm, forceRefreshOnError));

      return true;
    }
    return false;
  }

  showMisloadWarning(
    moreInfo: MoreInfo[],
    forceRefreshOnError = false,
    isOverrideCall = false,
    action?: AppAction
  ): void {
    let message = '';
    for (let index = 0; index < moreInfo.length; index++) {
      const doorNbr = moreInfo[index].location;
      const messageHasDoorNbr = (moreInfo[index].message || '').includes(doorNbr);
      message += `${moreInfo[index].message}`;
      if (!messageHasDoorNbr && action !== DoorPlanActionsEnum.PERFORM_ADD) {
        message += ` Door ${doorNbr}\n`;
      } else {
        message += `\n`;
      }
    }

    if (isOverrideCall) {
      this.dialog.open(MisloadDialogComponent, { data: { message: message } });
    } else {
      message += `\nClick 'Continue' to override the error.`;
      // Warning message
      const confirmConfig: XpoConfirmDialogConfig = {
        confirmButtonText: 'Continue',
        rejectButtonText: 'Cancel',
        icon: 'warning',
        // showCancelButton: true, // TODO: If we add it, two cancel will appear
      };
      this.confirmDialog
        .confirm(message, 'Warning', confirmConfig)
        .subscribe((confirm) => this.forcePerformedAction(confirm, forceRefreshOnError));
    }
  }

  showActionSuccessToast(toastMessage: string): MatSnackBarRef<XpoNotificationTemplate> {
    return this.snackBar.open({
      message: toastMessage,
      status: 'success',
      matConfig: {
        duration: 5000,
      },
    });
  }

  showActionInfoToast(toastMessage: string): MatSnackBarRef<XpoNotificationTemplate> {
    return this.snackBar.open({
      message: toastMessage,
      status: 'info',
      matConfig: {
        duration: 0,
      },
    });
  }
}
