import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SdkFreightFlowModule } from '@xpo-ltl-2.0/sdk-freightflow';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
  XpoLtlSicSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoIconModule,
  XpoMessagingPopoverModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkCityOperationsModule } from '@xpo-ltl/sdk-cityoperations';
import { SdkDockOperationsModule } from '@xpo-ltl/sdk-dockoperations';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLinehaulOperationsModule } from '@xpo-ltl/sdk-linehauloperations';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { DialogModule } from '@dialogs/dialog.module';
import { environment } from '@environments/environment';
import { TransactionTimestampInterceptor } from '@services/interceptors/transaction-timestamp.interceptor';
import { SharedModule } from '@shared/shared.module';
import { BeaconApiService } from '@xpo-ltl/sdk-beacon';
import { CypressUtilities } from './cypress/cypress.utilities';
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

const sdkModules: any[] = [
  SdkAppMetadataModule,
  SdkDockOperationsModule,
  SdkFreightFlowModule,
  SdkInfrastructureModule,
  SdkLinehaulOperationsModule,
  SdkLocationModule,
  SdkUserPreferenceModule,
  SdkCityOperationsModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    LoginModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatTabsModule,
    AgGridModule,
    CoreModule,
    XpoFooterModule,
    // Xpo Libraries
    XpoAccountPopoverModule,
    XpoAgGridModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlRoleSwitcherModule,
    XpoLtlSicSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    XpoMessagingPopoverModule,
    XpoIconModule,
    ...sdkModules,
    // For SSO
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule, // KEEP LAST!!!
    XpoPageNotFoundRoutingModule, // KEEP LAST!!!
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransactionTimestampInterceptor,
      multi: true,
    },
    DataApiService,
    BeaconApiService,
    LocationApiService,
    NotificationService,
    ...CypressUtilities.getProviders(),
  ],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
