import { ShiftCd, WeekDayCd } from '@xpo-ltl/sdk-common';

const WEEK_DAYS = [
  {
    index: 0,
    weekDayCd: WeekDayCd.SUNDAY,
  },
  {
    index: 1,
    weekDayCd: WeekDayCd.MONDAY,
  },
  {
    index: 2,
    weekDayCd: WeekDayCd.TUESDAY,
  },
  {
    index: 3,
    weekDayCd: WeekDayCd.WEDNESDAY,
  },
  {
    index: 4,
    weekDayCd: WeekDayCd.THURSDAY,
  },
  {
    index: 5,
    weekDayCd: WeekDayCd.FRIDAY,
  },
  {
    index: 6,
    weekDayCd: WeekDayCd.SATURDAY,
  },
];

export class WeekDayHelper {
  static getEnum(key: string): WeekDayCd {
    return WeekDayCd[key];
  }

  /**
   * Return the WeekDayCd by Date
   * `date` is today by default
   */
  static getWeekDayByDate(date = new Date()): WeekDayCd {
    const index = date.getDay();
    return this.getWeekDayByIndex(index);
  }

  /**
   * Return the WeekDayCd by index day
   */
  static getWeekDayByIndex(index: number): WeekDayCd {
    if (!index) {
      return;
    }
    const weekDay = WEEK_DAYS.find((wDay) => wDay.index === index);
    return weekDay ? weekDay.weekDayCd : null;
  }

  /** Return the index by WeekDayCd */
  static getWeekDayIndex(weekDayCd: WeekDayCd): number {
    if (!weekDayCd) {
      return;
    }
    const weekDay = WEEK_DAYS.find((wDay) => wDay.weekDayCd === weekDayCd);
    return weekDay ? weekDay.index : -1;
  }

  /** Return the date in current week by WeekDay */
  static getDateByWeekDay(weekDayCd: WeekDayCd): Date {
    const today = new Date();
    if (weekDayCd) {
      const currentWeekDayIndex = new Date().getDay();
      const paramWeekDayIndex = this.getWeekDayIndex(weekDayCd);
      const dayBetweenWeekDays = currentWeekDayIndex - paramWeekDayIndex;
      if (dayBetweenWeekDays > 0) {
        today.setDate(today.getDate() - Math.abs(dayBetweenWeekDays));
      } else if (dayBetweenWeekDays < 0) {
        today.setDate(today.getDate() + Math.abs(dayBetweenWeekDays));
      }
    }
    return today;
  }

  static getShiftDate(date: Date, shiftCd: ShiftCd) {
    const shiftDate = new Date(date.getTime());

    if (shiftCd === ShiftCd.NIGHT_FAC) {
      /* -8 hours is used below, because the night shift starts on "current day" and finishes the day after.
       * This way we avoid requesting data (eg. load lanes) for the wrong date
       */
      shiftDate.setHours(shiftDate.getHours() - 8);
    }

    return shiftDate;
  }
}
