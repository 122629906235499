import { DoorPlanProfileTypeCd, DoorPlanProfileTypeCdHelper } from '@xpo-ltl/sdk-common';

export class DoorPlanProfileHelper {
  /**
   * Get the key of the enum
   * Eg. DoorPlanProfileTypeCd.CURRENT return 'CURRENT'
   */
  static getKey(doorPlanProfile: DoorPlanProfileTypeCd): string {
    const keys = DoorPlanProfileTypeCdHelper.keys();
    const key = keys.find((k) => doorPlanProfile === DoorPlanProfileTypeCd[k]);
    return key;
  }
}
