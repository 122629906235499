import { FreightFlowPath } from '@xpo-ltl-2.0/sdk-freightflow';
import { ActionCd, AuditInfo } from '@xpo-ltl/sdk-common';
import { LoadLaneDestination, ScoDoorPlanDestination } from '@xpo-ltl/sdk-dockoperations';

import { LoadLaneInfo } from './load-lane-info.model';

export class DoorPlanDestination {
  doorPlanProfileId: number;
  doorNbr: string;
  sequenceNbr: string;
  destinationSicCode: string;
  destinationPostalCd: string;
  legacyCisCustomerNbr: number;
  customerLocationFuncId: number;
  correlationId: string;
  scoEventId: number;
  listActionCd: ActionCd;
  auditInfo: AuditInfo;
  loadLaneInfo?: Partial<LoadLaneInfo>;
  freightFlowPath?: FreightFlowPath;

  static createFromScoDoorPlanDestination(scoDoorPlanDestination?: ScoDoorPlanDestination): DoorPlanDestination {
    const doorPlanDestination = new DoorPlanDestination();
    if (scoDoorPlanDestination) {
      doorPlanDestination.doorPlanProfileId = scoDoorPlanDestination.doorPlanProfileId;
      doorPlanDestination.doorNbr = scoDoorPlanDestination.doorNbr;
      doorPlanDestination.sequenceNbr = scoDoorPlanDestination.sequenceNbr;
      doorPlanDestination.destinationSicCode = scoDoorPlanDestination.destinationSicCode;
      doorPlanDestination.destinationPostalCd = scoDoorPlanDestination.destinationPostalCd;
      doorPlanDestination.legacyCisCustomerNbr = scoDoorPlanDestination.legacyCisCustomerNbr;
      doorPlanDestination.customerLocationFuncId = scoDoorPlanDestination.customerLocationFuncId;
      doorPlanDestination.correlationId = scoDoorPlanDestination.correlationId;
      doorPlanDestination.scoEventId = scoDoorPlanDestination.scoEventId;
      doorPlanDestination.listActionCd = scoDoorPlanDestination.listActionCd;
      doorPlanDestination.auditInfo = scoDoorPlanDestination.auditInfo;
    }
    return doorPlanDestination;
  }

  static createFromLoadLaneDestination(loadLaneDestination?: LoadLaneDestination): DoorPlanDestination {
    const doorPlanDestination = new DoorPlanDestination();
    if (loadLaneDestination) {
      doorPlanDestination.destinationSicCode = loadLaneDestination.destinationSicCd;
      doorPlanDestination.loadLaneInfo = {
        remainingCubeLane: loadLaneDestination.remainingToLoadCubePct,
        remainingWeightLane: loadLaneDestination.remainingToLoadWeight,
        remainingBillCountLane: loadLaneDestination.remainingToLoadShipmentCount,
        remainingMMLane: loadLaneDestination.remainingMmCount,
        totalCubeLane: loadLaneDestination.totalPlannedCubePct,
        totalWeightLane: loadLaneDestination.totalPlannedWeight,
        totalBillCountLane: loadLaneDestination.totalPlannedShipmentCount,
        totalMMLane: loadLaneDestination.totalPlannedMmCount,
      };
      if (loadLaneDestination.doorPlan) {
        doorPlanDestination.doorNbr = loadLaneDestination.doorPlan.doorNbr;
        doorPlanDestination.sequenceNbr = loadLaneDestination.doorPlan.displaySequenceNbr;
      }
    }
    return doorPlanDestination;
  }

  setFreightFlowPath(freightFlowPaths: FreightFlowPath[]) {
    this.freightFlowPath = freightFlowPaths.find((path) => path.destinationSicCd === this.destinationSicCode);
  }

  /** Compare by doorNbr or destinationSicCode */
  matchDestination(loadLaneDestination: LoadLaneDestination): boolean {
    if (loadLaneDestination?.doorPlan?.doorNbr) {
      return (
        this.doorNbr === loadLaneDestination.doorPlan.doorNbr &&
        this.destinationSicCode === loadLaneDestination.destinationSicCd
      );
    }
    return this.destinationSicCode === loadLaneDestination.destinationSicCd;
  }

  /** Update weight, mm, bill count, cube */
  updateFromLoadLaneDestination(loadLaneDestination: LoadLaneDestination): void {
    this.loadLaneInfo = {
      remainingCubeLane: loadLaneDestination.remainingToLoadCubePct,
      remainingWeightLane: loadLaneDestination.remainingToLoadWeight,
      remainingBillCountLane: loadLaneDestination.remainingToLoadShipmentCount,
      remainingMMLane: loadLaneDestination.remainingMmCount,
      totalCubeLane: loadLaneDestination.totalPlannedCubePct,
      totalWeightLane: loadLaneDestination.totalPlannedWeight,
      totalBillCountLane: loadLaneDestination.totalPlannedShipmentCount,
      totalMMLane: loadLaneDestination.totalPlannedMmCount,
    };
  }
}
