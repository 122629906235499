import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Equipment } from '@xpo-ltl/sdk-dockoperations';

import { XpoContextMenuComponent } from '@app/context-menu/public_api';
import { AssingTrailerEvent } from '@app/door-plan-common/interfaces/assign-trailer-event.interface';
import { DoorPlanItem, GTAction } from '@shared/models/door-plan';

@Component({
  selector: 'app-door-plan-trailer-context-menu',
  templateUrl: './door-plan-trailer-menu.component.html',
})
export class DoorPlanContextMenuTrailerComponent {
  @Input() availableTrailers: Equipment[];
  @Output() itemSelected = new EventEmitter<GTAction>();
  @ViewChild(XpoContextMenuComponent, { static: true }) contextMenu: XpoContextMenuComponent<DoorPlanItem>;

  equipment: Equipment;

  openMenu(event: MouseEvent, doorPlanItem: DoorPlanItem) {
    this.equipment = doorPlanItem?.trailerInfo?.equipment;
    this.contextMenu.openMenu(event, doorPlanItem);
  }

  onItemClick(contextData: DoorPlanItem, trailerEvent: AssingTrailerEvent): void {
    const gtAction: GTAction = {
      action: trailerEvent.action,
      data: {
        firstRow: contextData,
        secondRow: null,
        additionalData: trailerEvent,
      },
    };
    this.itemSelected.emit(gtAction);
  }
}
