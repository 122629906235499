import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core';

import { XpoContextMenuComponent } from './context-menu.component';

@NgModule({
  declarations: [XpoContextMenuComponent],
  exports: [XpoContextMenuComponent],
  imports: [CommonModule, MatMenuModule, MatIconModule, XpoIconModule, MatDividerModule],
})
export class XpoContextMenuModule {}
