export enum AppRoutes {
  DEFAULT_PAGE = 'door-plan',
  EQUIPMENT_PAGE = 'equipment',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  DOOR_PLAN_PAGE = 'door-plan',
  PLT_TRAILER_MANIFEST = 'plt-trailer-manifest',
  LOAD_LANE_SUMMARY = 'load-lane-summary',
  DIVERSION_LANE = 'diversion-lane',
  CROSS_DOCK_TRAILERS = 'cross-dock-trailers',
}

export enum AppRoutesLabels {
  DOOR_PLAN_PAGE = 'Door Plan',
  PLT_TRAILER_MANIFEST = 'PLT Trailer Manifest',
  LOAD_LANE_SUMMARY = 'Lane Summary',
  DIVERSION_LANE = 'Linehaul Authorized Misloads',
  CROSS_DOCK_TRAILERS = 'Cross Dock Trailers',
}

export enum AppParams {
  SIC_CD = 'sicCd',
  SHIFT_CD = 'shiftCd',
  LOAD_LANE_DATE = 'planDate',
  THRESHOLD_WEIGHT = 'thresholdWeight',
  THRESHOLD_CUBE_PCT = 'thresholdCubePct',
  REMAINING_TO_LOAD_SHIPMENTS_IND = 'remainingToLoadShipmentsInd',
  HIDE_SHELL = 'hideShell',
}
