import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoadLaneContextMenuComponent } from '@app/door-plan-common/components/load-lane-context-menu/load-lane-context-menu.component';
import { DeepLoadingFormNames } from '@app/door-plan-common/enums/deep-loading/deep-loading-form-names.enum';
import { DoorPlanListService } from '@app/door-plan-list/services/door-plan-list-service.service';
import { ConfigManagerProperties } from '@app/shared/enums/config-manager-properties.enum';
import { DoorLengths, DoorPlanActionsEnum } from '@app/shared/enums/door-plan';
import { DoorPlanActionsService } from '@app/shared/services/door-plan-actions/door-plan-actions.service';
import { DoorPlanDataSourceService } from '@app/shared/services/door-plan-data-source/door-plan-data-source.service';
import { DeepLoadingDefaultFilterValues } from '@shared/enums/deep-loading-default-values.enum';
import { LoadLaneRegexPatterns } from '@shared/enums/load-lane/load-lane-patterns';
import { LoadLaneSummaryActionsEnum } from '@shared/enums/load-lane/load-lane-summary-actions.enum';
import { ActionsServiceEnum } from '@shared/enums/service-actions.enum';
import { DoorPlanItem, GTAction } from '@shared/models/door-plan';
import { CurrentDoorPlanProfileService } from '@shared/services/current-door-plan-profile/current-door-plan-profile.service';
import { LoadLaneSummaryService } from '@shared/services/load-lane-summary';
import { LoadLaneSummaryActionsService } from '@shared/services/load-lane-summary-actions/load-lane-summary-actions.service';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DeepLoadingFormBuilder } from './deep-loading-form-builder';

@Component({
  selector: 'app-deep-loading-list',
  templateUrl: './deep-loading-list.component.html',
  styleUrls: ['./deep-loading-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'deep-loading',
  },
})
export class DeepLoadingListComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  deepLoadingForm: FormGroup;
  cubeThresholdFormControl: FormControl;
  weightThresholdFormControl: FormControl;
  moveToFormControl: FormControl;
  sectorFormControl: FormControl;

  readonly formNames = DeepLoadingFormNames;
  readonly patterns = LoadLaneRegexPatterns;
  readonly lengthsEnum = DoorLengths;

  moveToOptions: string[] = [];
  emptyDoors: DoorPlanItem[] = [];
  currentMoveToFilters: string[] = [];
  currentSectorFilter: string = '';

  deepLoadingOpportunities: DoorPlanItem[] = [];
  private _filteredDeepLoadingOps = new BehaviorSubject<DoorPlanItem[]>([]);
  filteredDeepLoadingOps$: Observable<DoorPlanItem[]> = this._filteredDeepLoadingOps.asObservable();
  selectedTab: number = 0;
  isLoading = false;
  pilotSics: string[];
  pilotSicAllowed: boolean = false;
  pilotShiftAllowed: boolean = false;

  private switchTabSubject = new BehaviorSubject<boolean>(false);
  switchTab$: Observable<boolean> = this.switchTabSubject.asObservable();

  @ViewChild(LoadLaneContextMenuComponent) loadLaneContextMenu: LoadLaneContextMenuComponent;

  constructor(
    private fb: FormBuilder,
    private loadLaneSummaryService: LoadLaneSummaryService,
    private filtersService: NavbarFiltersService,
    private currentProfileService: CurrentDoorPlanProfileService,
    private doorPlanDataSourceService: DoorPlanDataSourceService,
    private loadLaneSummaryActionsService: LoadLaneSummaryActionsService,
    public doorPlanListService: DoorPlanListService,
    private doorPlanActionsService: DoorPlanActionsService,
    private config: ConfigManagerService
  ) {
    this.pilotSics = this.config
      .getSetting<string>(ConfigManagerProperties.pilotSics)
      .split(',')
      .map((p) => p.trim());
  }

  ngOnInit(): void {
    this.initForm();
    this.initFilterWatcher();
    this.getEmptyDoors();
    this.moveToSelectionWatcher();
    this.initSectorFilterWatcher();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private initForm(): void {
    this.deepLoadingForm = DeepLoadingFormBuilder.create(this.fb);
    this.cubeThresholdFormControl = this.deepLoadingForm.get([this.formNames.cubeThreshold]) as FormControl;
    this.weightThresholdFormControl = this.deepLoadingForm.get([this.formNames.weightThreshold]) as FormControl;
    this.moveToFormControl = this.deepLoadingForm.get([this.formNames.moveTo]) as FormControl;
    this.sectorFormControl = this.deepLoadingForm.get([this.formNames.sector]) as FormControl;
  }

  private initFilterWatcher(): void {
    combineLatest([
      this.cubeThresholdFormControl.valueChanges.pipe(startWith(DeepLoadingDefaultFilterValues.DEFAULT_CUBE)),
      this.weightThresholdFormControl.valueChanges.pipe(startWith(DeepLoadingDefaultFilterValues.DEFAULT_WEIGHT)),
      this.filtersService.selectedSic$,
      this.filtersService.selectedShift$,
      this.switchTab$,
      this.currentProfileService.currentDoorPlanProfile$,
      this.doorPlanListService.refreshData$.pipe(startWith(null)),
      this.initAddDoorWatcher().pipe(startWith(null)),
    ])
      .pipe(
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        debounceTime(500),
        switchMap(([cube, weight, sic, shift, switchTab]) => {
          this.pilotSicAllowed = this.isPilotSicAllowed(sic);
          this.pilotShiftAllowed = shift === ShiftCd.NIGHT_FAC || shift === ShiftCd.OUTBOUND;
          if (this.selectedTab === 0) {
            return this.loadLaneSummaryService.getDeepLoadingOpportunities(
              sic,
              shift,
              cube,
              weight,
              this.currentProfileService.getDoorPlanProfileDate()
            );
          } else if (this.selectedTab === 1 && this.pilotSicAllowed && this.pilotShiftAllowed) {
            return this.loadLaneSummaryService.getHeadLoadsOpportunities(
              sic,
              shift,
              this.currentProfileService.getCurrentDoorPlanProfile()
            );
          }
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((response: DoorPlanItem[]) => {
        if (this.selectedTab === 0) {
          this.deepLoadingOpportunities = response.sort(
            (a: DoorPlanItem, b: DoorPlanItem) => a.getLoadLevelOrder() - b.getLoadLevelOrder()
          );
        } else {
          this.deepLoadingOpportunities = response.sort(
            (a: DoorPlanItem, b: DoorPlanItem) => parseInt(a.doorNbr, 10) - parseInt(b.doorNbr, 10)
          );
        }
        this.isLoading = false;
        this._filteredDeepLoadingOps.next(this.deepLoadingOpportunities);
        this.populateMoveToFilter(response);
      });
  }

  private isPilotSicAllowed(sic: string): boolean {
    const intersection = this.pilotSics.indexOf(sic);
    return intersection === -1 ? false : true;
  }

  private isActionEnded(actionType: DoorPlanActionsEnum) {
    return (action: GTAction) => {
      return !!action && action.action === ActionsServiceEnum.ACTION_ENDED && action.previousAction === actionType;
    };
  }

  private initAddDoorWatcher(): Observable<GTAction> {
    const isPerformAddAction = this.isActionEnded(DoorPlanActionsEnum.PERFORM_ADD);

    return this.doorPlanActionsService.actionPerformed$.pipe(
      takeUntil(this.unsubscriber.done$),
      filter((action) => isPerformAddAction(action))
    );
  }

  private moveToSelectionWatcher(): void {
    this.moveToFormControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done$))
      .subscribe((moveToFilters: string[]) => {
        this.currentMoveToFilters = moveToFilters;
        this.updateFilteredOptions();
      });
  }

  private updateFilteredOptions(): void {
    if (this.currentMoveToFilters.length < 1) {
      this._filteredDeepLoadingOps.next(this.deepLoadingOpportunities);
    } else {
      const filteredOpportunities = this.deepLoadingOpportunities.filter((loadLane) =>
        this.currentMoveToFilters.includes(loadLane.farthestLoadPointSicCode)
      );
      this._filteredDeepLoadingOps.next(filteredOpportunities);
    }
    if (!!this.currentSectorFilter) {
      const filteredBySector = this._filteredDeepLoadingOps
        .getValue()
        .filter((headLoadOp: DoorPlanItem) => headLoadOp.doorSectorNbr === this.currentSectorFilter);
      this._filteredDeepLoadingOps.next(filteredBySector);
    }
  }

  private getEmptyDoors(): void {
    this.doorPlanDataSourceService.currentDoorPlanWithPreferences$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((emptyDoors) => {
        this.emptyDoors = emptyDoors.filter(
          (door) => door.isEmpty() && !door.doorPreference?.stepSaverExcludeInd && !door.doorPreference?.trapInd
        );
      });
  }

  private populateMoveToFilter(loadLanes: DoorPlanItem[]): void {
    this.moveToFormControl.setValue([]);
    this.moveToOptions = loadLanes.map(({ farthestLoadPointSicCode }) => farthestLoadPointSicCode);
    this.moveToOptions = [...new Set(this.moveToOptions)];
  }

  private removeDeepLoadingOpportunity(action: GTAction): void {
    this.deepLoadingOpportunities = this.deepLoadingOpportunities.filter(
      (loadingOp) =>
        !(
          loadingOp.firstLoadPointSicCode === action.data.firstRow.firstLoadPointSicCode &&
          loadingOp.farthestLoadPointSicCode === action.data.firstRow.farthestLoadPointSicCode
        )
    );

    this.updateFilteredOptions();
  }

  private removeMoveToFilterOption(action: GTAction): void {
    this.moveToOptions = this.moveToOptions.filter(
      (moveToOption) => !(moveToOption === action.data.firstRow.farthestLoadPointSicCode)
    );
  }

  private removeUpdatedDoor(action: GTAction): void {
    this.emptyDoors = this.emptyDoors.filter(
      (emptyDoor) => emptyDoor.displaySequenceNbr !== action.data.secondRow.displaySequenceNbr
    );
  }

  private initSectorFilterWatcher(): void {
    this.sectorFormControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done$))
      .subscribe((sector: string) => {
        this.currentSectorFilter = sector;
        this.updateFilteredOptions();
      });
  }

  setSelectedTab(event): void {
    this.selectedTab = event.index;
    this.switchTabSubject.next(event.index);
  }

  onAddDoorMenu(event: MouseEvent, doorPlanItem: DoorPlanItem): void {
    event.stopPropagation();
    this.loadLaneContextMenu.openMenu(event, doorPlanItem);
  }

  onItemSelected(action: GTAction): void {
    this.loadLaneSummaryActionsService.setActionInProgress(true);
    this.loadLaneSummaryActionsService.setActionPerformed(action);

    this.loadLaneSummaryActionsService.actionPerformed$
      .pipe(
        filter(
          (performedAction: GTAction) =>
            !!performedAction &&
            performedAction.action === ActionsServiceEnum.ACTION_ENDED &&
            performedAction.previousAction === LoadLaneSummaryActionsEnum.PERFORM_ADD
        ),
        take(1)
      )
      .subscribe(() => {
        this.removeUpdatedDoor(action);
        this.removeDeepLoadingOpportunity(action);
        this.removeMoveToFilterOption(action);
      });
  }
}
