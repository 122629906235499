import { Injectable } from '@angular/core';

import { DataOptions } from '@xpo-ltl/data-api';
import {
  DockOperationsApiService,
  Equipment,
  ListEquipmentBySicPath,
  ListEquipmentBySicQuery,
  ListEquipmentBySicResp,
} from '@xpo-ltl/sdk-dockoperations';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { TransactionTimestampInterceptor } from '../interceptors/transaction-timestamp.interceptor';

@Injectable({
  providedIn: 'root',
})
export class TrailerInfoService {
  private lastSicChecked: string = null;

  private readonly _allTrailersSubject = new BehaviorSubject<Equipment[]>([]);
  allTrailers$ = this._allTrailersSubject.asObservable();

  get allTrailers(): Equipment[] {
    return this._allTrailersSubject.value;
  }

  constructor(private dockOperationsApiService: DockOperationsApiService) {}

  setAllTrailers(trailers: Equipment[]): void {
    this._allTrailersSubject.next(trailers);
  }

  getTrailerInfo(sicCd: string, useCache = true, loadingOverlayEnabled = true): Observable<Equipment[]> {
    if (!sicCd) {
      return of(null);
    }
    if (useCache && sicCd === this.lastSicChecked) {
      return this.allTrailers$;
    }

    const path: ListEquipmentBySicPath = {
      sicCd,
    };
    const queryParams: Partial<ListEquipmentBySicQuery> = {
      fromDoorNbr: '0001',
      toDoorNbr: '9999',
    };
    const options: DataOptions = {
      toastOnError: false,
      loadingOverlayEnabled,
    };

    return this.dockOperationsApiService
      .listEquipmentBySic(
        path,
        queryParams as ListEquipmentBySicQuery,
        options,
        TransactionTimestampInterceptor.useTransactionTimestampHeader
      )
      .pipe(
        map((response: ListEquipmentBySicResp) => response.equipment),
        tap((equipmentData) => {
          this.setAllTrailers(equipmentData);
          this.lastSicChecked = sicCd;
        })
      );
  }
}
