import { Injectable } from '@angular/core';

import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * LoggedInUser service that returns a mocked user
 */
@Injectable()
export class XpoLtlLoggedInUserServiceAlwaysLoggedIn extends XpoLtlLoggedInUserService {
  getLoggedInUser(loggedInUserEndpoint: string): Observable<User> {
    const mockedUser = {
      userId: 'cmuser',
      employeeId: 'MOCK',
      displayName: 'User,  Mock',
      givenName: 'Mock',
      lastName: 'User',
      title: 'Senior Mock,  Cypress Testing',
      emailAddress: 'Mock.User@xpo.com',
      roles: ['CONWAY/DEFAULT_TABIGATOR', 'CONWAY/Conway_Tab_Default', 'Internal/everyone'],
      sicCode: 'IFR',
    } as User;

    return of(mockedUser).pipe(
      tap(() => {
        this['userLoggedInSubject'].next();
      })
    );
  }
}
