import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-p1-door-dialog',
  templateUrl: './remove-p1-door-dialog.component.html',
  styleUrls: ['./remove-p1-door-dialog.component.scss'],
})
export class RemoveP1DoorDialogComponent {
  constructor(public dialogRef: MatDialogRef<RemoveP1DoorDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
