import { Pipe, PipeTransform } from '@angular/core';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { ShiftHelper } from '../../classes/shift-helper';

@Pipe({
  name: 'freightFlowShift',
})
export class FreightFlowShiftPipe implements PipeTransform {
  transform(shiftCd: ShiftCd): any {
    if (!shiftCd) {
      return null;
    }
    // Could add different transformations based on format parameter
    return ShiftHelper.getFreightFlowShift(shiftCd);
  }
}
