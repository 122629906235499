import { FilterColumnCriteriaHelper } from '@app/shared/classes/filter-column-criteria-helper';
import { LoadLevelsEnum } from '@app/shared/enums/load-lane';
import { Operators, XpoFilterColumnCriteria, XpoFilterCriteria } from '@xpo-ltl/ngx-ltl-board';
import _ from 'lodash';
import { LaneTypeHelper } from '../../classes/lane-type-helper.class';

import { DoorPlanFilterCriteria } from './door-plan-filter-criteria.model';
import { DoorPlanItem } from './door-plan-item.model';

export class DoorPlanConditionalFilterCriteria implements XpoFilterCriteria {
  private criteria: DoorPlanFilterCriteria;
  doorSectorNbr: XpoFilterColumnCriteria;
  doorNbr: XpoFilterColumnCriteria;
  priorityNbr: XpoFilterColumnCriteria;
  laneTypeCd: XpoFilterColumnCriteria;
  firstLoadPointSicCode: XpoFilterColumnCriteria;
  farthestLoadPointSicCode: XpoFilterColumnCriteria;
  scoDoorPlanDestination: XpoFilterColumnCriteria;
  loadLevel: XpoFilterColumnCriteria;

  constructor(data?: XpoFilterCriteria) {
    const filterCriteria = data as DoorPlanConditionalFilterCriteria;
    if (filterCriteria && filterCriteria.criteria) {
      this.criteria = filterCriteria.criteria;

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.doorNbr)) {
        this.doorNbr = filterCriteria.doorNbr;
      } else {
        this.criteria.oneDoorNbr = undefined;
        this.criteria.doorNumberTo = undefined;
        this.criteria.doorNumberFrom = undefined;
      }

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.doorSectorNbr)) {
        this.doorSectorNbr = filterCriteria.doorSectorNbr;
      } else {
        this.criteria.sector = undefined;
      }

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.priorityNbr)) {
        this.priorityNbr = filterCriteria.priorityNbr;
      } else {
        this.criteria.priorities = undefined;
      }

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.laneTypeCd)) {
        this.laneTypeCd = filterCriteria.laneTypeCd;
      } else {
        this.criteria.doorTypes = undefined;
      }
      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.firstLoadPointSicCode)) {
        this.firstLoadPointSicCode = filterCriteria.firstLoadPointSicCode;
      } else {
        this.criteria.firstLoadPointSicCodes = undefined;
      }
      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.farthestLoadPointSicCode)) {
        this.farthestLoadPointSicCode = filterCriteria.farthestLoadPointSicCode;
      } else {
        this.criteria.farthestLoadPointSicCodes = undefined;
      }

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.scoDoorPlanDestination)) {
        this.scoDoorPlanDestination = filterCriteria.scoDoorPlanDestination;
      } else {
        this.criteria.scoDoorPlanDestinations = undefined;
      }

      if (FilterColumnCriteriaHelper.hasConditions(filterCriteria.loadLevel)) {
        this.loadLevel = filterCriteria.loadLevel;
      } else {
        this.criteria.loadLevels = undefined;
      }
    }
  }

  static createFromFilterCriteria(data?: Partial<DoorPlanFilterCriteria>) {
    const conditionalFilter = new DoorPlanConditionalFilterCriteria();
    conditionalFilter.setCriteria(data as DoorPlanFilterCriteria);
    conditionalFilter.addSectorFilter(data);
    conditionalFilter.addOneDoorNumberFilter(data);
    conditionalFilter.addRangeDoorNumberFilter(data);
    conditionalFilter.addPriorityFilter(data);
    conditionalFilter.addDoorTypesFilter(data);
    conditionalFilter.addFirstLoadPointSicCodeCriteria(data);
    conditionalFilter.addFarthestLoadPointSicCodeCriteria(data);
    conditionalFilter.addDoorPlanDestinationCriteria(data);
    conditionalFilter.addLoadLevelCriteria(data);
    return conditionalFilter;
  }

  setCriteria(criteria: DoorPlanFilterCriteria): void {
    this.criteria = criteria;
  }

  private addSectorFilter(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.sector) {
      this.doorSectorNbr = {
        conditions: [
          {
            operator: Operators.Equals,
            value: criteria.sector,
          },
        ],
      };
    }
  }

  private addPriorityFilter(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.priorities) {
      this.priorityNbr = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.priorities,
          },
        ],
      };
    }
  }

  private addLoadLevelCriteria(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.loadLevels) {
      this.loadLevel = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.loadLevels,
          },
        ],
      };
    }
  }

  private addDoorTypesFilter(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.doorTypes) {
      this.laneTypeCd = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.doorTypes,
          },
        ],
      };
    }
  }

  private addOneDoorNumberFilter(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.oneDoorNbr) {
      this.doorNbr = {
        conditions: [
          {
            operator: Operators.Equals,
            value: criteria.oneDoorNbr,
          },
        ],
      };
    }
  }

  private addRangeDoorNumberFilter(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.doorNumberFrom && criteria.doorNumberTo) {
      this.doorNbr = {
        conditions: [
          {
            operator: Operators.Between,
            value: [criteria.doorNumberFrom, criteria.doorNumberTo],
          },
        ],
      };
    }
  }

  private addFirstLoadPointSicCodeCriteria(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.firstLoadPointSicCodes) {
      this.firstLoadPointSicCode = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.firstLoadPointSicCodes,
          },
        ],
      };
    }
  }

  private addFarthestLoadPointSicCodeCriteria(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.farthestLoadPointSicCodes) {
      this.farthestLoadPointSicCode = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.farthestLoadPointSicCodes,
          },
        ],
      };
    }
  }

  private addDoorPlanDestinationCriteria(criteria?: Partial<DoorPlanFilterCriteria>): void {
    if (criteria && criteria.scoDoorPlanDestinations) {
      this.scoDoorPlanDestination = {
        conditions: [
          {
            operator: Operators.Contains,
            value: criteria.scoDoorPlanDestinations,
          },
        ],
      };
    }
  }

  hasValues(): boolean {
    return (
      !!this.doorSectorNbr ||
      !!this.doorNbr ||
      !!this.laneTypeCd ||
      !!this.priorityNbr ||
      !!this.firstLoadPointSicCode ||
      !!this.farthestLoadPointSicCode ||
      !!this.scoDoorPlanDestination ||
      !!this.loadLevel
    );
  }

  matchFilters(doorPlanItem: DoorPlanItem): boolean {
    let matchCriteria: boolean = true;

    if (this.criteria.sector) {
      matchCriteria = doorPlanItem.doorSectorNbr && doorPlanItem.doorSectorNbr === this.criteria.sector;
    }

    if (matchCriteria && this.criteria.oneDoorNbr) {
      let trimmedCurrentDoor = null;

      if (doorPlanItem.doorNbr) {
        trimmedCurrentDoor = _.trimStart(doorPlanItem.doorNbr, '0');
      }

      const trimmedPickedDoor = _.trimStart(this.criteria.oneDoorNbr, '0');

      matchCriteria = trimmedCurrentDoor && +trimmedCurrentDoor === +trimmedPickedDoor;
    }

    if (matchCriteria && this.criteria.doorNumberFrom && this.criteria.doorNumberTo) {
      let trimmedCurrentDoor = null;

      if (doorPlanItem.doorNbr) {
        trimmedCurrentDoor = _.trimStart(doorPlanItem.doorNbr, '0');
      }

      const trimmedRangeInitialDoor = _.trimStart(this.criteria.doorNumberFrom, '0');
      const trimmedRangeFinalDoor = _.trimStart(this.criteria.doorNumberTo, '0');

      matchCriteria =
        trimmedCurrentDoor &&
        +trimmedCurrentDoor >= +trimmedRangeInitialDoor &&
        +trimmedCurrentDoor <= +trimmedRangeFinalDoor;
    }

    if (matchCriteria && this.criteria.doorTypes && this.criteria.doorTypes.length > 0) {
      matchCriteria =
        doorPlanItem.laneTypeCd && this.criteria.doorTypes.includes(LaneTypeHelper.getLaneApp(doorPlanItem.laneTypeCd));
    }

    if (matchCriteria && this.criteria.priorities && this.criteria.priorities.length > 0) {
      matchCriteria =
        doorPlanItem.priorityNbr && this.criteria.priorities.includes(doorPlanItem.priorityNbr.toString());
    }

    if (matchCriteria && this.criteria.firstLoadPointSicCodes && this.criteria.firstLoadPointSicCodes.length > 0) {
      matchCriteria =
        doorPlanItem.firstLoadPointSicCode &&
        this.criteria.firstLoadPointSicCodes.includes(doorPlanItem.firstLoadPointSicCode.toString());
    }

    if (
      matchCriteria &&
      this.criteria.farthestLoadPointSicCodes &&
      this.criteria.farthestLoadPointSicCodes.length > 0
    ) {
      matchCriteria =
        doorPlanItem.farthestLoadPointSicCode &&
        this.criteria.farthestLoadPointSicCodes.includes(doorPlanItem.farthestLoadPointSicCode.toString());
    }

    if (matchCriteria && this.criteria.scoDoorPlanDestinations && this.criteria.scoDoorPlanDestinations.length > 0) {
      const doorPlanDestinationSicCodes: string[] = _.uniq(
        _.map(doorPlanItem.scoDoorPlanDestination, 'destinationSicCode')
      );
      matchCriteria =
        doorPlanItem.scoDoorPlanDestination &&
        _.intersection(this.criteria.scoDoorPlanDestinations, doorPlanDestinationSicCodes).length > 0;
    }

    if (matchCriteria && this.criteria.loadLevels?.length) {
      matchCriteria =
        doorPlanItem.loadLaneInfo?.loadLevel && this.criteria.loadLevels.includes(doorPlanItem.loadLaneInfo.loadLevel);
    }

    return matchCriteria;
  }
}
