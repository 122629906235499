import { TemplateRef } from '@angular/core';

import { XpoContextMenuItem } from '@app/context-menu/models';
import { DoorPlanActionsEnum } from '@app/shared/enums/door-plan';
import { DoorPlanItem } from '@app/shared/models/door-plan';

export class DoorPlanContextMenuHelper {
  private static defineItems(
    contextSubmenuTpl: TemplateRef<any>,
    contextMenuAddUpdatePlanTpl: TemplateRef<any>,
    emptyMenuItemExcludedTpl: TemplateRef<any>,
    emptyMenuItemHoldTpl: TemplateRef<any>,
    emptyMenuItemEmbargoTpl: TemplateRef<any>
  ): { [key: string]: XpoContextMenuItem<DoorPlanItem> } {
    return {
      [DoorPlanActionsEnum.PERFORM_COPY]: {
        name: DoorPlanActionsEnum.PERFORM_COPY,
        icon: 'content_copy',
        label: 'Copy to',
        subMenuTemplate: contextSubmenuTpl,
      },
      [DoorPlanActionsEnum.PERFORM_MERGE]: {
        name: DoorPlanActionsEnum.PERFORM_MERGE,
        icon: 'call_merge',
        label: 'Merge with',
        subMenuTemplate: contextSubmenuTpl,
      },
      [DoorPlanActionsEnum.PERFORM_MOVE]: {
        name: DoorPlanActionsEnum.PERFORM_MOVE,
        icon: 'arrow_right_alt',
        label: 'Move to',
        subMenuTemplate: contextSubmenuTpl,
      },
      [DoorPlanActionsEnum.PERFORM_SWAP]: {
        name: DoorPlanActionsEnum.PERFORM_SWAP,
        icon: 'swap_horiz',
        label: 'Swap with',
        subMenuTemplate: contextSubmenuTpl,
      },
      [DoorPlanActionsEnum.PERFORM_REMOVE]: {
        name: DoorPlanActionsEnum.PERFORM_REMOVE,
        icon: 'delete',
        label: 'Remove',
      },
      [DoorPlanActionsEnum.PERFORM_ADD]: {
        name: DoorPlanActionsEnum.PERFORM_ADD,
        icon: 'add',
        label: 'Add Lane',
        subMenuTemplate: contextMenuAddUpdatePlanTpl,
      },
      [DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER]: {
        name: DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER,
        labelTpl: emptyMenuItemExcludedTpl,
      },
      [DoorPlanActionsEnum.REMOVE_EXCLUSION]: {
        name: DoorPlanActionsEnum.REMOVE_EXCLUSION,
        labelTpl: emptyMenuItemExcludedTpl,
      },
      [DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT]: {
        name: DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT,
        labelTpl: emptyMenuItemHoldTpl,
      },
      [DoorPlanActionsEnum.EMBARGO_DOOR]: {
        name: DoorPlanActionsEnum.EMBARGO_DOOR,
        labelTpl: emptyMenuItemEmbargoTpl,
      },
      [DoorPlanActionsEnum.REMOVE_EMBARGO]: {
        name: DoorPlanActionsEnum.REMOVE_EMBARGO,
        labelTpl: emptyMenuItemEmbargoTpl,
      },
      [DoorPlanActionsEnum.REMOVE_HOLD]: {
        name: DoorPlanActionsEnum.REMOVE_HOLD,
        labelTpl: emptyMenuItemHoldTpl,
      },
      [DoorPlanActionsEnum.MAKE_STATIC]: {
        name: DoorPlanActionsEnum.MAKE_STATIC,
        xpoIcon: 'pin-filled',
        label: 'Make Static',
      },
      // TODO:
      /**
       * To be done LDOC-526 - case 3
      {
        name: DoorPlanActionsEnum.PERFORM_UPDATE,
        icon: 'add',
        label: 'Add',
        submenu: subMenuOptions,
        subMenuTemplate: this.contextMenuAddUpdatePlanTpl
      },
        * NOTE: removed because of LDOC-488
      {
        name: DoorPlanActionsEnum.PERFORM_RESET,
        icon: 'refresh',
        label: 'Reset',
      },*/
    };
  }

  static getMenus(
    contextSubmenuTpl: TemplateRef<any>,
    contextMenuAddUpdatePlanTpl: TemplateRef<any>,
    emptyMenuItemExcludedTpl: TemplateRef<any>,
    emptyMenuItemHoldTpl: TemplateRef<any>,
    emptyMenuItemEmbargoTpl: TemplateRef<any>
  ): { [key: string]: XpoContextMenuItem<DoorPlanItem>[] } {
    const items = this.defineItems(
      contextSubmenuTpl,
      contextMenuAddUpdatePlanTpl,
      emptyMenuItemExcludedTpl,
      emptyMenuItemHoldTpl,
      emptyMenuItemEmbargoTpl
    );
    const priority1 = [
      items[DoorPlanActionsEnum.PERFORM_COPY],
      items[DoorPlanActionsEnum.PERFORM_MERGE],
      items[DoorPlanActionsEnum.PERFORM_MOVE],
      items[DoorPlanActionsEnum.PERFORM_SWAP],
      items[DoorPlanActionsEnum.PERFORM_REMOVE],
    ];
    const priority2 = priority1;

    return {
      priority1,
      priority2,
      priority1StaticDoor: [...priority1],
      priority2StaticDoor: [...priority1],
      priority1NonStaticDoor: [...priority1, items[DoorPlanActionsEnum.MAKE_STATIC]],
      priority2NonStaticDoor: [...priority2],
      priority1NonStaticDoorEmbargoed: [...priority1],
      priority2NonStaticDoorEmbargoed: [...priority2],
      emptyDoorTrapDoor: [items[DoorPlanActionsEnum.REMOVE_HOLD]],
      excludedDoor: [items[DoorPlanActionsEnum.REMOVE_EXCLUSION]],
      emptyDoorNonValidShiftExclude: [
        items[DoorPlanActionsEnum.PERFORM_ADD],
        items[DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT],
        items[DoorPlanActionsEnum.EMBARGO_DOOR],
      ],
      emptyDoorValidShiftExclude: [
        items[DoorPlanActionsEnum.PERFORM_ADD],
        items[DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER],
        items[DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT],
        items[DoorPlanActionsEnum.EMBARGO_DOOR],
      ],
      emptyDoorValidShiftExcludeStatic: [
        items[DoorPlanActionsEnum.PERFORM_ADD],
        items[DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER],
        items[DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT],
      ],
      emptyDoorNonValidShiftExcludeStatic: [
        items[DoorPlanActionsEnum.PERFORM_ADD],
        items[DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT],
      ],
      embargoDoor: [items[DoorPlanActionsEnum.PERFORM_ADD], items[DoorPlanActionsEnum.REMOVE_EMBARGO]],
    };
  }
}
