import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { DoorPlanTrailerInfo } from '@shared/models/door-plan';

@Component({
  selector: 'app-door-plan-card-trailer-info',
  templateUrl: './door-plan-card-trailer-info.component.html',
  styleUrls: ['./door-plan-card-trailer-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'trailer-info' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoorPlanCardTrailerInfoComponent {
  @Input()
  trailerInfo: DoorPlanTrailerInfo;

  @Output()
  trailerMenu = new EventEmitter<MouseEvent>();

  onOpenTrailerMenu(event: MouseEvent): void {
    this.trailerMenu.emit(event);
  }
}
