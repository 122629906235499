import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Route } from '@shared/models/route.model';

@Component({
  selector: 'app-door-plan-routes',
  templateUrl: './door-plan-routes.component.html',
  styleUrls: ['./door-plan-routes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-routes',
  },
})
export class DoorPlanRoutesComponent implements OnInit {
  readonly maxRoutesDisplayed = 2;
  readonly columnNames = {
    ROUTE: 'route',
    TRAILER: 'trailer',
  };
  readonly displayedColumns = Object.values(this.columnNames);

  @Input()
  routes: Route[];

  routesText: string;
  hiddenRoutes: number;

  ngOnInit(): void {
    this.populateRoutesText();
  }

  private populateRoutesText(): void {
    let filteredRoutes: Route[];

    if (this.routes.length > this.maxRoutesDisplayed) {
      filteredRoutes = this.routes.slice(0, this.maxRoutesDisplayed);
      this.hiddenRoutes = this.routes.length - this.maxRoutesDisplayed;
    } else {
      filteredRoutes = this.routes;
    }

    this.routesText = filteredRoutes.map((route) => `${route.routePrefix}-${route.routeSuffix}`).join(', ');
  }
}
