import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Equipment } from '@xpo-ltl/sdk-dockoperations';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { AssignTrailerFormNames } from '@app/door-plan-common/enums';
import { AssingTrailerEvent } from '@app/door-plan-common/interfaces/assign-trailer-event.interface';
import { TrailerStatusColors } from '@shared/enums/assign-trailer/trailer-status-color.enum';
import { TrailerStatusEnum } from '@shared/enums/assign-trailer/trailer-status.enum';
import { DoorPlanActionsEnum } from '@shared/enums/door-plan';
import { TrailerNumberPipe } from '@shared/pipes/trailer-number/trailer-number.pipe';
import { AssignTrailerFormBuilder } from './assign-trailer.form-builder';

@Component({
  selector: 'app-assign-trailer',
  templateUrl: './assign-trailer.component.html',
  styleUrls: ['./assign-trailer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TrailerNumberPipe],
  host: {
    class: 'assign-trailer',
  },
})
export class AssignTrailerComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  readonly formNames = AssignTrailerFormNames;
  readonly trailerColors = TrailerStatusColors;

  @ViewChild('equipmentInput') autoEquipment: MatAutocompleteTrigger;
  @Input() availableTrailers: Equipment[] = [];
  @Input() equipment: Equipment;
  @Output() saveChanges = new EventEmitter<AssingTrailerEvent>();

  filteredAvailableTrailers$: Observable<Equipment[]>;
  assignTrailerForm: FormGroup;
  displayFn = this._displayFn.bind(this);
  action: DoorPlanActionsEnum;
  statusOptions = Object.keys(TrailerStatusEnum);

  constructor(private fb: FormBuilder, private trailerNumberPipe: TrailerNumberPipe) {}

  ngOnInit(): void {
    this.initForm();
    this.loadMode();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onPanelClick(mouseEvent: MouseEvent): void {
    this.autoEquipment?.closePanel();
    mouseEvent.stopPropagation();
  }

  onItemClick(): void {
    this.saveChanges.emit(AssignTrailerFormBuilder.values(this.assignTrailerForm, this.action));
  }

  trackByEquipment(index: number, equipment: Equipment): number {
    return equipment?.equipmentId;
  }

  private _displayFn(trailer: Equipment): string {
    return this.trailerNumberPipe.transform(trailer);
  }

  private initForm(): void {
    this.assignTrailerForm = AssignTrailerFormBuilder.create(this.fb);
  }

  private loadMode(): void {
    if (this.equipment) {
      this.initUpdateMode();
    } else {
      this.initCreateMode();
    }
  }

  private initUpdateMode(): void {
    this.action = DoorPlanActionsEnum.UPDATE_TRAILER;
    this.assignTrailerForm.setValue({
      [this.formNames.equipment]: this.equipment,
      [this.formNames.status]: this.equipment.trailerLoad?.currentStatus,
    });
  }

  private initCreateMode(): void {
    this.action = DoorPlanActionsEnum.ASSIGN_TRAILER;
    this.filteredAvailableTrailers$ = this.assignTrailerForm.get(this.formNames.equipment).valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      startWith(''),
      map((value) => {
        if (typeof value === 'string') {
          this.assignTrailerForm.get(this.formNames.status).reset({
            value: null,
            disabled: true,
          });

          return value;
        } else {
          const equipment = value as Equipment;
          this.assignTrailerForm.get(this.formNames.status).reset({
            value: equipment.trailerLoad?.currentStatus,
            disabled: false,
          });

          return this.trailerNumberPipe.transform(value);
        }
      }),
      map((name: string) => this.filterAvailableTrailers(name))
    );
  }

  private filterAvailableTrailers(name: string): Equipment[] {
    if (name) {
      const filterValue = name.toLowerCase();

      return this.availableTrailers.filter((trailer) => {
        const filterName = this.trailerNumberPipe.transform(trailer);

        return filterName.toLowerCase().includes(filterValue);
      });
    }

    return [...this.availableTrailers];
  }
}
