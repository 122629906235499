import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BatchEditWarningConfig } from '@app/shared/interfaces/bulk-edit-warning-config';
import { DoorPlanActionsEnum } from '@shared/enums/door-plan';
import { DoorPlanItem } from '@shared/models/door-plan';

interface DoorsWithErrors {
  errorDescription: string;
  doors: DoorPlanItem[];
}

@Component({
  selector: 'app-batch-edit-warning',
  templateUrl: './bulk-edit-warning.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bulk-edit-warning.component.scss'],
})
export class BulkEditWarningComponent implements OnInit {
  actionName: string = '';
  dialogTitle: string = '';
  dialogDescription: string = '';
  notUpdatedDescription: string = '';
  nonUpdatedDoorList: DoorPlanItem[];
  updatedDoorList: DoorPlanItem[];
  errorList: DoorsWithErrors[] = [];

  constructor(
    public dialogRef: MatDialogRef<BulkEditWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BatchEditWarningConfig
  ) {}

  ngOnInit(): void {
    const action = this.data.action;
    const selectedDoors: DoorPlanItem[] = this.data.selectedDoors
      ? this.data.selectedDoors.sort((a, b) => (!a.doorNbr ? -1 : Number(a.doorNbr) - Number(b.doorNbr || 0)))
      : [];
    this.setDialogValues(action, selectedDoors);
  }

  private setDialogValues(action: DoorPlanActionsEnum, selectedDoors: DoorPlanItem[]) {
    switch (action) {
      case DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER:
        this.actionName = 'EXCLUDE';
        this.dialogTitle = 'Exclude door from Step Saver?';
        this.dialogDescription = 'The following doors will be excluded from Step Saver';
        this.updatedDoorList = this.getEmptyDoors(selectedDoors);
        this.errorList.push({
          errorDescription: 'The following doors cannot be excluded, there is a Door Plan assigned',
          doors: this.getNonEmptyDoors(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.REMOVE_EXCLUSION:
        this.actionName = 'REMOVE EXCLUSION';
        this.dialogTitle = 'Remove Step Saver exclusion?';
        this.dialogDescription = 'The following doors will no longer be excluded from Step Saver';
        this.updatedDoorList = this.getEmptyDoors(selectedDoors);
        this.errorList.push({
          errorDescription: 'The following doors cannot be unexcluded, there is a Door Plan assigned',
          doors: this.getNonEmptyDoors(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT:
        this.actionName = 'HOLD';
        this.dialogTitle = 'Hold door for next shift?';
        this.dialogDescription = 'The following doors will be held for the next shift';
        this.updatedDoorList = this.getDoorsToBeHeld(selectedDoors);
        this.errorList.push({
          errorDescription:
            'The following doors cannot be held, either there is a Door Plan assigned or they are excluded',
          doors: [...new Set([...this.getNonEmptyDoors(selectedDoors), ...this.getExcludedDoors(selectedDoors)])],
        });
        break;
      case DoorPlanActionsEnum.REMOVE_HOLD:
        this.actionName = 'REMOVE HOLD';
        this.dialogTitle = 'Remove hold?';
        this.dialogDescription = 'The following doors will no longer be held for the next shift';
        this.updatedDoorList = this.getEmptyDoors(selectedDoors);

        this.errorList.push({
          errorDescription: 'The following doors cannot be removed from hold, there is a Door Plan assigned',
          doors: this.getNonEmptyDoors(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.EMBARGO_DOOR:
        this.actionName = 'EMBARGO';
        this.dialogTitle = 'Mark door as embargoed?';
        this.dialogDescription = 'The following doors will be embargoed';
        this.updatedDoorList = this.getDoorsToBeHeld(selectedDoors);
        this.errorList.push({
          errorDescription:
            'The following doors cannot be embargoed, either there is a Door Plan assigned or they are excluded',
          doors: [...new Set([...this.getNonEmptyDoors(selectedDoors), ...this.getExcludedDoors(selectedDoors)])],
        });
        break;
      case DoorPlanActionsEnum.REMOVE_EMBARGO:
        this.actionName = 'REMOVE EMBARGO';
        this.dialogTitle = 'Remove embargo?';
        this.dialogDescription = 'The following doors will no longer be embargoed';
        this.updatedDoorList = this.getEmptyDoors(selectedDoors);

        this.errorList.push({
          errorDescription: 'The following doors cannot be removed from embargo, there is a Door Plan assigned',
          doors: this.getNonEmptyDoors(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.MARK_UNLOAD:
        this.actionName = 'MARK AS UNLOAD';
        this.dialogTitle = 'Mark door as Unload?';
        this.dialogDescription = 'The following doors will be used as unload doors by Step Saver';
        this.updatedDoorList = this.getDoorsNonHolded(selectedDoors);
        this.errorList.push({
          errorDescription: 'Following doors cannot be marked as Unload as they are Hold doors',
          doors: this.getDoorsOnHold(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.REMOVE_UNLOAD:
        this.actionName = 'REMOVE UNLOAD';
        this.dialogTitle = 'Remove Unload?';
        this.dialogDescription = 'The following doors will no longer be unload doors';
        this.updatedDoorList = selectedDoors;
        break;
      case DoorPlanActionsEnum.MAKE_STATIC:
        this.actionName = 'SET TO STATIC';
        this.dialogTitle = 'Set the lane to static?';
        this.dialogDescription = 'The lanes on the following doors will be set to static';
        this.updatedDoorList = this.getEmptyDoors(selectedDoors);
        this.errorList.push({
          errorDescription: 'The following lanes cannot be set to static, there is not a Door Plan assigned',
          doors: this.getNonEmptyDoors(selectedDoors),
        });
        break;
      case DoorPlanActionsEnum.REMOVE_STATIC:
        this.actionName = 'REMOVE STATIC LANE';
        this.dialogTitle = 'Remove Static Lane?';
        this.dialogDescription = 'The lanes on the following doors will no longer be static';
        this.updatedDoorList = selectedDoors;
        break;
      default:
        this.onCancel();
    }
  }

  onAction() {
    this.dialogRef.close(this.updatedDoorList);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private getEmptyDoors(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => doorPlan.isEmpty());
  }
  private getNonEmptyDoors(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => !doorPlan.isEmpty());
  }
  private getDoorsOnHold(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => doorPlan?.doorPreference?.trapInd);
  }
  private getDoorsNonHolded(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => !doorPlan?.doorPreference?.trapInd);
  }
  private getExcludedDoors(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => doorPlan?.doorPreference?.stepSaverExcludeInd);
  }
  private getDoorsToBeHeld(selectedDoors: DoorPlanItem[]): DoorPlanItem[] {
    return selectedDoors.filter((doorPlan) => !doorPlan?.doorPreference?.stepSaverExcludeInd && doorPlan.isEmpty());
  }
}
