import { Injectable } from '@angular/core';
import {  LocationSic } from '@xpo-ltl-2.0/sdk-location';
import { compact, flatten, map, uniq } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DoorPlanFieldKeys } from '../../enums/door-plan';
import { DoorPlanDestination, DoorPlanItem } from '../../models/door-plan';
import { SicService } from '../sic-service/sic.service';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanFilterService {
  private readonly _moveToSicCodesSubject = new BehaviorSubject<LocationSic[]>([]);
  private readonly _closeToSicCodesSubject = new BehaviorSubject<LocationSic[]>([]);
  private readonly _destinationSicCodesSubject = new BehaviorSubject<LocationSic[]>([]);
  moveToSicCodes$ = this._moveToSicCodesSubject.asObservable();
  closeToSicCodes$ = this._closeToSicCodesSubject.asObservable();
  destinationSicCodes$ = this._destinationSicCodesSubject.asObservable();

  constructor(private sicService: SicService) {}

  setFilterData(doorPlans: DoorPlanItem[]): void {
    this.extractFilterData(doorPlans);
  }

  private extractFilterData(doorPlans: DoorPlanItem[]) {
    const destinationArrays: DoorPlanDestination[][] = uniq(
      map(doorPlans, DoorPlanFieldKeys.SCO_DOOR_PLAN_DESTINATION)
    );
    const flattenedDestinationArray: DoorPlanDestination[] = flatten(destinationArrays);

    const destinationSicCodes = compact(uniq(map(flattenedDestinationArray, DoorPlanFieldKeys.DESTINATION_SC)));
    const moveToSicCodes = compact(uniq(map(doorPlans, DoorPlanFieldKeys.FIRST_LOAD_POINT_SC)));
    const closeToSicCodes = compact(uniq(map(doorPlans, DoorPlanFieldKeys.FARTHEST_LOAD_POINT_SC)));

    const moveToIntersection = this.sicService.completeSicInformation(moveToSicCodes);
    this._moveToSicCodesSubject.next(moveToIntersection);
    const closeToIntersection = this.sicService.completeSicInformation(closeToSicCodes);
    this._closeToSicCodesSubject.next(closeToIntersection);
    const destinationIntersection = this.sicService.completeSicInformation(destinationSicCodes);
    this._destinationSicCodesSubject.next(destinationIntersection);
  }
}
