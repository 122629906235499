import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';

import { XpoContextMenuComponent } from '@app/context-menu/context-menu.component';
import { MultipleFreightFlowPathDialogComponent } from '@app/dialogs/multiple-freight-flow-path/multiple-freight-flow-path.component';
import { ActiveSicFormHelper } from '@app/door-plan-common/classes';
import { AddUpdatePlanEventType, AddUpdatePlanFormNames } from '@app/door-plan-common/enums';
import { AddUpdatePlanEvent } from '@app/door-plan-common/interfaces';
import { DoorPlanItem } from '@app/shared/models/door-plan';
import { AppCacheKeys } from '@shared/enums/app-cache-keys.enum';
import { GetCloseToDestinationsResponse } from '@shared/interfaces';
import { AppCacheService } from '@shared/services/cache-service/cache.service';
import { DoorPlanService } from '@shared/services/door-plan-service/door-plan.service';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service';
import { LocationEmbargo } from '@xpo-ltl-2.0/sdk-freightflow';
import { ActiveSic, ListLocationsResp, LocationSic } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { GetDestinationsByLoadLaneResp } from '@xpo-ltl/sdk-dockoperations';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { AddUpdatePlanFormValidator } from '../../validators';

@Component({
  selector: 'app-door-plan-context-menu-add-update-plan',
  templateUrl: './door-plan-context-menu-add-update-plan.component.html',
  styleUrls: ['./door-plan-context-menu-add-update-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-context-menu-add-update-plan',
  },
})
export class DoorPlanContextMenuAddUpdatePlanComponent implements OnInit {
  private unsubscriber = new Unsubscriber();
  readonly AddUpdatePlanFormNames = AddUpdatePlanFormNames;
  @ViewChild('moveToInput') autoMoveTo: MatAutocompleteTrigger;
  @ViewChild('closeToInput') autoCloseTo: MatAutocompleteTrigger;

  @Input()
  set embargoMenu(embargoMenu: boolean) {
    if (embargoMenu) {
      this.embargo = embargoMenu;
      this.destinationVisible = embargoMenu;
    }
  }

  @Input() embargoSics: LocationEmbargo[];

  @Input()
  contextMenuRef: XpoContextMenuComponent<DoorPlanItem>;

  @Input()
  set moveToSicCodes(moveToSicCodes: LocationSic[]) {
    if (moveToSicCodes?.length > 0) {
      this.setFilteredMoveTo(moveToSicCodes);
    }
  }
  @Output() itemSelected = new EventEmitter<AddUpdatePlanEvent>();

  /** To enforce the cleanup component */
  embargo: boolean = false;
  destinationVisible: boolean = false;
  eventType: AddUpdatePlanEventType = AddUpdatePlanEventType.ServiceCenter;
  addUpdateForm: FormGroup;
  filteredMoveTo$: Observable<LocationSic[]>;
  filteredCloseTo$: Observable<LocationSic[]>;
  locationSic: LocationSic[];
  embargoLocations: LocationSic[];
  createPlanDisabled$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private doorPlanService: DoorPlanService,
    private navbarFiltersService: NavbarFiltersService,
    private cacheService: AppCacheService,
    private snackBar: XpoSnackBar,
    private dialog: MatDialog
  ) {
    this.formInit();
  }

  ngOnInit(): void {
    this.initWatchers();
    this.loadLocations();

    // Not using `addUpdateForm.invalid` since the autocomplete does not appropriately update that value when
    // the form is clicked.
    this.createPlanDisabled$ = this.addUpdateForm.statusChanges.pipe(
      startWith(null),
      map((status) => status !== 'VALID'),
      shareReplay()
    );
  }

  displayFnAutocomplete(activeSic: ActiveSic): string {
    return activeSic?.sicCd;
  }

  onTabGroupClick(event: PointerEvent): void {
    event.stopPropagation();
    // Closing autocomplete panels if we switch tabs
    this.autoCloseTo?.closePanel();
    this.autoMoveTo?.closePanel();
  }

  createPlan(): void {
    const locations = this.addUpdateForm.get(AddUpdatePlanFormNames.Destination).value || null;
    const moveTo = this.addUpdateForm.get(AddUpdatePlanFormNames.MoveTo).value || null;
    const closeTo = this.addUpdateForm.get(AddUpdatePlanFormNames.CloseTo).value || null;
    const facSectorNbr = this.addUpdateForm.get(AddUpdatePlanFormNames.FacSectorNbr).value || null;
    const addUpdatePlanEvent: AddUpdatePlanEvent = {
      eventType: this.eventType,
      locations,
      moveTo,
      closeTo,
      facSectorNbr,
    };
    this.itemSelected.emit(addUpdatePlanEvent);
  }

  onSelectDestination(selectedSics: LocationSic[]): void {
    this.addUpdateForm.get(AddUpdatePlanFormNames.Destination).setValue(selectedSics);
  }

  onSelectedTabChange(param): void {
    this.destinationVisible = param.index === 1;
    this.eventType = this.destinationVisible
      ? AddUpdatePlanEventType.Destination
      : AddUpdatePlanEventType.ServiceCenter;
    this.addUpdateForm.reset();
  }

  customFilterAutocompleteChips(sic: LocationSic, criteria: string): boolean {
    let itemValue = sic.sicCd;
    itemValue += ` - ${sic.city || ''}`;
    itemValue += `, ${sic.state || ''}`;

    return itemValue.toLowerCase().includes(criteria);
  }

  trackByActiveSic(index: number, activeSic: ActiveSic): string {
    return activeSic?.sicCd;
  }

  private formInit(): void {
    this.addUpdateForm = this.fb.group(
      {
        [AddUpdatePlanFormNames.Destination]: this.fb.control(''),
        [AddUpdatePlanFormNames.MoveTo]: this.fb.control(''),
        [AddUpdatePlanFormNames.CloseTo]: this.fb.control(''),
        [AddUpdatePlanFormNames.FacSectorNbr]: this.fb.control(''),
        [AddUpdatePlanFormNames.CheckedCloseTo]: this.fb.control(false),
      },
      {
        validators: AddUpdatePlanFormValidator,
      }
    );
  }

  private loadLocations(): void {
    const cachedActiveSics: ListLocationsResp = this.cacheService.getCacheValue(AppCacheKeys.ACTIVE_SICS);
    if (cachedActiveSics) {
      if (this.embargo && this.embargoSics) {
        this.locationSic = this.setEmbargoLocations();
      } else {
        this.locationSic = cachedActiveSics.locationSic;
      }
      this.setFilteredCloseTo(this.locationSic);
    }
  }

  private setEmbargoLocations(): LocationSic[] {
    return this.cacheService
      .getCacheValue(AppCacheKeys.ACTIVE_SICS)
      .locationSic.filter((allSics) =>
        this.embargoSics.map((sics) => sics.destinationSicCd).some((sic) => sic === allSics.sicCd)
      );
  }

  private initWatchers(): void {
    this.addUpdateForm
      .get(AddUpdatePlanFormNames.CloseTo)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((closeToSic) => {
        this.onCloseToChanged(this.addUpdateForm.get(AddUpdatePlanFormNames.MoveTo).value, closeToSic);
      });
  }

  private onCloseToChanged(moveTo: ActiveSic, closeTo: ActiveSic): void {
    if (moveTo?.sicCd && closeTo?.sicCd) {
      this.resetCloseToDestination();

      this.doorPlanService
        .getDestinationsByLoadLane(
          this.navbarFiltersService.selectedSic,
          this.navbarFiltersService.selectedShift,
          moveTo.sicCd,
          closeTo.sicCd
        )
        .pipe(take(1))
        .subscribe(
          (response: GetDestinationsByLoadLaneResp) => {
            if (response.loadLanes?.length > 1) {
              this.showFFPathOptions(response, moveTo.sicCd, closeTo.sicCd);
            } else {
              this.addUpdateForm.get(AddUpdatePlanFormNames.CheckedCloseTo).setValue(true);
            }
          },
          () => {
            this.snackBar.error('Freight Flow Path not found');
          }
        );
    }
  }

  private showFFPathOptions(destinations: GetDestinationsByLoadLaneResp, moveTo: string, closeTo: string): void {
    this.dialog
      .open(MultipleFreightFlowPathDialogComponent, {
        disableClose: true,
        data: {
          loadLanes: destinations.loadLanes,
          moveToSicCd: moveTo,
          closeToSicCd: closeTo,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        (response: GetCloseToDestinationsResponse) => {
          if (response.actionCancelled) {
            this.contextMenuRef.closeMenu();
          } else {
            // set selected destinations
            if (response.destinations) {
              const locations = response.destinations
                .map((destinationSic) => {
                  return this.locationSic.find((loc) => loc.sicCd === destinationSic);
                })
                .filter((location) => !!location);

              this.onSelectDestination(locations);
              this.addUpdateForm.get(AddUpdatePlanFormNames.FacSectorNbr).setValue(response.facSectorNbr);
            }

            if (this.autoCloseTo?.openPanel) {
              this.autoCloseTo.closePanel();
            }
            this.contextMenuRef.closeMenu();
            this.createPlan();
          }
        },
        () => {
          this.snackBar.error('Freight Flow Path not found');
        }
      );
  }

  private resetCloseToDestination(): void {
    this.addUpdateForm.get(AddUpdatePlanFormNames.Destination).reset();
    this.addUpdateForm.get(AddUpdatePlanFormNames.CheckedCloseTo).reset();
  }

  private setFilteredMoveTo(moveToSicCodes: LocationSic[]) {
    this.filteredMoveTo$ = ActiveSicFormHelper.getFilteredActiveSics(
      this.addUpdateForm.get(AddUpdatePlanFormNames.MoveTo),
      moveToSicCodes
    );
  }

  private setFilteredCloseTo(closeToSicCodes: LocationSic[]) {
    this.filteredCloseTo$ = ActiveSicFormHelper.getFilteredActiveSics(
      this.addUpdateForm.get(AddUpdatePlanFormNames.CloseTo),
      closeToSicCodes
    );
  }
}
