import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { DoorPlanItem } from '@shared/models/door-plan';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanBulkSelection {
  private readonly _selectedDoors = new BehaviorSubject<DoorPlanItem[]>([]);
  selectedDoors$ = this._selectedDoors.asObservable();

  private readonly _resetSelectedDoors = new Subject();
  resetSelectedDoors$ = this._resetSelectedDoors.asObservable();

  toggleDoor(door: DoorPlanItem): void {
    if (door.selected) {
      this.removeDoor(door);
    } else {
      this.addDoor(door);
    }
  }

  resetSelectedDoors(): void {
    this._resetSelectedDoors.next();
    this.setSelectedDoors([]);
  }

  private addDoor(door: DoorPlanItem): void {
    const selectedDoors = [...this._selectedDoors.value];
    door.selected = true;
    selectedDoors.push(door);
    this.setSelectedDoors(selectedDoors);
  }

  private removeDoor(door: DoorPlanItem): void {
    const selectedDoors = this._selectedDoors.value.filter((selecteDoor) => door.doorNbr !== selecteDoor.doorNbr);
    door.selected = false;
    this.setSelectedDoors(selectedDoors);
  }

  private setSelectedDoors(doors: DoorPlanItem[]): void {
    this._selectedDoors.next(doors);
  }
}
