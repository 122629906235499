import { DateHelper } from '@app/shared/classes/date-helper';
import { DoorPlanProfileTypeCd, ShiftCd, WeekDayCd } from '@xpo-ltl/sdk-common';
import { ScoDoorPlan, ScoDoorPlanProfile } from '@xpo-ltl/sdk-dockoperations';
import { Moment } from 'moment';

import { ShiftHelper } from '../../classes/shift-helper';
import { WeekDayHelper } from '../../classes/week-day-helper';
import { Shift } from '../../enums/shifts';

export class DoorPlanProfile {
  doorPlanProfileId: number;
  name: string;
  profileName: string;
  profileTypeCd: DoorPlanProfileTypeCd;
  shiftCd: ShiftCd;
  sicCd: string;
  dayOfWeekCd: WeekDayCd;
  isActive: boolean;
  scoDoorPlan: ScoDoorPlan[];
  profileDate: Moment; // Can be undefined for some profiles

  readonly profileDateRegex = /^\d{2}\/\d{2}\/\d{2}$/;

  constructor(data?: ScoDoorPlanProfile) {
    if (data) {
      this.doorPlanProfileId = data.doorPlanProfileId;
      this.profileName = data.profileName;
      this.profileTypeCd = data.profileTypeCd;
      this.shiftCd = ShiftHelper.getValue(data.shiftCd as Shift);
      this.sicCd = data.sicCode;
      this.dayOfWeekCd = WeekDayHelper.getEnum(data.dayOfWeekCd);
      this.scoDoorPlan = data.scoDoorPlan || [];
      this.isActive = this.profileTypeCd === DoorPlanProfileTypeCd.CURRENT;
      if (this.dayOfWeekCd) {
        this.name = this.dayOfWeekCd;
      } else if (this.isStaticDoorPlanProfile()) {
        this.name = 'Static';
      } else {
        this.name = this.profileName;
      }
      this.profileDate = this.getProfileDate(data.profileName);
    }
  }

  static createStaticDoorPlanProfile(sicCd: string, shiftCd: ShiftCd): DoorPlanProfile {
    const staticProfile = new DoorPlanProfile();
    staticProfile.name = 'Static';
    staticProfile.profileName = 'STATIC';
    staticProfile.profileTypeCd = DoorPlanProfileTypeCd.STATIC;
    staticProfile.sicCd = sicCd;
    staticProfile.shiftCd = shiftCd;
    staticProfile.scoDoorPlan = [];
    return staticProfile;
  }

  getProfileDate(profileName: string): Moment {
    if (profileName?.length > 0) {
      const trimmedName = profileName.substring(4);
      if (trimmedName?.match(this.profileDateRegex)) {
        return DateHelper.getFormatDateFromSting(trimmedName);
      }
    }
  }

  isStaticDoorPlanProfile(): boolean {
    return this.profileTypeCd === DoorPlanProfileTypeCd.STATIC;
  }

  canBeActivatedByUserAction(shiftCd: ShiftCd, doorPlanProfiles: DoorPlanProfile[]): boolean {
    if (this.isActive) {
      return false;
    }

    return this.canBeActivated(shiftCd, doorPlanProfiles);
  }

  canBeActivated(shiftCd: ShiftCd, doorPlanProfiles: DoorPlanProfile[]): boolean {
    const currentIndex = WeekDayHelper.getShiftDate(new Date(), shiftCd).getDay();
    const weekDayIndex = WeekDayHelper.getWeekDayIndex(this.dayOfWeekCd);

    if (shiftCd === ShiftCd.INBOUND) {
      return this.canBeInboundActivated(currentIndex, weekDayIndex, doorPlanProfiles);
    } else {
      return weekDayIndex === currentIndex;
    }
  }

  private canBeInboundActivated(
    currentIndex: number,
    weekDayIndex: number,
    doorPlanProfiles: DoorPlanProfile[]
  ): boolean {
    if (weekDayIndex === currentIndex) {
      return true; // same day.
    } else if (weekDayIndex === currentIndex + 1) {
      return true; // Next day.
    } else {
      const currentWeekDay = WeekDayHelper.getWeekDayByIndex(currentIndex);

      if (currentWeekDay === WeekDayCd.FRIDAY) {
        if (
          this.dayOfWeekCd === WeekDayCd.SUNDAY &&
          !doorPlanProfiles.some((profile) => profile.dayOfWeekCd === WeekDayCd.SATURDAY)
        ) {
          return true; // Today is friday, Saturday profile does not exist and current profile is sunday.
        } else if (
          this.dayOfWeekCd === WeekDayCd.MONDAY &&
          doorPlanProfiles.filter(
            (profile) => profile.dayOfWeekCd === WeekDayCd.SATURDAY || profile.dayOfWeekCd === WeekDayCd.SUNDAY
          ).length === 0
        ) {
          return true; // Today is friday, Sunday & Saturday profiles don't exist and current profile is monday.
        }
      } else if (currentWeekDay === WeekDayCd.SATURDAY) {
        if (this.dayOfWeekCd === WeekDayCd.SUNDAY) {
          return true; // Today is Saturday, current profile is Sunday
        } else if (
          this.dayOfWeekCd === WeekDayCd.MONDAY &&
          !doorPlanProfiles.some((profile) => profile.dayOfWeekCd === WeekDayCd.SUNDAY)
        ) {
          return true; // Today is Saturday, Sunday profile does not exist and current profile is monday.
        }
      }
    }

    return false;
  }
}
