export enum FilterFormNames {
  SectorOrDoorRadio = 'SectorOrDoorRadio',
  OneDoorNumber = 'OneDoorNumber',
  SectorNumber = 'SectorNumber',
  DoorNumberInitial = 'DoorNumberInitial',
  DoorNumberFinal = 'DoorNumberFinal',
  TypeXpo = 'TypeXpo',
  TypeExc = 'TypeExc',
  TypeSupp = 'TypeSupp',
  PriorityOne = 'PriorityOne',
  PriorityTwo = 'PriorityTwo',
  MoveTo = 'MoveTo',
  CloseTo = 'CloseTo',
  Destinations = 'Destinations',
  LoadLevel1Fac = 'LoadLevel1Fac',
  LoadLevel2Fac = 'LoadLevel2Fac',
  LoadLevel3Fac = 'LoadLevel3Fac',
  LoadLevel4Fac = 'LoadLevel4Fac',
  LoadLevelHold = 'LoadLevelHold',
  LoadLevelFinalDest = 'LoadLevelFinalDest',
}
