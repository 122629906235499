import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadLeg } from '@xpo-ltl-2.0/sdk-freightflow';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'freight-flow-move-leg',
  templateUrl: './freight-flow-move-leg.component.html',
  styleUrls: ['./freight-flow-move-leg.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FreightFlowPathMoveLegComponent implements OnInit {
  @Input() moveLeg: LoadLeg;
  @Input() moveIndex: number;
  @Input() loadIndex: number;
  @Input() previousLoad: LoadLeg;
  @Input() previousMove: LoadLeg;

  private _showSicSubject = new BehaviorSubject<boolean>(false);
  showSic$ = this._showSicSubject.asObservable();

  ngOnInit() {
    this._showSicSubject.next(this.showMoveLegLeftContent());
  }

  showMoveLegLeftContent(): boolean {
    return (
      !(this.moveIndex > 0 && this.previousMove.originSicCd === this.moveLeg.originSicCd) &&
      !(
        this.moveIndex === 0 &&
        this.loadIndex > 0 &&
        ((this.previousLoad.moveLegs.length < 2 && this.previousLoad.originSicCd === this.moveLeg.originSicCd) ||
          (this.previousLoad.moveLegs.length > 1 &&
            this.previousLoad.moveLegs[this.previousLoad.moveLegs.length - 1].originSicCd === this.moveLeg.originSicCd))
      )
    );
  }
}
