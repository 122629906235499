export enum StaticPlanRecommendationTypes {
  FIX_LANE = 'FIX_LANE',
  DELETE_DESTINATIONS = 'DELETE_DESTINATIONS',
  DELETE_DOOR = 'DELETE_DOOR',
  DELETE_P2_DOOR = 'DELETE_P2_DOOR',
  // Required fixes below
  INVALID_LANE = 'INVALID_LANE',
  MISSING_DESTINATIONS = 'MISSING_DESTINATIONS',
  // Recommendations below
  DEEP_LOADING = 'DEEP_LOADING',
  HOLD_TO_DOOR = 'HOLD_TO_DOOR',
  MERGE_LANE = 'MERGE_LANE',
}
