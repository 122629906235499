import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NotificationComponent } from '@app/core/app-notification/app-notification.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [NotificationComponent],
  imports: [SharedModule, MatProgressSpinnerModule],
  exports: [NotificationComponent],
  entryComponents: [],
})
export class CoreModule {}
