import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { get as _get } from 'lodash';

import { DoorPlanDestination } from '@shared/models/door-plan';

interface DestinationSortOption {
  label: string;
  propertyName: string;
  asc: boolean;
}

@Component({
  selector: 'app-destination-sort',
  templateUrl: './destination-sort.component.html',
  styleUrls: ['./destination-sort.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'destination-sort',
  },
})
export class DestinationSortComponent implements OnInit {
  @Input()
  destinations: DoorPlanDestination[];

  @Output()
  destinationSorted = new EventEmitter<DoorPlanDestination[]>();

  sortOptions: DestinationSortOption[];

  private _selectedSortOption: DestinationSortOption;
  get selectedSortOption(): DestinationSortOption {
    return this._selectedSortOption;
  }
  set selectedSortOption(value: DestinationSortOption) {
    this._selectedSortOption = value;
    this.sortDestinations(value);
  }

  constructor() {}

  ngOnInit() {
    this.initializeOptions();
  }

  private initializeOptions(): void {
    this.sortOptions = [
      {
        label: 'Rem. Cube high to low',
        propertyName: 'remainingCubeLane',
        asc: false,
      },
      {
        label: 'Rem. Cube low to high',
        propertyName: 'remainingCubeLane',
        asc: true,
      },
      {
        label: 'Total Cube high to low',
        propertyName: 'totalCubeLane',
        asc: false,
      },
      {
        label: 'Total Cube low to high',
        propertyName: 'totalCubeLane',
        asc: true,
      },
      {
        label: 'Rem. Weight high to low',
        propertyName: 'remainingWeightLane',
        asc: false,
      },
      {
        label: 'Rem. Weight low to high',
        propertyName: 'remainingWeightLane',
        asc: true,
      },
      {
        label: 'Total Weight high to low',
        propertyName: 'totalWeightLane',
        asc: false,
      },
      {
        label: 'Total Weight low to high',
        propertyName: 'totalWeightLane',
        asc: true,
      },
    ];
  }

  private sortDestinations(selectedOption: DestinationSortOption): void {
    if (!selectedOption) {
      return;
    }
    const sortedDestinations = this.destinations.sort((a, b) => {
      const aProperty = _get(a, `loadLaneInfo.${selectedOption.propertyName}`, -1);
      const bProperty = _get(b, `loadLaneInfo.${selectedOption.propertyName}`, -1);

      if (selectedOption.asc) {
        return aProperty - bProperty;
      }

      return bProperty - aProperty;
    });
    this.destinationSorted.emit(sortedDestinations);
  }

  trackByOption(index: number, option: DestinationSortOption): string {
    return option?.propertyName;
  }
}
