import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generate-profile',
  templateUrl: './generate-profile.component.html',
  styleUrls: ['./generate-profile.component.scss'],
})
export class GenerateProfileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GenerateProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  activateProfile() {
    this.dialogRef.close(true);
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
