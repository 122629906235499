import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { XpoContextMenuComponent } from '@app/context-menu/public_api';
import { LoadLaneSummaryActionsEnum } from '@shared/enums/load-lane/load-lane-summary-actions.enum';
import { MenuOptions } from '@shared/interfaces/menu-options.interface';
import { DoorPlanItem, GTAction } from '@shared/models/door-plan';

@Component({
  selector: 'app-load-lane-context-menu',
  templateUrl: './load-lane-context-menu.component.html',
  styleUrls: ['./load-lane-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadLaneContextMenuComponent {
  @Input()
  doorPlanItems: DoorPlanItem[];
  @Output()
  itemSelected = new EventEmitter<GTAction>();

  menuOptions: MenuOptions = {};

  @ViewChild(XpoContextMenuComponent, { static: true }) contextMenu: XpoContextMenuComponent<DoorPlanItem>;
  @ViewChild('contextMenuAddUpdatePlanTpl', { static: true }) contextMenuAddUpdatePlanTpl: TemplateRef<any>;

  openMenu(event: MouseEvent, doorPlanItem: DoorPlanItem) {
    this.menuOptions = {
      template: this.contextMenuAddUpdatePlanTpl,
    };
    this.contextMenu.openMenu(event, doorPlanItem);
  }

  onItemClick(doorPlan: DoorPlanItem, emptyDoor: DoorPlanItem): void {
    const action = doorPlan.doorNbr
      ? LoadLaneSummaryActionsEnum.PERFORM_UPDATE
      : LoadLaneSummaryActionsEnum.PERFORM_ADD;

    const gtAction: GTAction = {
      action,
      data: {
        firstRow: doorPlan,
        secondRow: emptyDoor,
      },
    };
    this.itemSelected.emit(gtAction);
  }
}
