import { MoveModeCd } from '@xpo-ltl/sdk-common';
import { LaneTypeApp } from '../enums/lane-type.enum';

export class LaneTypeHelper {
  static getLaneApp(laneTypeCd: MoveModeCd) {
    switch (laneTypeCd) {
      case MoveModeCd.LINEHAUL:
        return LaneTypeApp.XPO;
      case MoveModeCd.EXCLUSIVE:
        return LaneTypeApp.EXC;
      case MoveModeCd.SUPPLEMENTAL:
        return LaneTypeApp.SUP;
    }
  }

  static getLaneAppNoXPO(laneTypeCd: MoveModeCd) {
    switch (laneTypeCd) {
      case MoveModeCd.EXCLUSIVE:
        return LaneTypeApp.EXC;
      case MoveModeCd.SUPPLEMENTAL:
        return LaneTypeApp.SUP;
    }
  }
}
