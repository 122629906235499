import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AddUpdatePlanFormNames } from '@app/door-plan-common/enums';

export const AddUpdatePlanFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }
  const destinationControl = control.get(AddUpdatePlanFormNames.Destination);
  const moveToControl = control.get(AddUpdatePlanFormNames.MoveTo);
  const closeToControl = control.get(AddUpdatePlanFormNames.CloseTo);
  const checkedCloseTo = control.get(AddUpdatePlanFormNames.CheckedCloseTo);

  if (destinationControl?.value?.length) {
    return null;
  }
  if (moveToControl?.value?.sicCd && closeToControl?.value?.sicCd && checkedCloseTo?.value) {
    return null;
  }
  return { addUpdateError: true };
};
