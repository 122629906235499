import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import _ from 'lodash';
import { take, takeUntil } from 'rxjs/operators';

import { ActiveProfileDialogComponent } from '@app/dialogs/active-profile/active-profile.component';
import { GenerateProfileDialogComponent } from '@app/dialogs/generate-profile/generate-profile.component';
import { AppCacheKeys } from '@app/shared/enums/app-cache-keys.enum';
import { ErrorCode } from '@app/shared/enums/error-codes.enum';
import { ActiveProfileModalData } from '@app/shared/interfaces';
import { GenerateProfileModalData } from '@app/shared/interfaces/generate-profile-dialog-data.interface';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { LocationsService } from '@app/shared/services/locations-services/locations.service';
import { CurrentDoorPlanProfileService } from '@services/current-door-plan-profile/current-door-plan-profile.service';
import { DoorPlanProfileService } from '@services/door-plan-profile/door-plan-profile.service';
import { WeekDayHelper } from '@shared/classes/week-day-helper';
import { DoorPlanProfile } from '@shared/models/door-plan';
import { DoorPlanDataSourceService } from '@shared/services/door-plan-data-source/door-plan-data-source.service';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import moment from 'moment';

@Component({
  selector: 'app-door-plan-profile-filter',
  templateUrl: './door-plan-profile-filter.component.html',
  styleUrls: ['./door-plan-profile-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-profile-filter',
  },
})
export class DoorPlanProfileFilterComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private doorPlanProfileService: DoorPlanProfileService,
    private currentDoorPlanProfileService: CurrentDoorPlanProfileService,
    private dialog: MatDialog,
    public doorPlanDataSource: DoorPlanDataSourceService,
    private localStorageService: LocalStorageService,
    private locationsService: LocationsService,
    private snackBar: XpoSnackBar
  ) {}
  @Input()
  sicCd: string;
  @Input()
  shiftCd: ShiftCd;
  @Input()
  set requirementsResolvedInput(currentStatus: boolean) {
    this.requirementsResolved = currentStatus;
    this.checkActivationFromStatic();
  }
  requirementsResolved: boolean;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  planOptions: DoorPlanProfile[] = [];
  planControl = new FormControl(null);

  canActivateFromStatic: boolean = false;
  isCurrentProfileStatic: boolean = false;
  dialogRef: MatDialogRef<ActiveProfileDialogComponent>;
  ngOnInit(): void {
    this.doorProfilesOptionsInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.sicCd || changes.shiftCd) && this.sicCd && this.shiftCd) {
      this.planControl.enable();
      this.doorProfilesDataInit();
    } else if (!changes.requirementsResolvedInput) {
      this.planControl.disable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private redirectToStaticProfile() {
    const staticProfile = this.planOptions.find((plan) => plan.isStaticDoorPlanProfile());
    if (staticProfile) {
      this.repairStaticDoorPlanProfile(staticProfile);
      this.snackBar.open({
        message: 'You are now editing the Static plan. Complete any changes before activating the door plan.',
        status: 'info',
        matConfig: {
          duration: 10000,
        },
      });
    }
  }

  private checkActivationFromStatic() {
    if (!!this.currentDoorPlanProfileService.getCurrentDoorPlanProfile()) {
      const isInboundActivated =
        this.shiftCd === ShiftCd.INBOUND && this.currentDoorPlanProfileService.isTomorrowsProfileActive();
      const isActivated = this.currentDoorPlanProfileService.isTodaysProfileActive();
      this.canActivateFromStatic = this.requirementsResolved && !isActivated && !isInboundActivated;
    }
  }

  onOptionSelected(optionSelected: DoorPlanProfile): void {
    if (!optionSelected) {
      return;
    }
    if (optionSelected.isStaticDoorPlanProfile()) {
      this.repairStaticDoorPlanProfile(optionSelected);
    } else {
      this.setCurrentProfile(optionSelected);
      if (optionSelected.isActive) {
        return;
      }
      if (this.shiftCd === ShiftCd.INBOUND) {
        this.checkActivationforInboundProfile(optionSelected);
      } else {
        this.checkGenericProfileActivation(optionSelected);
      }
    }
  }

  private repairStaticDoorPlanProfile(optionSelected: DoorPlanProfile) {
    // Make the call only when current plan has not yet been activated
    // and when user loads (or is forced to load) static plan.
    const staticProfileId = optionSelected.doorPlanProfileId;
    if (!this.isStaticPlanRepaired(staticProfileId) && !this.currentDoorPlanProfileService.isTodaysProfileActive()) {
      this.doorPlanProfileService.repairDoorPlanProfile(staticProfileId).subscribe((response) => {
        this.localStorageService.setLocalStorage(`${AppCacheKeys.DOOR_PLAN_REPAIR}-${staticProfileId}`, {
          value: true,
          expiration: moment().endOf('day'),
        });
        this.setCurrentProfile(optionSelected);
      });
    } else {
      this.setCurrentProfile(optionSelected);
    }
  }

  private isStaticPlanRepaired(staticProfileId: number): boolean {
    return this.localStorageService.getValue(`${AppCacheKeys.DOOR_PLAN_REPAIR}-${staticProfileId}`);
  }

  private checkActivationforInboundProfile(optionSelected: DoorPlanProfile) {
    const currentDate = WeekDayHelper.getShiftDate(new Date(), optionSelected.shiftCd);
    const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
    const todaysActiveProfile = this.planOptions.find(
      (profile) => profile.isActive && profile.dayOfWeekCd === currentDay
    );
    const tomorrowActiveProfile = this.planOptions.find(
      (profile) =>
        profile.isActive &&
        WeekDayHelper.getWeekDayIndex(profile.dayOfWeekCd) === WeekDayHelper.getWeekDayIndex(currentDay) + 1
    );
    if (!todaysActiveProfile && !tomorrowActiveProfile && currentDay === optionSelected.dayOfWeekCd) {
      this.redirectToStaticProfile();
      this.locationsService.isPilot$.pipe(take(1)).subscribe((isPilot) => {
        if (!isPilot) {
          this.generatedProfileDialog({ isConfirmModal: true, profile: optionSelected });
        }
      });
    } else if (
      !tomorrowActiveProfile &&
      WeekDayHelper.getWeekDayIndex(optionSelected.dayOfWeekCd) === WeekDayHelper.getWeekDayIndex(currentDay) + 1
    ) {
      // if not tomorrows profile is active and we selected it, prompt for activation
      this.generatedProfileDialog({ isConfirmModal: true, profile: optionSelected });
    } else if (
      WeekDayHelper.getWeekDayIndex(optionSelected.dayOfWeekCd) >
      WeekDayHelper.getWeekDayIndex(currentDay) + 1
    ) {
      this.generatedProfileDialog({ isConfirmModal: false, profile: optionSelected });
    }
  }

  private checkGenericProfileActivation(optionSelected: DoorPlanProfile) {
    const currentDate = WeekDayHelper.getShiftDate(new Date(), optionSelected.shiftCd);
    const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
    const isTodayProfileActive = this.planOptions.find(
      (profile) => profile.isActive && profile.dayOfWeekCd === currentDay
    );

    if (!isTodayProfileActive && currentDay === optionSelected.dayOfWeekCd) {
      this.redirectToStaticProfile();
      this.locationsService.isPilot$.pipe(take(1)).subscribe((isPilot) => {
        if (!isPilot) {
          this.generatedProfileDialog({ isConfirmModal: true, profile: optionSelected });
        }
      });
    } else if (WeekDayHelper.getWeekDayIndex(optionSelected.dayOfWeekCd) > WeekDayHelper.getWeekDayIndex(currentDay)) {
      this.generatedProfileDialog({ isConfirmModal: false, profile: optionSelected });
    }
  }

  isDoorPlanProfileActivable(): boolean {
    return this.currentDoorPlanProfileService.canBeActivated();
  }

  onActivateDoorPlanProfile(): void {
    if (this.currentDoorPlanProfileService.getCurrentDoorPlanProfile().isStaticDoorPlanProfile()) {
      const currentDate = WeekDayHelper.getShiftDate(
        new Date(),
        this.currentDoorPlanProfileService.getCurrentDoorPlanProfile().shiftCd
      );
      const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
      let profileToActivate = this.planOptions.find((profile) => profile.dayOfWeekCd === currentDay);
      if (!profileToActivate) {
        profileToActivate = new DoorPlanProfile();
        profileToActivate.dayOfWeekCd = currentDay;
        profileToActivate.sicCd = this.sicCd;
        profileToActivate.shiftCd = this.shiftCd;
      }
      this.currentDoorPlanProfileService.activateDoorPlanProfile(profileToActivate);
      this.setCurrentProfile(profileToActivate);
    } else {
      this.currentDoorPlanProfileService.activateDoorPlanProfile();
    }
  }

  private doorProfilesOptionsInit(): void {
    this.doorPlanProfileService.doorPlanProfiles$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((doorPlanProfileOptions) => {
        this.planOptions = doorPlanProfileOptions;
        const currentActivePlan = doorPlanProfileOptions?.find((profile) => profile.isActive);
        if (!currentActivePlan) {
          this.redirectToStaticProfile();
          this.checkCurrentActiveProfile();
        } else {
          const currentDate = WeekDayHelper.getShiftDate(new Date(), this.shiftCd);
          const momentShiftDate = moment(currentDate);
          const isActivated = currentActivePlan.profileDate?.isSame(momentShiftDate, 'day');
          const isInboundActivated =
            this.shiftCd === ShiftCd.INBOUND &&
            currentActivePlan.profileDate?.isAfter(momentShiftDate, 'day') &&
            moment.duration(currentActivePlan.profileDate?.diff(momentShiftDate.startOf('day'))).asDays() === 1;
          if (isActivated || isInboundActivated) {
            this.setCurrentProfile(currentActivePlan);
          } else {
            this.redirectToStaticProfile();
            this.checkCurrentActiveProfile();
          }
        }
      });
  }

  private checkCurrentActiveProfile(): void {
    this.locationsService.isPilot$.pipe(take(1)).subscribe((isPilot) => {
      if (!isPilot) {
        const currentDate = WeekDayHelper.getShiftDate(new Date(), this.shiftCd);
        const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
        const profileToActivate = this.planOptions.find((profile) => profile.dayOfWeekCd === currentDay);

        if (profileToActivate) {
          const data: ActiveProfileModalData = {
            isConfirmModal: true,
            currentDay,
          };
          this.openProfileActivationDialog(data, profileToActivate);
        }
      }
    });
  }

  private openProfileActivationDialog(
    data: ActiveProfileModalData,
    profileToActivate: DoorPlanProfile,
    showRemovedPlansAfterActivation: boolean = true
  ): void {
    const config = {
      width: '600px',
      disableClose: true,
      autoFocus: false,
      data,
    };
    this.dialog
      .open(ActiveProfileDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.currentDoorPlanProfileService.activateDoorPlanProfile(
            profileToActivate,
            showRemovedPlansAfterActivation
          );
        }
      });
  }

  private doorProfilesDataInit(): void {
    this.cleanOptions();
    this.doorPlanProfileService.listDoorPlanProfiles(this.sicCd, this.shiftCd).subscribe(
      (doorPlanProfiles) => {
        const existStaticProfile = doorPlanProfiles.some((profile) => profile.isStaticDoorPlanProfile());
        if (!existStaticProfile) {
          this.createStaticDoorPlanProfile();
        }
        this.doorPlanProfileService.setDoorPlanProfiles(doorPlanProfiles || []);
      },
      (error) => {
        if (error.code === ErrorCode.NotFound) {
          this.createStaticDoorPlanProfile();
        } else {
          this.doorPlanDataSource.resetDoorPlans();
        }
      }
    );
  }

  private cleanOptions(): void {
    this.doorPlanProfileService.setDoorPlanProfiles([]);
  }

  private setCurrentProfile(newPlan: DoorPlanProfile): void {
    if (!!newPlan && this.currentDoorPlanProfileService.getCurrentDoorPlanProfile() !== newPlan) {
      this.currentDoorPlanProfileService.setCurrentDoorPlanProfile(newPlan);
      this.planControl.setValue(newPlan);
      this.isCurrentProfileStatic = newPlan.isStaticDoorPlanProfile();
      this.checkActivationFromStatic();
    }
  }

  private createStaticDoorPlanProfile(): void {
    const staticDoorPlanProfile = DoorPlanProfile.createStaticDoorPlanProfile(this.sicCd, this.shiftCd);
    this.doorPlanProfileService.createDoorPlanProfile(staticDoorPlanProfile).subscribe((newStaticProfile) => {
      this.doorPlanProfileService.addDoorPlanProfile(newStaticProfile);
      this.redirectToStaticProfile();
    });
  }

  private generatedProfileDialog(data: GenerateProfileModalData): void {
    const config = {
      width: '600px',
      disableClose: true,
      autoFocus: false,
      data,
    };
    this.dialog
      .open(GenerateProfileDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.currentDoorPlanProfileService.activateDoorPlanProfile(data.profile);
        }
      });
  }

  trackByDoorPlanProfile(index: number, profile: DoorPlanProfile): number {
    return profile?.doorPlanProfileId;
  }
}
