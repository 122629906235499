import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber, XpoLtlServiceCentersService, XpoLtlSicSwitcherLabelOptions } from '@xpo-ltl/ngx-ltl';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

import { AppParams } from '@shared/enums/app-routes.enum';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service/filters-service.service';
import { QueryParamsService } from '@shared/services/query-params/query-params.service';
import { UserRoleService } from '@shared/services/user-role/user-role.service';

@Component({
  selector: 'app-contextual-header',
  templateUrl: './contextual-header.component.html',
  styleUrls: ['./contextual-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-ContextualHeader' },
})
export class AppContextualHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  pageTitle: string;
  @Input()
  showArrowBack = false;

  isUserLoggedIn$: Observable<boolean>;
  sicCd$: Observable<string>;
  shiftCd$: Observable<ShiftCd>;
  sicSwitcherLabel: XpoLtlSicSwitcherLabelOptions;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService,
    private filtersService: NavbarFiltersService,
    private userRoleService: UserRoleService,
    private xpoLtlServiceCentersService: XpoLtlServiceCentersService
  ) {}

  ngOnInit(): void {
    this.shiftCd$ = this.filtersService.selectedShift$.pipe(distinctUntilChanged());
    this.queryParamsInit();
    this.isUserLoggedIn$ = this.userRoleService.isUserLoggedIn$;
    this.sicSwitcherLabel = new XpoLtlSicSwitcherLabelOptions({
      title: 'Location',
    });
  }

  ngAfterViewInit(): void {
    // this needs to be here because sic switcher does not recognize from ngOnInit
    this.sicCd$ = this.filtersService.selectedSic$.pipe(distinctUntilChanged());
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  handleSicChanged(newSic: string): void {
    const queryParams = {
      [AppParams.SIC_CD]: newSic,
    };

    this.queryParamsService.handleQueryParamsChanged(queryParams);
  }

  handleShiftChanged(newShift: ShiftCd): void {
    const queryParams = {
      [AppParams.SHIFT_CD]: newShift,
    };

    this.queryParamsService.handleQueryParamsChanged(queryParams);
  }

  private queryParamsInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscriber.done$)).subscribe((queryParams) => {
      const sicCdParam: string = queryParams[AppParams.SIC_CD];
      const shiftCdParam: ShiftCd = queryParams[AppParams.SHIFT_CD];

      if (sicCdParam) {
        this.filtersService.setSelectedSic(sicCdParam);
      } else {
        this.getDefaultUserSic();
      }

      if (shiftCdParam) {
        this.filtersService.setSelectedShift(shiftCdParam);
      }
    });
  }

  private getDefaultUserSic(): void {
    const defaultSic = this.userRoleService.user && this.userRoleService.user.sicCode;

    if (defaultSic) {
      this.xpoLtlServiceCentersService
        .getSicByCd$(defaultSic)
        .pipe(
          take(1),
          filter((sicResponse) => !!sicResponse)
        )
        .subscribe(() => this.handleSicChanged(defaultSic));
    }
  }
}
