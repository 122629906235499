import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AssignTrailerFormNames } from '@app/door-plan-common/enums';
import { AssingTrailerEvent } from '@app/door-plan-common/interfaces/assign-trailer-event.interface';
import { DoorPlanActionsEnum } from '@shared/enums/door-plan';
import { trailerTypeValidator } from './validator/trailer-type.validator';

export class AssignTrailerFormBuilder {
  static create(fb: FormBuilder) {
    return fb.group({
      [AssignTrailerFormNames.equipment]: fb.control(null, [trailerTypeValidator]),
      [AssignTrailerFormNames.status]: fb.control(null, [Validators.required]),
    });
  }

  static values(formGroup: FormGroup, action: DoorPlanActionsEnum): AssingTrailerEvent {
    return {
      action,
      equipment: formGroup.get(AssignTrailerFormNames.equipment).value,
      status: formGroup.get(AssignTrailerFormNames.status).value,
    };
  }
}
