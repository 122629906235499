import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';

import { XpoEmptyStateModule, XpoMultiSelectModule } from '@xpo-ltl/ngx-ltl-core';

import { LoadLaneContextMenuComponent } from '@app/door-plan-common/components/load-lane-context-menu/load-lane-context-menu.component';
import { SharedModule } from '@shared/shared.module';
import { XpoContextMenuModule } from '../context-menu/public_api';
import { DeepLoadingListComponent } from '../door-plan-common/components/deep-loading-list/deep-loading-list.component';
import { AssignTrailerComponent } from './components/assign-trailer/assign-trailer.component';
import { CardDestinationsComponent } from './components/card-destinations/card-destinations.component';
import { DestinationSortComponent } from './components/destination-sort/destination-sort.component';
import { DestinationsFilterComponent } from './components/destinations-filter/destinations-filter.component';
import { DestinationsListComponent } from './components/destinations-list/destinations-list.component';
import { DoorPlanCardTrailerInfoComponent } from './components/door-plan-card/door-plan-card-trailer-info';
import { DoorPlanCardComponent } from './components/door-plan-card/door-plan-card.component';
import { DoorPlanContextMenuAddUpdatePlanComponent } from './components/door-plan-context-menu-add-update-plan/door-plan-context-menu-add-update-plan.component';
import { DoorPlanContextMenuComponent } from './components/door-plan-context-menu/door-plan-context-menu.component';
import { DoorPlanContextSubmenuComponent } from './components/door-plan-context-submenu/door-plan-context-submenu.component';
import { DoorPlanFiltersMenuPopoverComponent } from './components/door-plan-filters-menu-popover/door-plan-filters-menu-popover.component';
import { DoorPlanItemInlineComponent } from './components/door-plan-item-inline/door-plan-item-inline.component';
import { DoorPlanRoutesComponent } from './components/door-plan-routes/door-plan-routes.component';
import { DoorPlanContextMenuTrailerComponent } from './components/door-plan-trailer-menu/door-plan-trailer-menu.component';
import { LoadLaneCardComponent } from './components/load-lane-card/load-lane-card.component';
import { LoadLaneInfoComponent } from './components/load-lane-info/load-lane-info.component';
import { RecommendationsCardComponent } from './components/recommendations-card/recommendations-card.component';
import { RemovedStaticPlansComponent } from './components/removed-static-plans/removed-static-plans.component';
import { RequirementsContextMenuComponent } from './components/requirements-context-menu/requirements-context-menu.component';
import { StaticDoorRecommendationsComponent } from './components/static-door-recommendations/static-door-recommendations.component';

const components = [
  DoorPlanContextMenuComponent,
  DoorPlanContextSubmenuComponent,
  DoorPlanItemInlineComponent,
  DoorPlanFiltersMenuPopoverComponent,
  DoorPlanCardComponent,
  CardDestinationsComponent,
  DestinationsFilterComponent,
  DoorPlanContextMenuAddUpdatePlanComponent,
  DestinationSortComponent,
  DoorPlanContextMenuTrailerComponent,
  RemovedStaticPlansComponent,
  DoorPlanCardTrailerInfoComponent,
  LoadLaneInfoComponent,
  DestinationsListComponent,
  AssignTrailerComponent,
  DeepLoadingListComponent,
  LoadLaneContextMenuComponent,
  DoorPlanRoutesComponent,
  LoadLaneCardComponent,
  StaticDoorRecommendationsComponent,
  RequirementsContextMenuComponent,
  RecommendationsCardComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [SharedModule, MatPaginatorModule, XpoContextMenuModule, XpoMultiSelectModule, XpoEmptyStateModule],
})
export class DoorPlanCommonModule {}
