import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { fromEvent } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { XpoContextMenuItem, XpoContextMenuResult, XpoContextSubmenuItem } from './models';

@Component({
  selector: 'xpo-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  host: {
    class: 'xpo-ContextMenu',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XpoContextMenuComponent<T> implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  @Input()
  contextHeaderTpl: TemplateRef<any>;
  @Input()
  contextFooterTpl: TemplateRef<any>;
  @Input()
  contextMenuTpl: TemplateRef<any>;

  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  @Input()
  menuItems: XpoContextMenuItem<any>[] = [];

  @Output()
  itemSelected = new EventEmitter<XpoContextMenuResult<T>>();

  constructor() {}

  ngOnInit(): void {
    fromEvent(window, 'click')
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(() => {
        if (this.contextMenu.menuOpen) {
          this.closeMenu();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onMenuItemClick(menuItem: XpoContextMenuItem<T>, contextData: T): void {
    if (menuItem && menuItem.action) {
      menuItem.action(contextData);
    }
    this.onItemClick(menuItem, contextData);
  }

  onSubmenuItemClick(menuItem: XpoContextMenuItem<T>, submenuItem: XpoContextSubmenuItem<T>, contextData: T): void {
    if (submenuItem && submenuItem.action) {
      submenuItem.action(contextData);
    }
    this.onItemClick(menuItem, contextData, submenuItem);
  }

  private onItemClick(menuItem: XpoContextMenuItem<T>, contextData: T, submenuItem?: XpoContextSubmenuItem<T>): void {
    const result = new XpoContextMenuResult<T>();
    result.contextData = contextData;
    result.menuItem = menuItem;
    result.subMenuItem = submenuItem;
    this.itemSelected.emit(result);
  }

  openMenu(event: MouseEvent, contextData: T): void {
    event.preventDefault();
    if (this.contextMenu.menuOpen) {
      this.contextMenu.menuClosed.pipe(take(1)).subscribe(() => {
        this.openContextMenu(event, contextData);
      });
      this.closeMenu();
    } else {
      this.openContextMenu(event, contextData);
    }
  }

  private openContextMenu(event: MouseEvent, contextData: T): void {
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { contextData: contextData };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  closeMenu(): void {
    this.contextMenu.closeMenu();
  }

  trackByMenuItem(index: number, menuItem: XpoContextMenuItem<any>): string {
    return menuItem?.name;
  }
}
