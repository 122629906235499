import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, Inject, Input, NgZone, OnInit, Optional, ViewContainerRef } from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { ListLocationsResp } from '@xpo-ltl-2.0/sdk-location';
import { AppCacheKeys } from '../enums/app-cache-keys.enum';
import { AppCacheService } from '../services/cache-service/cache.service';

@Directive({
  selector: '[appSicDescriptionTooltip]',
})
export class SicDescriptionTooltipDirective extends MatTooltip implements OnInit {
  @Input('appSicDescriptionTooltip')
  sicCd: string;

  activeSics: ListLocationsResp;

  constructor(
    private cacheService: AppCacheService,
    _overlay: Overlay,
    _elementRef: ElementRef,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _platform: Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
    @Optional() _dir: Directionality,
    @Optional()
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    _defaultOptions: MatTooltipDefaultOptions
  ) {
    super(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      _scrollStrategy,
      _dir,
      _defaultOptions
    );
  }

  ngOnInit() {
    this.position = 'above';
    this.activeSics = this.cacheService.getCacheValue(AppCacheKeys.ACTIVE_SICS);
    const activeSic = this.activeSics.locationSic.find((sic) => sic.sicCd === this.sicCd);
    if (activeSic) {
      if (activeSic.city && activeSic.state) {
        this.message = `${activeSic.city}, ${activeSic.state}`;
      } else if (activeSic.sicDescription) {
        this.message = `${activeSic.sicDescription}`;
      }
    }
    if (!this.message) {
      this.message = this.sicCd;
    }
  }
}
