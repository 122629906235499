import { Component, Input, OnInit } from '@angular/core';

import { ScoDoorPlan } from '@xpo-ltl/sdk-dockoperations';

import { DoorPlanItem } from '@app/shared/models/door-plan';
import { CurrentDoorPlanProfileService } from '@app/shared/services/current-door-plan-profile/current-door-plan-profile.service';
import { DoorPlanProfileService } from '@app/shared/services/door-plan-profile/door-plan-profile.service';

@Component({
  selector: 'app-removed-static-plans',
  templateUrl: './removed-static-plans.component.html',
  styleUrls: ['./removed-static-plans.component.scss'],
})
export class RemovedStaticPlansComponent implements OnInit {
  removedStaticPlans: DoorPlanItem[] = [];

  @Input()
  removedPlansArray: ScoDoorPlan[];
  constructor(
    private currentProfileService: CurrentDoorPlanProfileService,
    private doorPlanProfileService: DoorPlanProfileService
  ) {}

  ngOnInit(): void {
    if (!!this.removedPlansArray) {
      this.removedStaticPlans = this.removedPlansArray.map((scoDoorPlan) =>
        DoorPlanItem.createFromScoDoorPlan(scoDoorPlan)
      );
    } else {
      const currentProfileId = this.currentProfileService.getCurrentDoorPlanProfileId();
      const currentStaticProfileId = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile().doorPlanProfileId;

      this.doorPlanProfileService
        .getRemovedStaticDoors(currentProfileId, currentStaticProfileId)
        .subscribe((scoDoorPlans) => {
          scoDoorPlans.forEach((scoDoorPlan) =>
            this.removedStaticPlans.push(DoorPlanItem.createFromScoDoorPlan(scoDoorPlan))
          );
        });
    }
  }

  trackByScoDoorPlan(index: number, doorPlan: ScoDoorPlan): string {
    return doorPlan?.displaySequenceNbr;
  }
}
