import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { ActiveSic, LocationSic } from '@xpo-ltl-2.0/sdk-location';

import { LocationHelper } from '@app/shared/classes/location-helper';

@Pipe({
  name: 'activeSic',
})
export class ActiveSicPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  transform(activeSic: ActiveSic | LocationSic): any {
    if (!activeSic) {
      return;
    }
    return LocationHelper.getVisibleName(activeSic, this.titleCasePipe);
  }
}
