import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RemovedStaticPlansDialogConfig } from '@app/shared/interfaces/removed-static-plans-dialog-config.interface';

@Component({
  selector: 'app-removed-static-plans-dialog',
  templateUrl: './removed-static-plans-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RemovedStaticPlansDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RemovedStaticPlansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemovedStaticPlansDialogConfig
  ) {}

  triggerAction(response = false): void {
    this.dialogRef.close(response);
  }
}
