import { Component, Input, OnInit } from '@angular/core';

import { LoadLaneDestination } from '@xpo-ltl/sdk-dockoperations';

@Component({
  selector: 'app-destinations-list',
  templateUrl: './destinations-list.component.html',
  styleUrls: ['./destinations-list.component.scss'],
})
export class DestinationsListComponent implements OnInit {
  @Input()
  destinations: LoadLaneDestination[] = [];

  destinationsString: string = '';

  constructor() {}

  ngOnInit(): void {
    const destinationSicCodesArray = this.destinations.map((destination) => destination.destinationSicCd);
    if (destinationSicCodesArray?.length) {
      this.destinationsString = destinationSicCodesArray.join(', ');
    }
  }
}
