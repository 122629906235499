import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoInlineSearchComponent } from '@xpo-ltl/ngx-ltl-core';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

import { AutocompleteChipsFormNames } from './enums/autocomplete-chips-form-names';

@Component({
  selector: 'app-autocomplete-chips-filter',
  templateUrl: './autocomplete-chips-filter.component.html',
  styleUrls: ['./autocomplete-chips-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-autocomplete-chips-filter',
  },
})
export class AutocompleteChipsFilterComponent<T> implements OnInit, OnDestroy {
  @Input() options: T[];
  @Input() property: string;
  @Input() showFilterIcon: boolean = true;
  @Input() showSelectAll: boolean = false;
  @Input() customFilter: Function;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() matChipTemplate: TemplateRef<any>;
  @Output() currentSelection = new EventEmitter<T[]>();
  @ViewChild(XpoInlineSearchComponent, { static: true }) inlineSearch: XpoInlineSearchComponent;
  @ViewChild(MatCheckbox) selectALlCheckbox: MatCheckbox;

  readonly FormNames = AutocompleteChipsFormNames;

  valuesFormArray = new FormArray([]);
  filteredOptions: AbstractControl[] = [];
  selectedValues: AbstractControl[] = [];

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor() {}

  ngOnInit() {
    this.setOptionsFilter();
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  select(isChecked: boolean, control: AbstractControl): void {
    if (isChecked) {
      this.selectedValues.push(control);
      this.emitValues();
    } else {
      this.remove(control);
    }
  }

  remove(control: AbstractControl, uncheckedControl?: boolean): void {
    const index = this.selectedValues.indexOf(control);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }
    if (uncheckedControl) {
      control.get(AutocompleteChipsFormNames.selected).setValue(false);
    }
    if (!this.selectedValues?.length && this.selectALlCheckbox?.checked) {
      this.selectALlCheckbox.checked = false;
    }
    this.emitValues();
  }

  clearFilter(): void {
    this.selectedValues = [];
  }

  onSelectAll(event: MatCheckboxChange) {
    this.selectedValues = [];
    this.filteredOptions.forEach((control) => {
      control.get(AutocompleteChipsFormNames.selected).setValue(event.checked);
      if (event.checked) {
        this.selectedValues.push(control);
      }
    });
    this.emitValues();
  }

  private emitValues() {
    this.currentSelection.emit(this.selectedValues.map(({ value }) => value.option));
  }

  private setOptionsFilter(): void {
    if (this.inlineSearch && this.inlineSearch.searchInput) {
      this.inlineSearch.searchInput.valueChanges
        .pipe(
          startWith(''),
          filter((criteria) => typeof criteria === 'string'),
          map((criteria: string) => this._filterOptions(criteria)),
          takeUntil(this.unsubscriber.done$)
        )
        .subscribe((filterResult) => {
          this.filteredOptions = filterResult;
        });
    }
  }

  private initForm(): void {
    for (const option of this.options) {
      this.valuesFormArray.push(
        new FormGroup({
          [AutocompleteChipsFormNames.option]: new FormControl(option),
          [AutocompleteChipsFormNames.selected]: new FormControl(false),
        })
      );
    }
  }

  private _filterOptions(criteria: string): AbstractControl[] {
    if (criteria) {
      const filterValue = criteria.toLowerCase();

      if (this.customFilter && typeof this.customFilter === 'function') {
        return this.valuesFormArray.controls.filter(({ value }) => this.customFilter(value.option, filterValue));
      } else {
        return this.valuesFormArray.controls.filter(({ value }) =>
          value.option[this.property].toLowerCase().includes(criteria)
        );
      }
    }

    return this.valuesFormArray.controls;
  }
}
