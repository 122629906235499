import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Observable } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

import { DoorPlanDestination } from '@app/shared/models/door-plan';
@Component({
  selector: 'app-destinations-filter',
  templateUrl: './destinations-filter.component.html',
  styleUrls: ['./destinations-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DestinationsFilterComponent implements OnInit, OnDestroy {
  @Input()
  destinations: DoorPlanDestination[];

  @Output()
  currentSelection = new EventEmitter<DoorPlanDestination[]>();

  destinationCtrl = new FormControl();
  selectedDestinations: DoorPlanDestination[] = [];
  filteredDestinations$: Observable<DoorPlanDestination[]>;

  @ViewChild('destinationInput', { static: false })
  destinationInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false })
  matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: false, read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor() {}

  ngOnInit() {
    this.setDestinationFilter();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  remove(destination: DoorPlanDestination): void {
    const index = this.selectedDestinations.indexOf(destination);
    if (index >= 0) {
      this.selectedDestinations.splice(index, 1);
    }
    this.currentSelection.emit(this.selectedDestinations);
    requestAnimationFrame(() => {
      this.matAutocompleteTrigger.updatePosition();
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedDestinations.indexOf(event.option.value) < 0) {
      this.selectedDestinations.push(event.option.value);
    }
    this.destinationInput.nativeElement.value = '';
    this.destinationCtrl.setValue('');
    this.currentSelection.emit(this.selectedDestinations);
  }

  private setDestinationFilter(): void {
    this.filteredDestinations$ = this.destinationCtrl.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string'),
      map((sicCode: string) => this._filter(sicCode)),
      takeUntil(this.unsubscriber.done$)
    );
  }

  checkOpenPanel(): void {
    if (!this.matAutocompleteTrigger.panelOpen) {
      this.destinationInput.nativeElement.focus();
      this.matAutocompleteTrigger.openPanel();
    }
  }

  private _filter(value: string): DoorPlanDestination[] {
    if (value) {
      const filterValue = value.toUpperCase();

      return this.destinations.filter((destination) =>
        destination.destinationSicCode.toUpperCase().includes(filterValue)
      );
    }

    return this.destinations;
  }

  clearFilter(): void {
    this.selectedDestinations = [];
    this.currentSelection.emit(this.selectedDestinations);
  }

  trackByDoorPlanDestination(index: number, destination: DoorPlanDestination): string {
    return destination?.sequenceNbr;
  }
}
