import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ShiftCd } from '@xpo-ltl/sdk-common';
import _ from 'lodash';

import { ShiftHelper } from '../../classes/shift-helper';

@Component({
  selector: 'app-shift-filter',
  templateUrl: './shift-filter.component.html',
  styleUrls: ['./shift-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShiftFilterComponent {
  get shiftCd(): ShiftCd {
    return this.selectedShiftCd;
  }
  @Input()
  set shiftCd(value: ShiftCd) {
    this.setSelectedShiftCd(value);
  }

  @Output()
  shiftChange = new EventEmitter<ShiftCd>();

  private selectedShiftCd: ShiftCd;

  shiftOptions: ShiftCd[] = ShiftHelper.getList();
  shiftControl = new FormControl();

  constructor() {}

  private setSelectedShiftCd(newShiftCd: ShiftCd): void {
    if (this.selectedShiftCd !== newShiftCd) {
      this.selectedShiftCd = newShiftCd;
      this.shiftControl.setValue(newShiftCd);
      this.shiftChange.emit(newShiftCd);
    }
  }

  onOptionSelected(optionSelected) {
    this.setSelectedShiftCd(optionSelected);
  }
}
