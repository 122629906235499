import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LoadLaneFreightFlow } from '@xpo-ltl/sdk-dockoperations';

import { GetCloseToDestinationsResponse, MultipleFreightFlowPathConfig } from '@shared/interfaces';
import { MultipleFreightFlowPathFormNames } from './enums/multiple-freight-flow-path-form-names.enum';
import { selectedValuesValidator } from './validators/selected-value.validator';

@Component({
  selector: 'app-multiple-freight-flow-path',
  templateUrl: './multiple-freight-flow-path.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./multiple-freight-flow-path.component.scss'],
})
export class MultipleFreightFlowPathDialogComponent implements OnInit {
  private readonly formNames = MultipleFreightFlowPathFormNames;

  ffPathsForm: FormArray;

  constructor(
    public dialogRef: MatDialogRef<MultipleFreightFlowPathDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MultipleFreightFlowPathConfig
  ) {}

  ngOnInit(): void {
    this.initForm(this.data.loadLanes || []);
  }

  triggerAction(): void {
    const destinationSicCds = new Set(
      this.ffPathsForm.controls.reduce<string[]>((destinations, group: FormGroup) => {
        const loadLaneFF: LoadLaneFreightFlow = group.controls[this.formNames.DESTINATION_FF_PATH].value;
        const selected: boolean = group.controls[this.formNames.SELECTED].value;

        return selected
          ? destinations.concat(loadLaneFF.loadLaneDestinations.map((destination) => destination.destinationSicCd))
          : destinations;
      }, [])
    );

    const selectedItems = this.ffPathsForm.controls.filter(
      (group: FormGroup) => !!group.controls[this.formNames.SELECTED].value
    );
    const facSectorNbrArray: number[] = (selectedItems || []).map(

      (group: FormGroup) => group.controls[this.formNames.FAC_SECTOR_NBR].value
    );

    const facSector: number = facSectorNbrArray?.every((val, i, arr) => val === arr[0]) ? facSectorNbrArray[0] : null;
    const response: GetCloseToDestinationsResponse = {
      destinations: [...destinationSicCds],
      actionCancelled: false,
      facSectorNbr: facSector,
    };
    this.dialogRef.close(response);
  }

  handleCancel(): void {
    const response: GetCloseToDestinationsResponse = {
      actionCancelled: true,
    };
    this.dialogRef.close(response);
  }

  onChangeValue(): void {
    const selectedFFPaths = this.ffPathsForm.controls.filter(
      (group: FormGroup) => group.value[this.formNames.SELECTED]
    );

    if (selectedFFPaths.length === 0) {
      this.ffPathsForm.controls.forEach((group: FormGroup) => {
        group.controls[this.formNames.SELECTED].enable();
      });
    } else if (selectedFFPaths.length === 1) {
      const fFPathForm = selectedFFPaths[0] as FormGroup;
      const selectedFFPath: LoadLaneFreightFlow = fFPathForm.controls[this.formNames.DESTINATION_FF_PATH].value;

      this.ffPathsForm.controls.forEach((group: FormGroup) => {
        const currentFFPath: LoadLaneFreightFlow = group.controls[this.formNames.DESTINATION_FF_PATH].value;
        const currentSelectedControl = group.controls[this.formNames.SELECTED];

        if (
          currentFFPath.freightFlowPath.includes(selectedFFPath.freightFlowPath) ||
          selectedFFPath.freightFlowPath.includes(currentFFPath.freightFlowPath)
        ) {
          currentSelectedControl.enable();
        } else {
          currentSelectedControl.disable();
        }
      });
    }
  }

  private initForm(loadLaneFreightFlowArray: LoadLaneFreightFlow[]): void {
    this.ffPathsForm = this.fb.array([], {
      validators: [selectedValuesValidator],
    });
    loadLaneFreightFlowArray.forEach((loadLaneFF: LoadLaneFreightFlow) => {
      this.ffPathsForm.push(
        this.fb.group({
          [this.formNames.DESTINATION_FF_PATH]: this.fb.control(loadLaneFF),
          [this.formNames.SELECTED]: this.fb.control(false),
          [this.formNames.WEIGHT_REMAINING]: this.fb.control(loadLaneFF.remainingToLoadWeight),
          [this.formNames.CUBE_REMAINING]: this.fb.control(loadLaneFF.remainingToLoadCubePercentage),
          [this.formNames.FAC_SECTOR_NBR]: this.fb.control(loadLaneFF.facSectorNbr),
        })
      );
    });
  }
}
