import { TrailerStatusCd } from '@xpo-ltl/sdk-common';

export const TRAILER_STATUS_CODES_MAP: Record<string, TrailerStatusCd> = {
  APPT: TrailerStatusCd.APPOINTMENT,
  ARIV: TrailerStatusCd.ARRIVE,
  CARV: TrailerStatusCd.CITY_ARRIVE,
  CLDK: TrailerStatusCd.CITY_LOADING_AT_DOCK,
  CLDV: TrailerStatusCd.CLOSE_FOR_DELIVERY,
  CLOS: TrailerStatusCd.CLOSE_FOR_LINEHAUL,
  DROP: TrailerStatusCd.DROP,
  EMTY: TrailerStatusCd.EMPTY,
  LDCU: TrailerStatusCd.LOADING_AT_CUSTOMER,
  LDDK: TrailerStatusCd.LOADING_AT_DOCK,
  PDRT: TrailerStatusCd.PICKUP_AND_DELIVERY,
  TRAP: TrailerStatusCd.TRAP,
  UNLC: TrailerStatusCd.UNLOAD_AT_CUSTOMER,
  UNLD: TrailerStatusCd.UNLOAD_AT_DOCK,
  OVHD: TrailerStatusCd.OVERHEAD,
};
