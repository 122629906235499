import { Pipe, PipeTransform } from '@angular/core';

import { Equipment } from '@xpo-ltl/sdk-dockoperations';

@Pipe({
  name: 'trailerNumber',
})
export class TrailerNumberPipe implements PipeTransform {
  transform(trailer: Equipment): string {
    if (trailer?.equipmentIdPrefix && trailer.equipmentIdSuffixNbr) {
      return `${trailer.equipmentIdPrefix}-${trailer.equipmentIdSuffixNbr}`;
    }

    return '';
  }
}
