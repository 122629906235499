export class RequirementBeacon {
  doorNbr?: string;
  loadLanes: [
    {
      destinationSicCodes: string[];
      destinationsString: string;
      moveToSicCode: string;
      moveToShiftCode: string;
      nextLoadLegSicCode: string;
      nextLoadLegShiftCode: string;
      closeToSicCode: string;
      closeToShiftCode: string;
      loadLevel: number;
    }
  ];

  constructor(beaconMessage: string) {
    if (beaconMessage) {
      const parsedBeacon = JSON.parse(beaconMessage);
      this.doorNbr = parsedBeacon.doorNumber;
      this.loadLanes = parsedBeacon.loadLanes;
      this.loadLanes?.forEach((lane) => {
        lane.destinationsString = ' ' + lane.destinationSicCodes?.join(', ');
      });
    }
  }
}
