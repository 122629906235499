import { FormControl } from '@angular/forms';

export function trailerTypeValidator(formControl: FormControl): { [key: string]: boolean } | null {
  const trailer = formControl.value;

  if (trailer && typeof trailer === 'object') {
    return null;
  }

  return { trailerType: true };
}
