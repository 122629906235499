import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanListService {
  private readonly _refreshData = new Subject<void>();
  refreshData$: Observable<void> = this._refreshData.asObservable();

  constructor() {}

  refresh(): void {
    this._refreshData.next();
  }
}
