import { AppAction } from '@shared/types/actions.type';
import { DoorPlanItem } from './door-plan-item.model';

export class GTAction {
  action: AppAction;
  previousAction?: AppAction;
  data: GTActionData;
  showSuccessMessage?: boolean;
  /** Skip refreshing data after success action */
  skipRefreshData?: boolean;
  /** For misload */
  errorOverrideInd?: boolean;
}

export class GTActionData {
  firstRow?: DoorPlanItem;
  secondRow?: DoorPlanItem;
  multipleRows?: DoorPlanItem[];
  additionalData?: {
    [key: string]: any;
  };
}
