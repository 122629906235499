import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { MoreInfo } from '@xpo-ltl/sdk-common';
import { MaintainDockDoorPreferencesResp } from '@xpo-ltl/sdk-dockoperations';
import { cloneDeep as _cloneDeep } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { RemoveP1DoorDialogComponent } from '@app/dialogs/remove-p1-door-dialog/remove-p1-door-dialog.component';
import { AddUpdatePlanEvent } from '@app/door-plan-common/interfaces';
import { AssingTrailerEvent } from '@app/door-plan-common/interfaces/assign-trailer-event.interface';
import { TRAILER_STATUS_CODES_MAP } from '@shared/constants/trailer-status.const';
import { ActionsService } from '../../classes/actions-service';
import { DoorPlanActionsEnum } from '../../enums/door-plan';
import { ActionsServiceEnum } from '../../enums/service-actions.enum';
import { DoorPlanItem } from '../../models/door-plan';
import { GTAction, GTActionData } from '../../models/door-plan/grids-toolbar-actions.model';
import { CurrentDoorPlanProfileService } from '../current-door-plan-profile/current-door-plan-profile.service';
import { DoorPlanBulkSelection } from '../door-plan-bulk-selection/door-plan-bulk-selection';
import { DoorPlanProfileService } from '../door-plan-profile/door-plan-profile.service';
import { DoorPlanService } from '../door-plan-service/door-plan.service';
import { NavbarFiltersService } from '../navbar-filters-service';
import { DoorPlanActionHelper } from './door-plan-actions-helper';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanActionsService extends ActionsService {
  // #region Toast Refs
  private moveInfoToastRef: MatSnackBarRef<XpoNotificationTemplate> = null;
  private copyInfoToastRef: MatSnackBarRef<XpoNotificationTemplate> = null;
  private mergeInfoToastRef: MatSnackBarRef<XpoNotificationTemplate> = null;
  // #endregion

  constructor(
    _confirmDialog: XpoConfirmDialog,
    _snackBar: XpoSnackBar,
    _dialog: MatDialog,
    private doorPlanService: DoorPlanService,
    private currentDoorPlanProfile: CurrentDoorPlanProfileService,
    private doorPlanProfileService: DoorPlanProfileService,
    private filtersService: NavbarFiltersService,
    private doorPlanBulkSelection: DoorPlanBulkSelection
  ) {
    super(_confirmDialog, _snackBar, _dialog);
  }

  handleActionChange(gridsToolbarAction: GTAction): void {
    switch (gridsToolbarAction.action) {
      case DoorPlanActionsEnum.PERFORM_COPY:
        this.onCopyEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.PERFORM_MOVE:
        this.onMoveEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.PERFORM_MERGE:
        this.onMergeEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.PERFORM_SWAP:
        this.onSwapDoorsTriggered(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.PERFORM_RESET:
        this.resetSelectedDoorPlan(
          [gridsToolbarAction.data.firstRow],
          false,
          gridsToolbarAction.showSuccessMessage
        ).subscribe();
        break;
      case DoorPlanActionsEnum.PERFORM_REMOVE:
        this.onRemoveEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.PERFORM_ADD:
        this.onAddEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case DoorPlanActionsEnum.MAKE_STATIC:
        this.makeStaticDoorPlan(gridsToolbarAction);
        break;
      case DoorPlanActionsEnum.REMOVE_STATIC:
        this.removeStaticDoorPlan(gridsToolbarAction);
        break;
      case DoorPlanActionsEnum.EXCLUDE_FROM_STEP_SAVER:
      case DoorPlanActionsEnum.HOLD_FOR_NEXT_SHIFT:
      case DoorPlanActionsEnum.MARK_UNLOAD:
      case DoorPlanActionsEnum.REMOVE_EXCLUSION:
      case DoorPlanActionsEnum.REMOVE_UNLOAD:
      case DoorPlanActionsEnum.REMOVE_HOLD:
      case DoorPlanActionsEnum.EMBARGO_DOOR:
      case DoorPlanActionsEnum.REMOVE_EMBARGO:
        this.onMaintainDockDoorPreferenceEnded(gridsToolbarAction);
        break;
      case ActionsServiceEnum.ACTION_ENDED:
        this.setActionInProgress(false);
        break;
      case ActionsServiceEnum.ACTION_CANCELED:
        this.setActionInProgress(false);
        if (this.copyInfoToastRef) {
          this.copyInfoToastRef.dismiss();
        }
        break;
      case DoorPlanActionsEnum.ASSIGN_TRAILER:
      case DoorPlanActionsEnum.UPDATE_TRAILER:
        this.assignTrailer(gridsToolbarAction, gridsToolbarAction.showSuccessMessage);
        break;
    }
  }

  showModalWarning(
    moreInfo: MoreInfo[],
    action?: DoorPlanActionsEnum,
    forceRefreshOnError = false,
    isOverrideCall = false
  ): void {
    let displayedModal: boolean;

    if (action === DoorPlanActionsEnum.PERFORM_ADD) {
      displayedModal = this.showTrailerloadingDialog(moreInfo, forceRefreshOnError);
    }

    if (!displayedModal) {
      this.showMisloadWarning(moreInfo, forceRefreshOnError, isOverrideCall, action);
    }
  }

  startMergeDoorPlan(doorPlan: DoorPlanItem): void {
    this.setActionInProgress(true);
    this.mergeInfoToastRef = this.showActionInfoToast(
      DoorPlanActionHelper.getInfoToast(doorPlan, DoorPlanActionsEnum.PERFORM_MERGE)
    );

    if (this.mergeInfoToastRef) {
      this.mergeInfoToastRef.afterDismissed().subscribe(() => this.onActionCancelled());
    }

    this.setActionPerformed({
      action: DoorPlanActionsEnum.MERGE_TRIGGERED,
      data: {
        firstRow: doorPlan,
        secondRow: null,
      },
    });
  }

  startMoveDoorPlan(doorPlan: DoorPlanItem): void {
    this.setActionInProgress(true);
    this.moveInfoToastRef = this.showActionInfoToast(
      DoorPlanActionHelper.getInfoToast(doorPlan, DoorPlanActionsEnum.PERFORM_MOVE)
    );

    if (this.moveInfoToastRef) {
      this.moveInfoToastRef.afterDismissed().subscribe(() => this.onActionCancelled());
    }

    this.setActionPerformed({
      action: DoorPlanActionsEnum.MOVE_TRIGGERED,
      data: {
        firstRow: doorPlan,
        secondRow: null,
      },
    });
  }

  startCopyDoorPlan(doorPlanItem: DoorPlanItem): void {
    this.setActionInProgress(true);
    this.copyInfoToastRef = this.showActionInfoToast(
      DoorPlanActionHelper.getInfoToast(doorPlanItem, DoorPlanActionsEnum.PERFORM_COPY)
    );

    if (this.copyInfoToastRef) {
      this.copyInfoToastRef.afterDismissed().subscribe(() => this.onActionCancelled());
    }

    this.setActionPerformed({
      action: DoorPlanActionsEnum.COPY_TRIGGERED,
      data: {
        firstRow: doorPlanItem,
        secondRow: null,
      },
    });
  }

  swapDoorPlans(actionData: GTActionData): void {
    this.setActionInProgress(true);
    this.setActionPerformed({
      action: DoorPlanActionsEnum.PERFORM_SWAP,
      data: actionData,
    });
  }

  resetSelectedDoorPlan(
    doorPlanItems: DoorPlanItem[],
    isSelectAll = false,
    showSuccessMessage = true
  ): Observable<void> {
    this.setActionInProgress(true);
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    return this.doorPlanService.resetDoorPlans(doorPlanProfileId, doorPlanItems, isSelectAll).pipe(
      tap(() => {
        if (showSuccessMessage) {
          this.showActionSuccessToast(
            DoorPlanActionHelper.getToastMessageByActionDoorPlanItem(doorPlanItems, DoorPlanActionsEnum.PERFORM_RESET)
          );
        }
        this.setActionPerformed({
          action: ActionsServiceEnum.ACTION_ENDED,
          data: null,
        });
      }),
      catchError((error) => {
        this.setActionInProgress(false);
        return of(null);
      })
    );
  }

  resetAllDoorPlan(): Observable<void> {
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    if (!doorPlanProfileId) {
      return;
    }
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Continue',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };
    return this.confirmDialog
      .confirm(
        'Resetting the entire Door Plan will revert any changes that have been made back to the weekly plan',
        'Do you want to reset the entire Door Plan?',
        confirmConfig
      )
      .pipe(
        filter((confirm) => !!confirm),
        switchMap(() => this.resetSelectedDoorPlan([], true))
      );
  }

  private onSwapDoorsTriggered(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    this.doorPlanService
      .swapDoorPlans(doorPlanProfileId, actionData.firstRow.doorNbr, actionData.secondRow.doorNbr, errorOverrideInd)
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_SWAP)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => this.onActionsError(error)
      );
  }

  private onMoveEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    if (this.moveInfoToastRef) {
      this.moveInfoToastRef.dismiss();
    }
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    let stepSaverChanged = false;

    this.checkStepSaverBeforeAction(actionData.secondRow)
      .pipe(
        switchMap((res) => {
          stepSaverChanged = res;

          return this.doorPlanService.moveDoorPlan(
            doorPlanProfileId,
            actionData.firstRow.doorNbr,
            actionData.secondRow.doorNbr,
            errorOverrideInd
          );
        })
      )
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_MOVE)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => this.onActionsError(error, DoorPlanActionsEnum.PERFORM_MOVE, stepSaverChanged)
      );
  }

  private onCopyEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    if (this.copyInfoToastRef) {
      this.copyInfoToastRef.dismiss();
    }
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    let stepSaverChanged = false;

    this.checkStepSaverBeforeAction(actionData.secondRow)
      .pipe(
        switchMap((res) => {
          stepSaverChanged = res;

          return this.doorPlanService.copyDoorPlan(
            doorPlanProfileId,
            actionData.firstRow.doorNbr,
            actionData.secondRow.doorNbr,
            errorOverrideInd
          );
        })
      )
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_COPY)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => this.onActionsError(error, DoorPlanActionsEnum.PERFORM_COPY, stepSaverChanged)
      );
  }

  private onMergeEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    if (this.mergeInfoToastRef) {
      this.mergeInfoToastRef.dismiss();
    }
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();

    this.doorPlanService
      .mergeDoorPlan(doorPlanProfileId, actionData.firstRow.doorNbr, actionData.secondRow.doorNbr, errorOverrideInd)
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_MERGE)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => this.onActionsError(error)
      );
  }

  private onRemoveEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    if (actionData.firstRow.priorityNbr === '1') {
      this.dialog.open(RemoveP1DoorDialogComponent);
    } else {
      const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
      this.doorPlanService.removeDoorPlan(doorPlanProfileId, actionData.firstRow.doorNbr, errorOverrideInd).subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_REMOVE)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => this.onActionsError(error)
      );
    }
  }

  private onAddEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    let stepSaverChanged = false;

    this.checkStepSaverBeforeAction(actionData.firstRow)
      .pipe(
        switchMap((res) => {
          stepSaverChanged = res;

          return this.doorPlanService.addDoorPlan(
            doorPlanProfileId,
            actionData.firstRow.doorNbr,
            actionData.additionalData as AddUpdatePlanEvent,
            errorOverrideInd
          );
        })
      )
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              DoorPlanActionHelper.getToastMessageByActionGTA(actionData, DoorPlanActionsEnum.PERFORM_ADD)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
            previousAction: DoorPlanActionsEnum.PERFORM_ADD,
          });
        },
        (error) => this.onActionsError(error, DoorPlanActionsEnum.PERFORM_ADD, stepSaverChanged, true)
      );
  }

  private removeStaticDoorPlan(action: GTAction) {
    const actionData = action.data;
    const currentStaticProfile = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile();

    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Continue',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };
    const modalMessage = actionData.firstRow ? ['this P1 door', 'Plan'] : ['these P1 doors', 'Plans'];

    return this.confirmDialog
      .confirm(
        `All P2 doors associated with ${modalMessage[0]} will be removed`,
        `Remove Static Door ${modalMessage[1]}?`,
        confirmConfig
      )
      .pipe(
        filter((confirm) => !!confirm),
        take(1)
      )
      .subscribe(() => {
        if (actionData.firstRow) {
          this.doorPlanService
            .removeDoorPlan(
              currentStaticProfile.doorPlanProfileId,
              actionData.firstRow.doorNbr,
              action.errorOverrideInd
            )
            .pipe(take(1))
            .subscribe(
              () => {
                this.setActionPerformed({
                  action: ActionsServiceEnum.ACTION_ENDED,
                  previousAction: action.action,
                  data: actionData,
                  skipRefreshData: true,
                });
                this.showActionSuccessToast(DoorPlanActionHelper.getToastMessageByActionGTA(actionData, action.action));
              },
              (error) => this.onActionsError(error, action.action)
            );
        } else {
          this.removeStaticDoorPlanBulk(action);
        }
      });
  }

  private removeStaticDoorPlanBulk(action: GTAction) {
    const actionData = action.data;
    const resultResponse = {
      errors: [] as DoorPlanItem[],
      success: [] as DoorPlanItem[],
    };

    const currentStaticProfile = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile();
    const removeStaticRqsts = actionData.multipleRows.map((doorPlan) => {
      return this.doorPlanService.removeDoorPlan(currentStaticProfile.doorPlanProfileId, doorPlan.doorNbr).pipe(
        take(1),
        tap(() => resultResponse.success.push(doorPlan)),
        catchError(() => {
          resultResponse.errors.push(doorPlan);

          return of(null);
        })
      );
    });

    forkJoin(removeStaticRqsts).subscribe(
      () => {
        if (resultResponse.errors.length) {
          this.snackBar.error(
            DoorPlanActionHelper.getToastErrorMessageByActionDoorPlanItem(resultResponse.errors, action.action)
          );
        } else {
          this.showActionSuccessToast(
            DoorPlanActionHelper.getToastMessageByActionDoorPlanItem(actionData.multipleRows, action.action)
          );
        }

        this.setActionPerformed({
          action: ActionsServiceEnum.ACTION_ENDED,
          previousAction: action.action,
          data: action.data,
        });
      },
      () => this.onActionsError(null)
    );
  }

  private makeStaticDoorPlan(action: GTAction) {
    const actionData = action.data;

    if (actionData.firstRow) {
      const currentStaticProfile = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile();
      const newStaticDoorPlan: DoorPlanItem = _cloneDeep(actionData.firstRow);
      newStaticDoorPlan.doorPlanProfileId = currentStaticProfile.doorPlanProfileId;

      this.doorPlanService.createStaticDoorPlan(newStaticDoorPlan, action.errorOverrideInd).subscribe(
        () => {
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            previousAction: action.action,
            data: actionData,
            skipRefreshData: true,
          });
          this.showActionSuccessToast(DoorPlanActionHelper.getToastMessageByActionGTA(actionData, action.action));
        },
        (error) => this.onActionsError(error, action.action)
      );
    } else {
      this.makeStaticDoorPlanBulk(action);
    }
  }

  private makeStaticDoorPlanBulk(action: GTAction) {
    const actionData = action.data;
    const resultResponse = {
      errors: [] as DoorPlanItem[],
      success: [] as DoorPlanItem[],
    };

    const currentStaticProfile = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile();
    const makeStaticRqsts = actionData.multipleRows.map((doorPlan) => {
      doorPlan.doorPlanProfileId = currentStaticProfile.doorPlanProfileId;

      return this.doorPlanService.createStaticDoorPlan(doorPlan, true).pipe(
        take(1),
        tap(() => resultResponse.success.push(doorPlan)),
        catchError(() => {
          resultResponse.errors.push(doorPlan);

          return of(null);
        })
      );
    });

    forkJoin(makeStaticRqsts).subscribe(
      () => {
        if (resultResponse.errors.length) {
          this.snackBar.error(
            DoorPlanActionHelper.getToastErrorMessageByActionDoorPlanItem(resultResponse.errors, action.action)
          );
        } else {
          this.showActionSuccessToast(
            DoorPlanActionHelper.getToastMessageByActionDoorPlanItem(actionData.multipleRows, action.action)
          );
        }

        this.setActionPerformed({
          action: ActionsServiceEnum.ACTION_ENDED,
          previousAction: action.action,
          data: action.data,
        });
      },
      () => this.onActionsError(null)
    );
  }

  private onMaintainDockDoorPreferenceEnded(action: GTAction): void {
    const doorplans = DoorPlanActionHelper.getDoorsForDockDoorPrerencesAction(
      action.action as DoorPlanActionsEnum,
      action.data.multipleRows || [action.data.firstRow]
    );

    if (doorplans?.length < 1) {
      this.doorPlanBulkSelection.resetSelectedDoors();
      return;
    }

    this.maintainDockDoorPreferenceWrapper(doorplans).subscribe(
      () => {
        this.setActionPerformed({
          action: ActionsServiceEnum.ACTION_ENDED,
          previousAction: action.action,
          data: action.data,
        });
        this.showActionSuccessToast(
          DoorPlanActionHelper.getToastMessageByActionDoorPlanItem(doorplans, action.action, action.data)
        );
      },
      (error) => this.onActionsError(error, action.action)
    );
  }

  private maintainDockDoorPreferenceWrapper(doorPlans: DoorPlanItem[]): Observable<MaintainDockDoorPreferencesResp> {
    return this.doorPlanService.maintainDockDoorPreference(
      this.filtersService.selectedSic,
      this.filtersService.selectedShift,
      this.currentDoorPlanProfile.getCurrentDoorPlanProfileType(),
      doorPlans
    );
  }

  private checkStepSaverBeforeAction(doorPlan: DoorPlanItem): Observable<boolean> {
    if (doorPlan?.isEmpty() && doorPlan.doorPreference?.stepSaverExcludeInd) {
      const doorplans = DoorPlanActionHelper.getDoorsForDockDoorPrerencesAction(DoorPlanActionsEnum.REMOVE_EXCLUSION, [
        doorPlan,
      ]);

      return this.maintainDockDoorPreferenceWrapper(doorplans).pipe(mapTo(true));
    } else {
      return of(false);
    }
  }

  private assignTrailer(action: GTAction, showSuccessMessage = true): void {
    const sicCd = this.filtersService.selectedSic;
    const shiftCd = this.filtersService.selectedShift;
    const doorNbr = action.data.firstRow.doorNbr;
    const additionalData = action.data.additionalData as AssingTrailerEvent;
    const equipmentId = additionalData.equipment.equipmentId;
    const trailerNbr = additionalData.equipment.equipmentNbr;
    const trailerStatus = TRAILER_STATUS_CODES_MAP[action.data.additionalData.status];

    this.doorPlanService
      .assignTrailerToDoor(sicCd, shiftCd, doorNbr, equipmentId, trailerNbr, trailerStatus)
      .subscribe((response) => {
        if (showSuccessMessage) {
          this.showActionSuccessToast(
            DoorPlanActionHelper.getToastMessageByActionTrailer(response.trailer, action.action)
          );
        }
        this.setActionPerformed({
          action: ActionsServiceEnum.ACTION_ENDED,
          previousAction: action.action,
          skipRefreshData: true,
          data: action.data,
        });
      });
  }
}
