import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';

export interface LocalStorageValue {
  value: any;
  expiration?: Moment;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setLocalStorage(key: string, localStorageValue: LocalStorageValue): void {
    try {
      localStorage.setItem(key, JSON.stringify(localStorageValue));
    } catch (error) {
      localStorage.clear();
      localStorage.setItem(key, JSON.stringify(localStorageValue));
    }
  }

  getValue(key: string): any {
    const storedValue: LocalStorageValue = JSON.parse(localStorage.getItem(key) || null);
    if (!storedValue) {
      return null;
    }
    const hasExpired = storedValue.expiration ? moment(storedValue.expiration).isBefore(moment()) : false;
    if (hasExpired) {
      this.remove(key);
      return null;
    } else {
      return storedValue.value;
    }
  }

  clearExpiredData() {
    Object.keys(localStorage).map((key) => {
      try {
        const storedValue: LocalStorageValue = JSON.parse(localStorage.getItem(key) || null);
        if (!storedValue) {
          return;
        }
        const hasExpired = storedValue.expiration ? moment(storedValue.expiration).isBefore(moment()) : false;
        if (hasExpired) {
          this.remove(key);
        }
      } catch (error) {
        return;
      }
    });
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
