import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LoadLaneInfo } from '@shared/models/door-plan';

@Component({
  selector: 'app-load-lane-info',
  templateUrl: './load-lane-info.component.html',
  styleUrls: ['./load-lane-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'load-lane-info',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadLaneInfoComponent {
  @Input()
  loadLaneInfo: LoadLaneInfo;
  @Input()
  showAditionalData: boolean = false;
}
