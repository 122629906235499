export class RecommendationBeacon {
  doorNbr?: string;
  shiftCd?: string;
  scoDoorPlan?: {
    moveToSicCode?: string;
    closeToSicCode?: string;
  };
  loadLane: {
    moveToSicCode?: string;
    closeToSicCode?: string;
    totalCubePct?: string;
    totalWeight?: string;
    destinationSicCodes?: string[];
  };

  constructor(beaconMessage: string) {
    if (beaconMessage) {
      const parsedBeacon = JSON.parse(beaconMessage);
      this.doorNbr = parsedBeacon.doorNumber;
      this.shiftCd = parsedBeacon.shiftCd;
      this.loadLane = {};
      this.loadLane.moveToSicCode = parsedBeacon.loadLane?.moveToSicCode;
      this.loadLane.closeToSicCode = parsedBeacon.loadLane?.closeToSicCode;
      this.loadLane.totalCubePct = parsedBeacon.loadLane?.totalCubePct;
      this.loadLane.totalWeight = parsedBeacon.loadLane?.totalWeight;
      this.loadLane.destinationSicCodes = parsedBeacon.loadLane?.destinationSicCodes;
      this.scoDoorPlan = {};
      this.scoDoorPlan.moveToSicCode = parsedBeacon.scoDoorPlan?.moveToSicCode;
      this.scoDoorPlan.closeToSicCode = parsedBeacon.scoDoorPlan?.closeToSicCode;
    }
  }
}
