export enum TrailerStatusEnum {
  APPT = 'APPOINTMENT',
  ARIV = 'ARRIVED',
  CARV = 'CITY ARRIVE',
  CLDK = 'CITY LOAD',
  CLDV = 'CLOSE DELVR',
  CLOS = 'CLOSE',
  DROP = 'DROP',
  EMTY = 'EMPTY',
  LDCU = 'LOAD AT CUST',
  LDDK = 'LOADING',
  OVHD = 'OVERHEAD',
  PDRT = 'P&D ROUTE',
  TRAP = 'TRAP',
  UNLC = 'UNLOAD CUST',
  UNLD = 'UNLOADING',
}
