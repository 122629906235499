import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoInlineSearchComponent } from '@xpo-ltl/ngx-ltl-core';
import { takeUntil } from 'rxjs/operators';

import { DoorPlanActionsEnum } from '@app/shared/enums/door-plan';
import { XpoContextMenuItem } from '../../../context-menu/public_api';
import { DoorPlanItem } from '../../../shared/models/door-plan';

@Component({
  selector: 'app-door-plan-context-submenu',
  templateUrl: './door-plan-context-submenu.component.html',
  styleUrls: ['./door-plan-context-submenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-context-submenu',
  },
})
export class DoorPlanContextSubmenuComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  @ViewChild(XpoInlineSearchComponent, { static: true })
  inlineSearch: XpoInlineSearchComponent;

  @Input()
  menuItem: XpoContextMenuItem<DoorPlanItem>;
  @Input()
  doorPlanItems: DoorPlanItem[];
  @Input()
  doorPlanItem: DoorPlanItem;

  @Output()
  itemSelected = new EventEmitter<DoorPlanItem>();

  availableDoorPlanItems: DoorPlanItem[];
  doorPlanItemFiltered: DoorPlanItem[];

  constructor() {}

  ngOnInit() {
    this.availableDoorPlanItems = this.doorPlanItems.filter((doorPlanItem) =>
      this.filterDoorPlanItemsByMenuAction(doorPlanItem)
    );
    this.doorPlanItemFiltered = [...this.availableDoorPlanItems];
    this.initWatcherFilter();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private filterDoorPlanItemsByMenuAction(doorPlanItem: DoorPlanItem): boolean {
    const actionName = this.menuItem ? this.menuItem.name : '';
    switch (actionName) {
      case DoorPlanActionsEnum.PERFORM_COPY:
        return (doorPlanItem.isEmpty() || doorPlanItem.priorityNbr === '2') && !doorPlanItem.doorPreference?.trapInd;
      case DoorPlanActionsEnum.PERFORM_MOVE:
        return (doorPlanItem.isEmpty() || doorPlanItem.priorityNbr === '2') && !doorPlanItem.doorPreference?.trapInd;
      case DoorPlanActionsEnum.PERFORM_SWAP:
        return !doorPlanItem.isEmpty();
      case DoorPlanActionsEnum.PERFORM_MERGE:
        // It is filtered in context menu
        return true;
      default:
        return true;
    }
  }

  private initWatcherFilter(): void {
    if (this.inlineSearch && this.inlineSearch.searchInput) {
      this.inlineSearch.searchInput.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((value) => {
        const searchValue = value.toLocaleLowerCase();
        this.doorPlanItemFiltered = this.availableDoorPlanItems.filter((item) =>
          item.searchText.toLocaleLowerCase().includes(searchValue)
        );
      });
    }
  }

  onItemClick(doorPlanItem: DoorPlanItem): void {
    this.itemSelected.emit(doorPlanItem);
  }

  trackByDoorPlanItem(index: number, doorPlanItem: DoorPlanItem): string {
    return doorPlanItem?.displaySequenceNbr;
  }
}
