import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { DoorPlanProfileTypeCd, ShiftCd } from '@xpo-ltl/sdk-common';
import { ScoDoorPlan } from '@xpo-ltl/sdk-dockoperations';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DateHelper } from '@app/shared/classes/date-helper';
import { RemovedStaticPlansDialogConfig } from '@app/shared/interfaces/removed-static-plans-dialog-config.interface';
import { RemovedStaticPlansDialogComponent } from '@dialogs/removed-static-plans-dialog/removed-static-plans-dialog.component';
import { WeekDayHelper } from '@shared/classes/week-day-helper';
import moment from 'moment';
import { DoorPlanProfile } from '../../models/door-plan';
import { DoorPlanProfileService } from '../door-plan-profile/door-plan-profile.service';
import { NavbarFiltersService } from '../navbar-filters-service/filters-service.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentDoorPlanProfileService {
  private _currentDoorPlanProfileSubject = new BehaviorSubject<DoorPlanProfile>(undefined);
  currentDoorPlanProfile$ = this._currentDoorPlanProfileSubject.asObservable();

  constructor(
    private doorPlanProfileService: DoorPlanProfileService,
    private filterService: NavbarFiltersService,
    private snackBar: XpoSnackBar,
    private dialog: MatDialog
  ) {}

  setCurrentDoorPlanProfile(newProfile: DoorPlanProfile): void {
    if (!newProfile) {
      return;
    }
    this._currentDoorPlanProfileSubject.next(newProfile);
  }

  getCurrentDoorPlanProfile(): DoorPlanProfile {
    return this._currentDoorPlanProfileSubject.value;
  }

  getCurrentDoorPlanProfileId(): number {
    const currentProfile = this.getCurrentDoorPlanProfile();
    return currentProfile ? currentProfile.doorPlanProfileId : undefined;
  }

  getCurrentDoorPlanProfileType(): DoorPlanProfileTypeCd {
    const currentProfile = this.getCurrentDoorPlanProfile();
    return currentProfile ? currentProfile.profileTypeCd : undefined;
  }

  getDoorPlanProfileDate(doorPlanProfile?: DoorPlanProfile): Date {
    let planDate = new Date();
    doorPlanProfile = doorPlanProfile || this.getCurrentDoorPlanProfile();
    if (doorPlanProfile) {
      if (this.isActive() || this.isStatic()) {
        planDate = WeekDayHelper.getShiftDate(planDate, doorPlanProfile.shiftCd);
      } else {
        planDate = WeekDayHelper.getDateByWeekDay(doorPlanProfile.dayOfWeekCd);
      }
    }

    return planDate;
  }

  isActive(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    return !!doorPlanProfile && doorPlanProfile.isActive;
  }

  isStatic(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    return !!doorPlanProfile && doorPlanProfile.isStaticDoorPlanProfile();
  }

  canBeActivated(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    if (!doorPlanProfile) {
      return false;
    }
    const shiftCd = this.filterService.selectedShift;

    const currentDate = WeekDayHelper.getShiftDate(new Date(), doorPlanProfile.shiftCd);
    const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
    const currentDayIndex = WeekDayHelper.getWeekDayIndex(currentDay);
    if (this.isTodaysProfileActive() && currentDay === doorPlanProfile.dayOfWeekCd) {
      return false;
    } else if (
      shiftCd === ShiftCd.INBOUND &&
      this.isTomorrowsProfileActive() &&
      (currentDay === doorPlanProfile.dayOfWeekCd ||
        currentDayIndex + 1 === WeekDayHelper.getWeekDayIndex(doorPlanProfile.dayOfWeekCd))
    ) {
      return false;
    } else {
      return doorPlanProfile.canBeActivatedByUserAction(shiftCd, this.doorPlanProfileService.getDoorPlanProfiles());
    }
  }

  canShowProfile(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    if (!doorPlanProfile) {
      return false;
    }

    const currentDate = WeekDayHelper.getShiftDate(new Date(), doorPlanProfile.shiftCd);
    const currentDay = WeekDayHelper.getWeekDayByDate(currentDate);
    const currentDayIndex = WeekDayHelper.getWeekDayIndex(currentDay);
    const newProfileDayIndex = WeekDayHelper.getWeekDayIndex(doorPlanProfile.dayOfWeekCd);
    return (
      doorPlanProfile.isActive ||
      doorPlanProfile.isStaticDoorPlanProfile() ||
      newProfileDayIndex < currentDayIndex ||
      (newProfileDayIndex === currentDayIndex && this.isTodaysProfileActive()) ||
      (this.filterService.selectedShift === ShiftCd.INBOUND &&
        newProfileDayIndex <= currentDayIndex + 1 &&
        this.isTomorrowsProfileActive())
    );
  }

  isTodaysProfileActive(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    const currentDate = WeekDayHelper.getShiftDate(new Date(), doorPlanProfile?.shiftCd);
    const momentShiftDate = moment(currentDate);
    return !!this.doorPlanProfileService
      .getDoorPlanProfiles()
      .find((profile) => profile.isActive && profile.profileDate?.isSame(momentShiftDate, 'day'));
  }

  isTomorrowsProfileActive(): boolean {
    const doorPlanProfile = this.getCurrentDoorPlanProfile();
    const currentDate = WeekDayHelper.getShiftDate(new Date(), doorPlanProfile.shiftCd);
    const momentShiftDate = moment(currentDate);
    return !!this.doorPlanProfileService
      .getDoorPlanProfiles()
      .find(
        (profile) =>
          profile.isActive &&
          profile.profileDate?.isAfter(momentShiftDate, 'day') &&
          moment.duration(profile.profileDate?.diff(momentShiftDate.startOf('day'))).asDays() === 1
      );
  }

  activateDoorPlanProfile(doorPlanProfile?: DoorPlanProfile, showRemovedStaticDoors: boolean = true): void {
    doorPlanProfile = doorPlanProfile || this.getCurrentDoorPlanProfile();
    if (!doorPlanProfile) {
      return;
    }
    this.doorPlanProfileService
      .activateDoorPlanProfile(
        doorPlanProfile.dayOfWeekCd,
        this.filterService.selectedSic,
        this.filterService.selectedShift
      )
      .pipe(
        switchMap((doorPlanActivated) =>
          this.doorPlanProfileService.listDoorPlanProfiles(doorPlanActivated.sicCd, doorPlanActivated.shiftCd)
        )
      )
      .subscribe(
        (doorPlanProfiles) => {
          this.doorPlanProfileService.setDoorPlanProfiles(doorPlanProfiles || []);
          if (showRemovedStaticDoors) {
            this.getRemovedStaticDoors();
          }
          this.createLoadLanes(doorPlanProfile);
        },
        (error) => {
          this.onActionsError(error);
        }
      );
  }

  private createLoadLanes(doorPlanProfile: DoorPlanProfile) {
    if (
      this.filterService.selectedShift === ShiftCd.OUTBOUND ||
      this.filterService.selectedShift === ShiftCd.NIGHT_FAC
    ) {
      this.doorPlanProfileService
        .createLoadLanesByDockPlan(
          this.filterService.selectedSic,
          this.filterService.selectedShift,
          DateHelper.getFormatDate(WeekDayHelper.getDateByWeekDay(doorPlanProfile.dayOfWeekCd), 'YYYY-MM-DD')
        )
        .subscribe();
    }
  }

  /** Compares activated plan vs static plan and returns all the static doorPlans that have been
   * removed from the active plan
   */
  getRemovedStaticDoors() {
    const currentProfileId = this.getCurrentDoorPlanProfileId();
    const currentStaticProfileId = this.doorPlanProfileService.getCurrentStaticDoorPlanProfile().doorPlanProfileId;

    this.doorPlanProfileService
      .getRemovedStaticDoors(currentProfileId, currentStaticProfileId)
      .subscribe((removedStaticDoorPlans: ScoDoorPlan[]) => {
        const currentProfile = this.getCurrentDoorPlanProfile();

        if (removedStaticDoorPlans.length === 0) {
          this.snackBar.success(`${currentProfile.name}'s Plan is now active`);
        } else {
          removedStaticDoorPlans.sort((a, b) => Number(a.doorNbr) - Number(b.doorNbr));
          const dialogData: RemovedStaticPlansDialogConfig = {
            currentProfile,
            removedStaticDoorPlans,
          };
          this.dialog.open(RemovedStaticPlansDialogComponent, { data: dialogData });
        }
      });
  }

  private onActionsError(error: any): void {
    // Generic error validation
    if (error && error.error && error.error.moreInfo && error.error.moreInfo.length) {
      const message = error.error.moreInfo[0].message;
      const errorCode = error.error.errorCode;
      this.snackBar.error(`${errorCode}: ${message}`);
    }
  }
}
