import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ListInfo, ShiftCd } from '@xpo-ltl/sdk-common';
import {
  CreateDoorPlanProfilePath,
  CreateDoorPlanProfileRqst,
  CreateLoadLanesByDockPlanPath,
  CreateLoadLanesByDockPlanRqst,
  CreateWeeklyDefaultDoorPlanPath,
  CreateWeeklyDefaultDoorPlanRqst,
  DockOperationsApiService,
  GetDoorPlanByIdPath,
  ListDoorPlanProfilesPath,
  ListDoorPlanProfilesQuery,
  RepairDoorPlanProfilePath,
  RepairDoorPlanProfileRqst,
  ScoDoorPlan,
} from '@xpo-ltl/sdk-dockoperations';

import { WeekDayHelper } from '@app/shared/classes/week-day-helper';
import { DeepLoadingDefaultFilterValues } from '@app/shared/enums/deep-loading-default-values.enum';
import { DoorPlanProfileHelper } from '@shared/classes/door-plan-profile-helper';
import { DataOptions } from '@xpo-ltl/data-api';
import { DoorPlanProfile } from '../../models/door-plan';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanProfileService {
  private _doorPlanProfilesSubject = new BehaviorSubject<DoorPlanProfile[]>([]);
  doorPlanProfiles$ = this._doorPlanProfilesSubject.asObservable();

  private _activeProfileSubject = new BehaviorSubject<DoorPlanProfile>(null);
  activeProfile$ = this._activeProfileSubject.asObservable();

  constructor(private dockOperationsApiService: DockOperationsApiService) {}

  setDoorPlanProfiles(doorPlanProfiles: DoorPlanProfile[]): void {
    const activeProfile = doorPlanProfiles.find((doorPlan) => !!doorPlan.isActive);
    this._activeProfileSubject.next(activeProfile);

    this._doorPlanProfilesSubject.next(this.sortProfiles(doorPlanProfiles));
  }

  getDoorPlanProfiles(): DoorPlanProfile[] {
    return this._doorPlanProfilesSubject.value;
  }

  getCurrentStaticDoorPlanProfile(): DoorPlanProfile {
    return this._doorPlanProfilesSubject.value.find((profile) => profile.isStaticDoorPlanProfile());
  }

  private sortProfiles(profileOptions: DoorPlanProfile[]) {
    let sortedOptions = profileOptions
      .filter((option) => option.dayOfWeekCd)
      .sort((a, b) => {
        let sortingOrder = WeekDayHelper.getWeekDayIndex(a.dayOfWeekCd) - WeekDayHelper.getWeekDayIndex(b.dayOfWeekCd);
        if (sortingOrder === 0) {
          sortingOrder = a.isActive ? -1 : 1;
        }
        return sortingOrder;
      });
    return (sortedOptions = [...sortedOptions, ...profileOptions.filter((value) => !value.dayOfWeekCd)]);
  }

  addDoorPlanProfile(doorPlanProfile: DoorPlanProfile): void {
    const doorPlanProfiles = this._doorPlanProfilesSubject.value || [];
    doorPlanProfiles.push(doorPlanProfile);
    this.setDoorPlanProfiles(doorPlanProfiles);
  }

  listDoorPlanProfiles(sicCd: string, shiftCd: ShiftCd, levelOfDetail?: string): Observable<DoorPlanProfile[]> {
    const path = new ListDoorPlanProfilesPath();
    path.sicCd = sicCd;
    const queryParams = new ListDoorPlanProfilesQuery();
    queryParams.shiftCd = shiftCd;
    queryParams.listInfo = new ListInfo();
    queryParams.listInfo.levelOfDetail = levelOfDetail || '';
    return this.dockOperationsApiService.listDoorPlanProfiles(path, queryParams).pipe(
      map((listDoorPlanProfilesResp) =>
        listDoorPlanProfilesResp.doorPlanProfiles.map((doorPlanProfile) => new DoorPlanProfile(doorPlanProfile))
      ),
      catchError((error) => throwError(error))
    );
  }

  activateDoorPlanProfile(dayOfWeek: string, sic: string, shift: ShiftCd): Observable<DoorPlanProfile> {
    const request = new CreateWeeklyDefaultDoorPlanRqst();
    request.dayOfWeekCd = dayOfWeek;
    request.activateDoorPlanInd = true;
    const path = new CreateWeeklyDefaultDoorPlanPath();
    path.sicCd = sic;
    path.shiftCd = shift;
    return this.dockOperationsApiService
      .createWeeklyDefaultDoorPlan(request, path)
      .pipe(map((response) => new DoorPlanProfile(response ? response.doorPlanProfile : null)));
  }

  createDoorPlanProfile(doorPlanProfile: DoorPlanProfile): Observable<DoorPlanProfile> {
    const request = new CreateDoorPlanProfileRqst();
    request.profileName = doorPlanProfile.profileName;
    request.profileTypeCd = DoorPlanProfileHelper.getKey(doorPlanProfile.profileTypeCd);

    const pathParams = new CreateDoorPlanProfilePath();
    pathParams.shiftCd = doorPlanProfile.shiftCd;
    pathParams.sicCd = doorPlanProfile.sicCd;

    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.dockOperationsApiService
      .createDoorPlanProfile(request, pathParams, dataOptions)
      .pipe(map((response) => new DoorPlanProfile(response ? response.doorPlanProfile : null)));
  }

  getRemovedStaticDoors(currentDoorPlanId: number, currentStaticProfileId: number): Observable<ScoDoorPlan[]> {
    const currentDoorPlanPath = new GetDoorPlanByIdPath();
    currentDoorPlanPath.doorPlanProfileId = currentDoorPlanId;
    const currentProfile$ = this.dockOperationsApiService.getDoorPlanById(currentDoorPlanPath);
    const currentStaticProfilePath = new GetDoorPlanByIdPath();
    currentStaticProfilePath.doorPlanProfileId = currentStaticProfileId;
    const currentStaticProfile$ = this.dockOperationsApiService.getDoorPlanById(currentStaticProfilePath);

    return combineLatest([currentStaticProfile$, currentProfile$]).pipe(
      map(([staticProfile, currentProfile]) => {
        const removedStaticDoorPlans: ScoDoorPlan[] = [];

        staticProfile.doorPlanProfile.scoDoorPlan.forEach((staticDoorPlanItem: ScoDoorPlan) => {
          const newDoorPlan = currentProfile.doorPlanProfile.scoDoorPlan.find((doorPlan) => {
            return doorPlan?.doorNbr === staticDoorPlanItem.doorNbr;
          });
          if (!newDoorPlan || (newDoorPlan && !newDoorPlan.staticMatchInd)) {
            removedStaticDoorPlans.push(staticDoorPlanItem);
          }
        });
        return removedStaticDoorPlans;
      })
    );
  }

  createLoadLanesByDockPlan(sic: string, shift: ShiftCd, planDate: string): Observable<void> {
    const path = new CreateLoadLanesByDockPlanPath();
    path.sicCd = sic;
    path.shiftCd = shift;
    const request = new CreateLoadLanesByDockPlanRqst();
    request.includeOptimalLoadLanesInd = true;
    request.includeDoorPlanInd = true;
    request.thresholdCubePct = parseInt(DeepLoadingDefaultFilterValues.DEFAULT_CUBE, 10);
    request.thresholdWeight = parseInt(DeepLoadingDefaultFilterValues.DEFAULT_WEIGHT, 10);
    request.includeShipmentsInd = true;
    request.remainingToLoadShipmentsInd = false;
    request.planDate = planDate;
    return this.dockOperationsApiService.createLoadLanesByDockPlan(request, path);
  }

  repairDoorPlanProfile(doorPlanProfileId: number): Observable<void> {
    const request = new RepairDoorPlanProfileRqst();
    const path = new RepairDoorPlanProfilePath();
    path.doorPlanProfileId = doorPlanProfileId;

    return this.dockOperationsApiService.repairDoorPlanProfile(request, path);
  }
}
