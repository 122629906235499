import { Door, ListLocationsResp } from '@xpo-ltl-2.0/sdk-location';
import { ActionCd, AuditInfo, MoveModeCd, ShiftCd } from '@xpo-ltl/sdk-common';
import {
  DockDoorPlan,
  DockDoorPlanDestination,
  DoorPreference,
  Equipment,
  LoadLane,
  LoadLaneDestination,
  ScoDoorPlan,
} from '@xpo-ltl/sdk-dockoperations';
import { differenceBy, forEach, isEmpty, keys } from 'lodash';

import { LocationHelper } from '@app/shared/classes/location-helper';
import { DoorPlanFieldKeys, DoorPlanPriorityEnum } from '@app/shared/enums/door-plan';
import { LaneTypeHelper } from '@shared/classes/lane-type-helper.class';
import { LaneTypeIcons } from '@shared/enums/lane-type.enum';
import { LoadLevelsEnum } from '@shared/enums/load-lane/load-levels.enum';
import { Route } from '../route.model';
import { DoorPlanDestination } from './door-plan-destination.model';
import { DoorPlanTrailerInfo } from './door-plan-trailer-info.model';
import { LoadLaneInfo } from './load-lane-info.model';

export class DoorPlanItem {
  doorPlanProfileId: number;
  doorNbr: string;
  displaySequenceNbr: string;
  priorityNbr: string;
  firstLoadPointSicCode: string;
  firstLoadPointShiftCd: ShiftCd;
  farthestLoadPointSicCode: string;
  farthestLoadPointShiftCd: ShiftCd;
  laneTypeCd: MoveModeCd;
  facSectorNbr: string;
  doorSectorNbr: string;
  dayFreightInd: boolean;
  cutOffTime: string;
  correlationId: string;
  scoEventId: number;
  listActionCd: ActionCd;
  auditInfo: AuditInfo;
  scoDoorPlanDestination: DoorPlanDestination[];
  isPure: boolean;
  searchText: string;
  trailerInfo?: Partial<DoorPlanTrailerInfo>;
  staticMatchInd: boolean;
  selected: boolean;
  /** Populated with deriveLoadLanesByDockPlan */
  loadLaneInfo?: Partial<LoadLaneInfo>;
  /** Populated with getListDockDoorPreferences */
  doorPreference?: Partial<DoorPreference>;
  /** Populated with listPnDRoutes */
  routes: Route[];
  headloadSicCd?: string;

  static createFromScoDoorPlan(scoDoorPlan?: ScoDoorPlan, allActiveSics?: ListLocationsResp): DoorPlanItem {
    if (!scoDoorPlan) {
      return;
    }
    const doorPlanItem = new DoorPlanItem();
    doorPlanItem.doorPlanProfileId = scoDoorPlan.doorPlanProfileId;
    doorPlanItem.doorNbr = scoDoorPlan.doorNbr;
    doorPlanItem.displaySequenceNbr = scoDoorPlan.displaySequenceNbr;
    doorPlanItem.priorityNbr = scoDoorPlan.priorityNbr ? scoDoorPlan.priorityNbr.toString() : '';
    doorPlanItem.firstLoadPointSicCode = scoDoorPlan.firstLoadPointSicCode;
    doorPlanItem.firstLoadPointShiftCd = scoDoorPlan.firstLoadPointShiftCd;
    doorPlanItem.farthestLoadPointSicCode = scoDoorPlan.farthestLoadPointSicCode;
    doorPlanItem.farthestLoadPointShiftCd = scoDoorPlan.farthestLoadPointShiftCd;
    doorPlanItem.laneTypeCd = scoDoorPlan.laneTypeCd;
    doorPlanItem.facSectorNbr = scoDoorPlan.facSectorNbr;
    doorPlanItem.doorSectorNbr = scoDoorPlan.doorSectorNbr;
    doorPlanItem.dayFreightInd = scoDoorPlan.dayFreightInd;
    doorPlanItem.cutOffTime = scoDoorPlan.cutOffTime;
    doorPlanItem.correlationId = scoDoorPlan.correlationId;
    doorPlanItem.scoEventId = scoDoorPlan.scoEventId;
    doorPlanItem.listActionCd = scoDoorPlan.listActionCd;
    doorPlanItem.auditInfo = scoDoorPlan.auditInfo;
    doorPlanItem.staticMatchInd = scoDoorPlan.staticMatchInd;
    doorPlanItem.scoDoorPlanDestination = (scoDoorPlan.scoDoorPlanDestination || []).map((destination) =>
      DoorPlanDestination.createFromScoDoorPlanDestination(destination)
    );
    doorPlanItem.isPure = this.isPureDoor(doorPlanItem, allActiveSics);
    doorPlanItem.searchText = this.createSearchText(doorPlanItem);
    doorPlanItem.doorPreference = {};
    return doorPlanItem;
  }

  static createEmptyDoorPlanItem(door?: Door): DoorPlanItem {
    const doorPlanEmpty = new DoorPlanItem();
    if (door) {
      doorPlanEmpty.displaySequenceNbr = door.displaySeqNbr.toString();
      doorPlanEmpty.doorNbr = door.doorNbrTxt.toString();
      doorPlanEmpty.doorSectorNbr = door.sectorId.toString();
      doorPlanEmpty.doorPreference = {};
    }
    doorPlanEmpty.searchText = this.createSearchText(doorPlanEmpty);
    return doorPlanEmpty;
  }

  static createFromLoadLane(loadLane?: LoadLane): DoorPlanItem {
    const doorPlanItem = new DoorPlanItem();
    if (loadLane) {
      const reduceDestinationProperty = (propertyName: string): number => {
        const total = loadLane.destinations.reduce((acc, destination) => {
          acc += destination[propertyName];
          return acc;
        }, 0);
        return total;
      };

      if (loadLane.doorPlan) {
        doorPlanItem.doorNbr = loadLane.doorPlan.doorNbr;
        doorPlanItem.displaySequenceNbr = loadLane.doorPlan.displaySequenceNbr;
        doorPlanItem.doorSectorNbr = loadLane.doorPlan.doorSectorNbr;
      }
      doorPlanItem.firstLoadPointSicCode = loadLane.moveToSicCd;
      doorPlanItem.farthestLoadPointSicCode = loadLane.closeToSicCd;
      doorPlanItem.headloadSicCd = loadLane.headloadSicCd;
      doorPlanItem.scoDoorPlanDestination = (loadLane.destinations || []).map((destination) => {
        const loadLaneDestination = DoorPlanDestination.createFromLoadLaneDestination(destination);
        // If loadLane has a doorPlan associated we should use his doorNbr in the destinations
        if (doorPlanItem.doorNbr) {
          loadLaneDestination.doorNbr = doorPlanItem.doorNbr;
        }
        return loadLaneDestination;
      });
      doorPlanItem.searchText = this.createSearchText(doorPlanItem);
      doorPlanItem.loadLaneInfo = {
        totalWeightLane: loadLane.totalWeight,
        totalCubeLane: loadLane.totalCubePct,
        loadLevel: loadLane.loadLevel as LoadLevelsEnum,
        totalBillCountLane: loadLane.shipmentCount,
        totalMMLane: reduceDestinationProperty('totalPlannedMmCount'),
        remainingMMLane: reduceDestinationProperty('remainingMmCount'),
        remainingBillCountLane: reduceDestinationProperty('remainingToLoadShipmentCount'),
        remainingCubeLane: reduceDestinationProperty('remainingToLoadCubePct'),
        remainingWeightLane: reduceDestinationProperty('remainingToLoadWeight'),
        pupCount: loadLane.pupCount,
      };
    }
    return doorPlanItem;
  }

  private static createSearchText(doorPlanItem: DoorPlanItem): string {
    if (!doorPlanItem || doorPlanItem.isEmpty()) {
      return `${doorPlanItem.doorNbr} EMPTY`;
    }
    // Add MoveTo and CloseTo
    let searchText = `${doorPlanItem.doorNbr} ${doorPlanItem.firstLoadPointSicCode} -> ${doorPlanItem.farthestLoadPointSicCode}`;
    // Add priority
    searchText += ` ${doorPlanItem.getPriorityIndicator()}`;
    // Add Pure flag
    if (doorPlanItem.isPure) {
      searchText += ' PURE';
    }
    // Add Lane Code
    searchText += ` ${LaneTypeHelper.getLaneApp(doorPlanItem.laneTypeCd)}`;
    return searchText;
  }

  private static isPureDoor(
    doorPlanItem: DoorPlanItem,
    { locationSic }: ListLocationsResp = new ListLocationsResp()
  ): boolean {
    let isPure = false;
    const destinations = doorPlanItem ? doorPlanItem.scoDoorPlanDestination.slice() : [];
    if (!isEmpty(locationSic) && !isEmpty(destinations) && destinations.length < 15) {
      isPure = true;

      const existFarthestSicInDestinations =
        destinations.findIndex(
          (destination) => destination.destinationSicCode === doorPlanItem.farthestLoadPointSicCode
        ) >= 0;

      // If farthestSic is not in destinations, we should add it
      if (!existFarthestSicInDestinations) {
        const farthestDestination = new DoorPlanDestination();
        farthestDestination.destinationSicCode = doorPlanItem.farthestLoadPointSicCode;
        destinations.push(farthestDestination);
      }

      if (destinations.length > 1) {
        const values = {};
        let parents: number = 0;

        forEach(destinations, (destination) => {
          const parentSatelliteSic = LocationHelper.getLastParentSatelliteSicCd(destination.destinationSicCode, {
            locationSic,
          });
          if (parentSatelliteSic) {
            values[parentSatelliteSic] = true;
          } else {
            parents++;
          }

          if (parents > 1 || keys(values).length > 1) {
            isPure = false;
            return false;
          }
        });
      }
    }

    return isPure;
  }

  get hashCode(): string {
    return `${this.doorPlanProfileId ?? ''}${this.displaySequenceNbr ?? ''}${this.auditInfo?.updatedTimestamp ?? ''}`;
  }

  get destinationsList(): string {
    let destinations: string = '';
    if (this.scoDoorPlanDestination) {
      destinations = this.scoDoorPlanDestination.reduce((destinationCodes, destination, index, destinationArray) => {
        destinationCodes += destination.destinationSicCode;
        return index < destinationArray.length - 1 ? (destinationCodes += ', ') : destinationCodes;
      }, '');
    }
    return destinations;
  }

  isEmpty(): boolean {
    return !this.scoDoorPlanDestination || !this.scoDoorPlanDestination.length;
  }

  /**
   * Returns priority indicator for a DoorPlanItem.
   * When DoorPlanItem[] param is provided, returns P1 indicator only if a priority 2 copy of the plan
   * is found in the array.
   */
  getPriorityIndicator(doorPlanArray?: DoorPlanItem[]): string {
    if (this.priorityNbr === '2') {
      return DoorPlanPriorityEnum.P2;
    } else if (
      this.priorityNbr === '1' &&
      (isEmpty(doorPlanArray) || this.isPriorityTwoOnSameDoorPlan(doorPlanArray))
    ) {
      return DoorPlanPriorityEnum.P1;
    }
  }

  private isPriorityTwoOnSameDoorPlan(doorPlanArray: DoorPlanItem[]): boolean {
    const potentialMatches: DoorPlanItem[] = doorPlanArray.filter((doorPlan) => {
      return doorPlan.doorNbr !== this.doorNbr && this.hasSameMoveToCloseToAs(doorPlan);
    });
    if (!isEmpty(potentialMatches)) {
      for (const match of potentialMatches) {
        if (this.hasSameDestinationsAs(match)) {
          return true;
        }
      }
      return false;
    }
  }

  private hasSameMoveToCloseToAs(doorPlan: DoorPlanItem): boolean {
    return (
      doorPlan.firstLoadPointSicCode === this.firstLoadPointSicCode &&
      doorPlan.firstLoadPointShiftCd === this.firstLoadPointShiftCd &&
      doorPlan.farthestLoadPointShiftCd === this.farthestLoadPointShiftCd &&
      doorPlan.farthestLoadPointSicCode === this.farthestLoadPointSicCode
    );
  }

  private hasSameDestinationsAs(doorPlan: DoorPlanItem): boolean {
    const difference: DoorPlanDestination[] = differenceBy(
      doorPlan.scoDoorPlanDestination,
      this.scoDoorPlanDestination,
      DoorPlanFieldKeys.DESTINATION_SC
    );
    return isEmpty(difference);
  }

  getLoadLevelOrder(): number {
    if (!this.loadLaneInfo?.loadLevel) {
      return -1;
    }
    switch (this.loadLaneInfo.loadLevel) {
      case LoadLevelsEnum.FINAL_DEST:
        return 0;
      case LoadLevelsEnum.FIRST_FAC:
        return 1;
      case LoadLevelsEnum.SECOND_FAC:
        return 2;
      case LoadLevelsEnum.THIRD_FAC:
        return 3;
      case LoadLevelsEnum.FOURTH_FAC:
        return 4;
    }
  }

  getLaneTypeIcon(): string {
    switch (this.laneTypeCd) {
      case MoveModeCd.SUPPLEMENTAL:
        return LaneTypeIcons.SUPPLEMENTAL;
      case MoveModeCd.EXCLUSIVE:
        return LaneTypeIcons.EXCLUSIVE;
    }
  }

  updateFromLoadLaneDestinations(loadLanesDestinations: LoadLaneDestination[]): void {
    if (!loadLanesDestinations?.length) {
      this.loadLaneInfo = {};
      return;
    }

    const reduceDestinationProperty = (propertyName: string): number => {
      const total = this.scoDoorPlanDestination.reduce((acc, destination) => {
        acc += (destination.loadLaneInfo && destination.loadLaneInfo[propertyName]) || 0;
        return acc;
      }, 0);
      return total;
    };

    this.scoDoorPlanDestination.forEach((destination) => {
      const loadLaneDestination = loadLanesDestinations.find((llDestination) =>
        destination.matchDestination(llDestination)
      );
      if (loadLaneDestination) {
        destination.updateFromLoadLaneDestination(loadLaneDestination);
      }
    });
    this.loadLaneInfo = {
      totalWeightLane: reduceDestinationProperty('totalWeightLane'),
      totalCubeLane: reduceDestinationProperty('totalCubeLane'),
      totalBillCountLane: reduceDestinationProperty('totalBillCountLane'),
      totalMMLane: reduceDestinationProperty('totalMMLane'),
      remainingMMLane: reduceDestinationProperty('remainingMMLane'),
      remainingBillCountLane: reduceDestinationProperty('remainingBillCountLane'),
      remainingCubeLane: reduceDestinationProperty('remainingCubeLane'),
      remainingWeightLane: reduceDestinationProperty('remainingWeightLane'),
    };
  }

  addDoorPreferences(doorPreference: DoorPreference): void {
    this.doorPreference = doorPreference || {};
  }

  addTrailerInfo(equipment: Equipment, selectedSic: string): void {
    if (equipment) {
      const isEnrouteStatus = !!(
        equipment.currentSic === selectedSic &&
        equipment.trailerLoad?.originSic !== selectedSic &&
        equipment.trailerLoad?.currentStatus === 'CLOS'
      );

      this.trailerInfo = { equipment, isEnrouteStatus };
    } else {
      this.trailerInfo = {};
    }
  }

  addRouteInfo(routes: Route[]): void {
    if (routes?.length) {
      this.routes = routes;
    }
  }

  isEven(): boolean {
    return Number(this.displaySequenceNbr) % 2 === 0;
  }

  isOdd(): boolean {
    return Number(this.displaySequenceNbr) % 2 !== 0;
  }

  getDockDoorPlan(): DockDoorPlan {
    const dockDoorPlan = new DockDoorPlan();
    dockDoorPlan.priorityNbr = this.priorityNbr;
    dockDoorPlan.moveToSicCd = this.firstLoadPointSicCode;
    dockDoorPlan.closeToSicCd = this.farthestLoadPointSicCode;
    dockDoorPlan.cutOffTime = this.cutOffTime;
    dockDoorPlan.doorNbr = this.doorNbr;
    dockDoorPlan.doorPlanDestinations = this.getDockDoorPlanDestinations();

    return dockDoorPlan;
  }

  getDockDoorPlanDestinations(mapAllInformationInd = true): DockDoorPlanDestination[] {
    if (!this.scoDoorPlanDestination?.length) {
      return;
    }
    return this.scoDoorPlanDestination.map((destination) => {
      const dockDoorPlanDestination = new DockDoorPlanDestination();
      dockDoorPlanDestination.destinationSicCode = destination.destinationSicCode;
      if (mapAllInformationInd) {
        dockDoorPlanDestination.destinationPostalCd = destination.destinationPostalCd;
        dockDoorPlanDestination.legacyCisCustomerNbr = destination.legacyCisCustomerNbr;
        dockDoorPlanDestination.customerLocationFuncId = destination.customerLocationFuncId;
      }
      return dockDoorPlanDestination;
    });
  }
}
