import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { XpoContextMenuComponent } from '@app/context-menu/context-menu.component';
import { DoorPlanActionsEnum } from '@app/shared/enums/door-plan';
import { MenuOptions } from '@app/shared/interfaces/menu-options.interface';
import { DoorPlanItem, GTAction } from '@app/shared/models/door-plan';
import { ActiveSic } from '@xpo-ltl-2.0/sdk-location';

@Component({
  selector: 'app-requirements-context-menu',
  templateUrl: './requirements-context-menu.component.html',
  styleUrls: ['./requirements-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequirementsContextMenuComponent {
  @Input()
  doorPlanItems: DoorPlanItem[];
  @Output()
  itemSelected = new EventEmitter<GTAction>();

  menuOptions: MenuOptions = {};

  @ViewChild(XpoContextMenuComponent, { static: true }) contextMenu: XpoContextMenuComponent<any>;
  @ViewChild('contextMenuAddUpdatePlanTpl', { static: true }) contextMenuAddUpdatePlanTpl: TemplateRef<any>;

  openMenu(event: MouseEvent, destinationSicCodes: string[], moveTo?: string, closeTo?: string) {
    this.menuOptions = {
      template: this.contextMenuAddUpdatePlanTpl,
    };
    this.contextMenu.openMenu(event, { destinations: destinationSicCodes, moveTo, closeTo });
  }

  onItemClick(context: any, emptyDoor: DoorPlanItem): void {
    const action = DoorPlanActionsEnum.PERFORM_ADD;
    let destinationSicCodes;
    let closeToSic;
    let moveToSic;
    if (context?.destinations) {
      destinationSicCodes = context.destinations;
    }
    if (context?.moveTo) {
      const activeMoveTo = new ActiveSic();
      activeMoveTo.sicCd = context.moveTo;
      moveToSic = activeMoveTo;
    }
    if (context?.closeTo) {
      const activeCloseTo = new ActiveSic();
      activeCloseTo.sicCd = context.closeTo;
      closeToSic = activeCloseTo;
    }

    const gtAction: GTAction = {
      action,
      data: {
        firstRow: emptyDoor,
        additionalData: {
          destinationsArray: destinationSicCodes,
          moveTo: moveToSic,
          closeTo: closeToSic,
        },
      },
      errorOverrideInd: true,
    };
    this.itemSelected.emit(gtAction);
  }
}
