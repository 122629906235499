import { Injectable } from '@angular/core';

import {  LocationSic } from '@xpo-ltl-2.0/sdk-location';
import { find, map } from 'lodash';

import { AppCacheKeys } from '@app/shared/enums/app-cache-keys.enum';
import { AppCacheService } from '../cache-service/cache.service';

@Injectable({
  providedIn: 'root',
})
export class SicService {
  constructor(private cacheService: AppCacheService) {}
  /** Takes an array of strings that represent SIC codes and returns an array of corresponding ActiveSic
   * completing missing data when possible   */
  completeSicInformation(sicList: string[]): LocationSic[] {
    const activeSics: LocationSic[] = this.cacheService.getCacheValue(AppCacheKeys.ACTIVE_SICS).locationSic;
    const newSicList: LocationSic[] = map(sicList, (sic) => {
      const newSic = find(activeSics, (activeSic) => activeSic.sicCd === sic);
      if (newSic) {
        return newSic;
      } else {
        const newestSic = new LocationSic();
        newestSic.sicCd = sic;
        return newestSic;
      }
    });
    return newSicList;
  }
}
