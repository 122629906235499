import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { DynamicFilterFields } from '@shared/enums/door-plan/dynamic-filter-fields.enum';

@Pipe({
  name: 'sicControlsFilter',
})
export class SicControlsPipe implements PipeTransform {
  transform(controls: AbstractControl[], criteria: string): AbstractControl[] {
    if (criteria) {
      criteria = criteria.toLocaleLowerCase();

      return controls.filter(({ value }) => {
        let itemValue = value[DynamicFilterFields.SIC_CD];
        itemValue += ` - ${value[DynamicFilterFields.CITY] || ''}`;
        itemValue += `, ${value[DynamicFilterFields.STATE] || ''}`;

        return itemValue.toLocaleLowerCase().includes(criteria);
      });
    }

    return controls;
  }
}
