import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DoorPlanItem } from '@shared/models/door-plan';

@Component({
  selector: 'app-load-lane-card',
  templateUrl: './load-lane-card.component.html',
  styleUrls: ['./load-lane-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'load-lane-card',
  },
})
export class LoadLaneCardComponent implements OnInit {
  @Input() deepLoad: boolean = true;
  @Input() loadLane: DoorPlanItem;
  @Output()
  addDoorMenu = new EventEmitter<MouseEvent>();
  destinationsString = '';

  private _isPanelExpanded = new BehaviorSubject<boolean>(false);
  isPanelExpanded$ = this._isPanelExpanded.asObservable();
  constructor() {}

  ngOnInit(): void {
    this.destinationsString = this.loadLane?.destinationsList || '';
  }

  onAddDoorClicked(event: MouseEvent): void {
    this.addDoorMenu.emit(event);
  }

  onOpened(): void {
    this._isPanelExpanded.next(true);
  }

  onClosed(): void {
    this._isPanelExpanded.next(false);
  }
}
