import { Injectable } from '@angular/core';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export interface CdtState {
  profileId?: number;
  sicCd: string;
  shiftCd: ShiftCd;
  period: string;
  changes?: CdtStateChangeType[];
  refresh?: boolean;
}
export enum CdtStateChange {
  shift = 'shift',
  period = 'period',
  sic = 'sic',
  profile = 'profile',
}
type CdtStateChangeType = CdtStateChange.period | CdtStateChange.shift | CdtStateChange.sic | CdtStateChange.profile;

@Injectable({
  providedIn: 'root',
})
export class CdtStateService {
  private static readonly InitialState: CdtState = <CdtState>{
    // Temporary until endpoints upports all Shifts
    shiftCd: ShiftCd.INBOUND,
  };

  private readonly crossDockStateSource = new BehaviorSubject<CdtState>(CdtStateService.InitialState);
  readonly filtersState$ = this.crossDockStateSource.asObservable().pipe(
    distinctUntilChanged(
      (prev, curr) =>
        prev.sicCd === curr.sicCd &&
        prev.shiftCd === curr.shiftCd &&
        prev.profileId === curr.profileId &&
        prev.period === curr.period &&
        !curr.refresh
    ),
    map((state) => {
      // Refresh should last only one change
      state.refresh = false;
      return state;
    }),
    shareReplay()
  );

  constructor() {}

  refresh(): void {
    this.crossDockStateSource.next({ ...this.crossDockState, refresh: true });
  }

  private get crossDockState(): CdtState {
    return this.crossDockStateSource.getValue();
  }

  get sicCd(): string {
    return this.crossDockState.sicCd;
  }
  get shiftCd(): string {
    return this.crossDockState.shiftCd;
  }
  get profileId(): number {
    return this.crossDockState.profileId;
  }
  get period(): string {
    return this.crossDockState.period;
  }

  /**
   * Subscribe to specific state change
   * @param stateProperty
   * @param callback
   */
  onStateChange(stateProperty: CdtStateChangeType, callback: Function) {
    this.filtersState$.subscribe((data) => {
      if (data.changes?.some((change) => change === stateProperty)) {
        callback({
          [stateProperty]: data[stateProperty],
        });
      }
    });
  }

  /**
   * Set a brand new state
   */
  setState(state: Partial<CdtState>): void {
    this.crossDockStateSource.next({
      ...this.crossDockState,
      ...state,
      changes: [CdtStateChange.sic, CdtStateChange.shift, CdtStateChange.period, CdtStateChange.profile],
    });
  }

  /**
   * Change location in state
   * @param value
   */
  setSic(value: string): void {
    this.crossDockStateSource.next({ ...this.crossDockState, sicCd: value, changes: [CdtStateChange.sic] });
  }

  /**
   * Change Shift in state
   * @param value
   */
  setShift(value: ShiftCd): void {
    this.crossDockStateSource.next({ ...this.crossDockState, shiftCd: value, changes: [CdtStateChange.shift] });
  }

  /**
   * Change Period in state
   * @param value
   */
  setPeriod(value: string): void {
    this.crossDockStateSource.next({ ...this.crossDockState, period: value, changes: [CdtStateChange.period] });
  }

  /**
   * Change Period in state
   * @param value
   */
  setProfile(value: number): void {
    this.crossDockStateSource.next({
      ...this.crossDockState,
      profileId: value,
      changes: [CdtStateChange.profile],
    });
  }

  private resetState(): void {
    this.crossDockStateSource.next({ ...CdtStateService.InitialState });
  }
}
