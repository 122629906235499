import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { XpoConfirmDialog, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { MoreInfo } from '@xpo-ltl/sdk-common';
import _ from 'lodash';

import { ActionsService } from '../../classes/actions-service';
import { LoadLaneSummaryActionsEnum } from '../../enums/load-lane/load-lane-summary-actions.enum';
import { ActionsServiceEnum } from '../../enums/service-actions.enum';
import { GTAction, GTActionData } from '../../models/door-plan/grids-toolbar-actions.model';
import { CurrentDoorPlanProfileService } from '../current-door-plan-profile/current-door-plan-profile.service';
import { LoadLaneSummaryService } from '../load-lane-summary';

@Injectable({
  providedIn: 'root',
})
export class LoadLaneSummaryActionsService extends ActionsService {
  constructor(
    _confirmDialog: XpoConfirmDialog,
    _snackBar: XpoSnackBar,
    _dialog: MatDialog,
    private loadLaneSummaryService: LoadLaneSummaryService,
    private currentDoorPlanProfile: CurrentDoorPlanProfileService
  ) {
    super(_confirmDialog, _snackBar, _dialog);
  }

  handleActionChange(gridsToolbarAction: GTAction): void {
    switch (gridsToolbarAction.action) {
      case LoadLaneSummaryActionsEnum.PERFORM_ADD:
        this.onAddEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case LoadLaneSummaryActionsEnum.PERFORM_UPDATE:
        this.onUpdateEnded(
          gridsToolbarAction.data,
          gridsToolbarAction.errorOverrideInd,
          gridsToolbarAction.showSuccessMessage
        );
        break;
      case ActionsServiceEnum.ACTION_ENDED:
        this.setActionInProgress(false);
        break;
      case ActionsServiceEnum.ACTION_CANCELED:
        this.setActionInProgress(false);
        break;
    }
  }

  getToastMessageByAction(actionData: GTActionData, action: LoadLaneSummaryActionsEnum): string {
    let doorSectorNumberFrom: string;
    let doorSectorNumberTo: string;
    let toastMessage: string = '';

    switch (action) {
      case LoadLaneSummaryActionsEnum.PERFORM_ADD:
        toastMessage = `Door ${actionData.secondRow.doorNbr} has been created`;
        break;
      case LoadLaneSummaryActionsEnum.PERFORM_UPDATE:
        doorSectorNumberFrom = `${actionData.firstRow.doorSectorNbr} - ` + `${actionData.firstRow.doorNbr}`;
        doorSectorNumberTo =
          `${actionData.secondRow.doorSectorNbr} - ` + `${(actionData as GTActionData).secondRow.doorNbr}`;
        toastMessage = `Door ${doorSectorNumberFrom} Plan has been moved to Door ${doorSectorNumberTo} Plan`;
        break;
    }

    return toastMessage;
  }

  showModalWarning(moreInfo: MoreInfo[], action?: LoadLaneSummaryActionsEnum): void {
    let displayedModal: boolean;

    if (action === LoadLaneSummaryActionsEnum.PERFORM_ADD) {
      displayedModal = this.showTrailerloadingDialog(moreInfo);
    }

    if (!displayedModal) {
      this.showMisloadWarning(moreInfo);
    }
  }

  private onUpdateEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    this.loadLaneSummaryService
      .updateDoorToLane(doorPlanProfileId, actionData.firstRow.doorNbr, actionData.secondRow.doorNbr, errorOverrideInd)
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              this.getToastMessageByAction(actionData, LoadLaneSummaryActionsEnum.PERFORM_UPDATE)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            data: null,
          });
        },
        (error) => {
          this.onActionsError(error, LoadLaneSummaryActionsEnum.PERFORM_UPDATE);
        }
      );
  }

  private onAddEnded(actionData: GTActionData, errorOverrideInd: boolean, showSuccessMessage = true): void {
    const doorPlanProfileId = this.currentDoorPlanProfile.getCurrentDoorPlanProfileId();
    this.loadLaneSummaryService
      .addDoorToLane(doorPlanProfileId, actionData.secondRow.doorNbr, actionData.firstRow, errorOverrideInd)
      .subscribe(
        () => {
          if (showSuccessMessage) {
            this.showActionSuccessToast(
              this.getToastMessageByAction(actionData, LoadLaneSummaryActionsEnum.PERFORM_ADD)
            );
          }
          this.setActionPerformed({
            action: ActionsServiceEnum.ACTION_ENDED,
            previousAction: LoadLaneSummaryActionsEnum.PERFORM_ADD,
            data: null,
          });
        },
        (error) => {
          this.onActionsError(error, LoadLaneSummaryActionsEnum.PERFORM_ADD);
        }
      );
  }
}
