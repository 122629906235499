import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ListFilteredDoorplanBeacons, ListFilteredDoorplanBeaconsResp } from '@xpo-ltl/sdk-beacon';
import { DoorPlanRecommendation, ListDoorPlanRecommendationsResp } from '@xpo-ltl/sdk-dockoperations';
import { groupBy } from 'lodash';
import moment from 'moment';
import { distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs/operators';

import { StaticPlanRecommendationTypes } from '@app/door-plan-common/enums/static-plan-recommendations/static-plan-recommendation-types.enum';
import { AppCacheKeys } from '@app/shared/enums/app-cache-keys.enum';
import { DoorPlanActionsEnum } from '@app/shared/enums/door-plan';
import { ActionsServiceEnum } from '@app/shared/enums/service-actions.enum';
import { DoorPlanItem, GTAction } from '@app/shared/models/door-plan';
import { BeaconService } from '@app/shared/services/beacon-service/beacon.service';
import { CurrentDoorPlanProfileService } from '@app/shared/services/current-door-plan-profile/current-door-plan-profile.service';
import { DoorPlanActionsService } from '@app/shared/services/door-plan-actions/door-plan-actions.service';
import { DoorPlanDataSourceService } from '@app/shared/services/door-plan-data-source/door-plan-data-source.service';
import { DoorPlanService } from '@app/shared/services/door-plan-service/door-plan.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { combineLatest, Observable, of } from 'rxjs';
import { RequirementsContextMenuComponent } from '../requirements-context-menu/requirements-context-menu.component';

export interface GroupedRepairs {
  recommendationTypeCd: string;
  recommendations: ListFilteredDoorplanBeacons[];
  profileId: number;
}
export interface GroupedRecommendations {
  recommendationTypeCd: string;
  recommendations: DoorPlanRecommendation[];
}
@Component({
  selector: 'app-static-door-recommendations',
  templateUrl: './static-door-recommendations.component.html',
  styleUrls: ['./static-door-recommendations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'static-door-recommendations',
  },
})
export class StaticDoorRecommendationsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  profileId: number;
  doorPlanRecommendations: GroupedRecommendations[];
  doorPlanRequirements: DoorPlanRecommendation[] = [];
  doorPlanFixes: GroupedRepairs[] = [];
  disableRecommendations: boolean = true;
  isLoading = false;
  beacons: any[];
  emptyDoors: DoorPlanItem[] = [];
  tabIndex = 0;

  @Output()
  requirementsUpdated = new EventEmitter<DoorPlanRecommendation[]>();

  @ViewChild(RequirementsContextMenuComponent) requirementsContextMenu: RequirementsContextMenuComponent;
  @ViewChild('tabs') tabGroup: MatTabGroup;

  constructor(
    private currentProfileService: CurrentDoorPlanProfileService,
    private beaconService: BeaconService,
    private doorPlanService: DoorPlanService,
    private doorPlanDataSourceService: DoorPlanDataSourceService,
    private doorPlanActionsService: DoorPlanActionsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.profileId = this.currentProfileService.getCurrentDoorPlanProfileId();
    this.getFixesAndRequirements();
    this.getEmptyDoors();
    this.refreshRequirementsOnProfileChange();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private mapBeacons(beacons: ListFilteredDoorplanBeacons[]): GroupedRepairs[] {
    const groupedBeacons = groupBy(beacons, 'owner.activityName');
    const groupedRecommendations: GroupedRepairs[] = [];
    Object.keys(groupedBeacons).forEach((key) => {
      const newGroupedBeacons: GroupedRepairs = {
        recommendationTypeCd: key,
        recommendations: groupedBeacons[key],
        profileId: this.profileId,
      };
      groupedRecommendations.push(newGroupedBeacons);
    });
    return groupedRecommendations;
  }

  private requirements$(): Observable<ListDoorPlanRecommendationsResp> {
    if (this.requirementsSolved()) {
      const emptyResponse = new ListDoorPlanRecommendationsResp();
      emptyResponse.doorPlanRecommendations = [];
      return of(emptyResponse);
    } else {
      return this.doorPlanService.listDoorPlanRecommendations(this.profileId, true).pipe(
        tap((resp) => {
          this.checkRequirements(resp);
        })
      );
    }
  }

  private beacons$(): Observable<ListFilteredDoorplanBeaconsResp> {
    const today = moment
      .utc()
      .hours(0)
      .minute(0)
      .seconds(0)
      .toDate();
    return this.beaconService.getStaticRepairBeaconMessages(this.profileId, today);
  }

  getFixesAndRequirements() {
    combineLatest([this.beacons$(), this.requirements$()])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([beaconResp, requirements]) => {
        this.doorPlanFixes = this.mapBeacons(beaconResp.beacons);
        this.doorPlanRequirements = this.filterRequirements(requirements);
        this.requirementsUpdated.emit(this.doorPlanRequirements);
        if (this.doorPlanRequirements.length === 0) {
          this.disableRecommendations = false;
          this.getRecommendations();
        }
        if (this.allAutoFixesConfirmed() && this.doorPlanRequirements.length < 1) {
          this.switchToRecommendationsTab();
        }
      });
  }

  private allAutoFixesConfirmed(): boolean {
    const unConfirmedFixes = this.doorPlanFixes.filter(
      (fix) =>
        !this.localStorageService.getValue(
          `${AppCacheKeys.CONFIRMED_FIX}-${fix?.profileId}-${fix.recommendationTypeCd}`
        )
    );
    return unConfirmedFixes?.length < 1;
  }

  onFixConfirmed() {
    if (this.allAutoFixesConfirmed() && this.doorPlanRequirements.length < 1) {
      this.switchToRecommendationsTab();
    }
  }

  private checkRequirements(
    requirementsResp: ListDoorPlanRecommendationsResp
  ): Observable<ListDoorPlanRecommendationsResp> {
    if (requirementsResp.doorPlanRecommendations.length === 0) {
      this.disableRecommendations = false;
      this.getRecommendations();
      this.localStorageService.setLocalStorage(`${AppCacheKeys.REQUIREMENTS}-${this.profileId}`, {
        value: true,
        expiration: moment().endOf('day'),
      });
    }
    return of(requirementsResp);
  }

  private filterRequirements(requirements: ListDoorPlanRecommendationsResp): DoorPlanRecommendation[] {
    return requirements.doorPlanRecommendations.filter(
      (recommendation) =>
        recommendation.recommendationTypeCd === StaticPlanRecommendationTypes.INVALID_LANE ||
        recommendation.recommendationTypeCd === StaticPlanRecommendationTypes.MISSING_DESTINATIONS
    );
  }

  private getRecommendations(): void {
    this.isLoading = true;
    this.doorPlanService
      .listDoorPlanRecommendations(this.profileId, false)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(
        (response) => {
          this.doorPlanRecommendations = this.mapRecommendations(response.doorPlanRecommendations);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  private mapRecommendations(beacons: DoorPlanRecommendation[]): GroupedRecommendations[] {
    const groupedBeacons = groupBy(beacons, 'recommendationTypeCd');
    const groupedRecommendations: GroupedRecommendations[] = [];
    Object.keys(groupedBeacons).forEach((key) => {
      const newGroupedBeacons: GroupedRecommendations = {
        recommendationTypeCd: key,
        recommendations: groupedBeacons[key],
      };
      groupedRecommendations.push(newGroupedBeacons);
    });
    return groupedRecommendations;
  }

  private requirementsSolved(): boolean {
    return this.localStorageService.getValue(`${AppCacheKeys.REQUIREMENTS}-${this.profileId}`);
  }

  private refreshRequirementsOnProfileChange(): void {
    this.doorPlanService.doorPlanProfileUpdated$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((response) => {
        this.switchToRequirementsTab();
        this.disableRecommendations = true;
        this.profileId = response?.doorPlanProfileId;
        this.getFixesAndRequirements();
        this.getEmptyDoors();
      });
  }

  private getEmptyDoors(): void {
    this.doorPlanDataSourceService.unfilteredDoorPlans$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((emptyDoors) => {
        this.emptyDoors = emptyDoors
          .filter(
            (door) => door.isEmpty() && !door.doorPreference?.stepSaverExcludeInd && !door.doorPreference?.trapInd
          )
          .sort((a, b) => Number(a.doorNbr) - Number(b.doorNbr));
      });
  }

  private removeUpdatedDoor(action: GTAction): void {
    this.emptyDoors = this.emptyDoors.filter(
      (emptyDoor) => emptyDoor.displaySequenceNbr !== action.data.firstRow.displaySequenceNbr
    );
  }

  private switchToRecommendationsTab(): void {
    if (this.tabGroup) {
      this.tabGroup.selectedIndex = 1;
    }
    this.tabIndex = 1;
  }
  private switchToRequirementsTab(): void {
    if (this.tabGroup) {
      this.tabGroup.selectedIndex = 0;
    }
    this.tabIndex = 0;
  }

  onItemSelected(action: GTAction): void {
    this.doorPlanActionsService.setActionInProgress(true);
    this.doorPlanActionsService.setActionPerformed(action);

    this.doorPlanActionsService.actionPerformed$
      .pipe(
        filter(
          (performedAction: GTAction) =>
            !!performedAction &&
            performedAction.action === ActionsServiceEnum.ACTION_ENDED &&
            performedAction.previousAction === DoorPlanActionsEnum.PERFORM_ADD
        ),
        take(1),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {
        this.removeUpdatedDoor(action);
      });
  }

  onAddDoorMenu(recommendation: any): void {
    recommendation.event.stopPropagation();
    this.requirementsContextMenu.openMenu(recommendation.event, recommendation.destinations);
  }

  onAddDeepLoadingMenu(recommendation: any): void {
    recommendation.event.stopPropagation();

    this.requirementsContextMenu.openMenu(
      recommendation.event,
      recommendation.loadLane?.destinationSicCodes,
      recommendation.loadLane?.moveToSicCode,
      recommendation.loadLane?.closeToSicCode
    );
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
  }
}
