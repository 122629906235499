export enum DoorPlanActionsEnum {
  COPY_TRIGGERED = 'COPY_TRIGGERED',
  MOVE_TRIGGERED = 'MOVE_TRIGGERED',
  MERGE_TRIGGERED = 'MERGE_TRIGGERED',
  PERFORM_COPY = 'PERFORM_COPY',
  PERFORM_MOVE = 'PERFORM_MOVE',
  PERFORM_MERGE = 'PERFORM_MERGE',
  PERFORM_SWAP = 'PERFORM_SWAP',
  PERFORM_RESET = 'PERFORM_RESET',
  PERFORM_REMOVE = 'PERFORM_REMOVE',
  PERFORM_ADD = 'PERFORM_ADD',
  PERFORM_UPDATE = 'PERFORM_UPDATE',
  ACTIVATE_PROFILE = 'ACTIVATE_PROFILE',
  REMOVE_TRIGGERED = 'REMOVE_TRIGGERED',
  MAKE_STATIC = 'MARK_STATIC',
  REMOVE_STATIC = 'UNMARK_STATIC',
  ASSIGN_TRAILER = 'ASSIGN_TRAILER',
  UPDATE_TRAILER = 'UPDATE_TRAILER',
  EXCLUDE_FROM_STEP_SAVER = 'EXCLUDE_FROM_STEP_SAVER',
  HOLD_FOR_NEXT_SHIFT = 'HOLD_FOR_NEXT_SHIFT',
  MARK_UNLOAD = 'MARK_UNLOAD',
  REMOVE_EXCLUSION = 'REMOVE_EXCLUSION',
  REMOVE_UNLOAD = 'REMOVE_UNLOAD',
  REMOVE_HOLD = 'REMOVE_HOLD',
  EMBARGO_DOOR = 'EMBARGO_DOOR',
  REMOVE_EMBARGO = 'REMOVE_EMBARGO',
}
