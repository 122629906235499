import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FreightFlow, Leg, LoadLeg } from '@xpo-ltl-2.0/sdk-freightflow';
import { MoveModeCd } from '@xpo-ltl/sdk-common';
import { LoadLaneFreightFlow } from '@xpo-ltl/sdk-dockoperations';

@Component({
  selector: 'app-freight-flow-path',
  templateUrl: './freight-flow-path.component.html',
  styleUrls: ['./freight-flow-path.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FreightFlowPathComponent {
  @Input() pathInfo: FreightFlow | LoadLaneFreightFlow = null;
  @Input() defaultPath: FreightFlow[] = [];
  @Input() isAlternative: boolean;

  // Using enum in template
  private readonly MoveModeCd = MoveModeCd;
  trackByLoadLeg(index: number, loadLeg: LoadLeg): number {
    return loadLeg?.sequenceNbr;
  }

  trackByLeg(index: number, leg: Leg): number {
    return leg?.sequenceNbr;
  }
}
