import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-misload-dialog',
  templateUrl: './misload-dialog.component.html',
  styleUrls: ['./misload-dialog.component.scss'],
})
export class MisloadDialogComponent {
  constructor(public dialogRef: MatDialogRef<MisloadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
