import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';

import { ActiveSic, LocationSic } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoLtlPopoverTrigger } from '@xpo-ltl/ngx-ltl-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActiveSicFormHelper } from '@app/door-plan-common/classes';
import { LoadLevelsEnum } from '@app/shared/enums/load-lane';
import { DoorLengths, DoorRegexPatterns, FilterFormNames } from '@shared/enums/door-plan';
import { DynamicFilterFields } from '@shared/enums/door-plan/dynamic-filter-fields.enum';
import { LaneTypeApp } from '@shared/enums/lane-type.enum';
import { DoorPlanConditionalFilterCriteria, DoorPlanFilterCriteria } from '@shared/models/door-plan';
import { DoorPlanFilterService } from '@shared/services/door-plan-filter/door-plan-filter.service';

@Component({
  selector: 'app-door-plan-filters-menu-popover',
  templateUrl: './door-plan-filters-menu-popover.component.html',
  styleUrls: ['./door-plan-filters-menu-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-filters-menu-popover',
  },
})
export class DoorPlanFiltersMenuPopoverComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(XpoLtlPopoverTrigger, { static: false }) doorPlanFiltersPopover: XpoLtlPopoverTrigger;
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  private unsubscriber: Unsubscriber = new Unsubscriber();

  filtersForm: FormGroup;
  hasFilters: boolean = false;
  isPopoverOpened = false;
  hasOneDoorFocus = true;
  hasSectorFocus = true;
  hasDoorFocus = false;

  readonly oneDoorRadioValue: string = '1';
  readonly sectorRadioValue: string = '2';
  readonly doorRadioValue: string = '3';
  readonly DoorLengths = DoorLengths;
  readonly patterns = DoorRegexPatterns;
  readonly LoadLevelsEnum = LoadLevelsEnum;
  readonly FilterFormNames = FilterFormNames;

  moveToSicCodes$ = this.doorPlanFilterService.moveToSicCodes$;
  closeToSicCodes$ = this.doorPlanFilterService.closeToSicCodes$;
  destinationSicCodes$ = this.doorPlanFilterService.destinationSicCodes$;

  @Input()
  set filterState(criteria: DoorPlanConditionalFilterCriteria) {
    this.onStateChange(criteria);
  }
  @Input()
  showDoorFilters: boolean = false;
  @Input()
  disableApplyFiltersBtn: boolean = false;
  @Input()
  showLoadLevelFilters: boolean = false;
  @Input()
  filterTitle: string;
  @Output()
  filterCriteria = new EventEmitter<Partial<DoorPlanFilterCriteria>>();

  constructor(private doorPlanFilterService: DoorPlanFilterService) {
    this.initFiltersForm();
    this.onSectorOrDoorRadioChange();
  }

  get moveToFilterControls() {
    return this.filtersForm.get(FilterFormNames.MoveTo) as FormArray;
  }
  get closeToFilterControls() {
    return this.filtersForm.get(FilterFormNames.CloseTo) as FormArray;
  }
  get DestinationsFilterControls() {
    return this.filtersForm.get(FilterFormNames.Destinations) as FormArray;
  }

  onEnterInlineSearch() {} // inline search requires an OnEnter function

  onStateChange(criteria: DoorPlanConditionalFilterCriteria): void {
    const filterCriteria = new DoorPlanConditionalFilterCriteria(criteria as DoorPlanConditionalFilterCriteria);

    this.hasFilters = filterCriteria.hasValues();

    this.cleanFilters(filterCriteria);
  }

  ngOnInit() {
    this.setDynamicFilter(this.moveToSicCodes$, this.moveToFilterControls);
    this.setDynamicFilter(this.closeToSicCodes$, this.closeToFilterControls);
    this.setDynamicFilter(this.destinationSicCodes$, this.DestinationsFilterControls);
  }

  ngAfterViewInit() {
    this.doorPlanFiltersPopover.menuOpened.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.isPopoverOpened = true;

      this.focusTextBoxByRadioSelected(this.filtersForm.get(FilterFormNames.SectorOrDoorRadio).value);
    });

    this.doorPlanFiltersPopover.menuClosed.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.collapseAllPanels();
      this.isPopoverOpened = false;
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setDynamicFilter(sicCodes$: Observable<LocationSic[]>, controlArray: FormArray): void {
    ActiveSicFormHelper.setDynamicFilter(sicCodes$, controlArray)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe();
  }

  onCancel() {
    this.closePopover();
  }

  onSubmit() {
    this.applyFilters();
    this.closePopover();
  }

  onOneDoorFocus() {
    this.filtersForm.get(FilterFormNames.SectorOrDoorRadio).setValue(this.oneDoorRadioValue);
    this.filtersForm.get(FilterFormNames.DoorNumberInitial).reset();
    this.filtersForm.get(FilterFormNames.DoorNumberFinal).reset();
    this.filtersForm.get(FilterFormNames.SectorNumber).reset();
    this.hasDoorFocus = false;
    this.hasSectorFocus = false;
    this.hasOneDoorFocus = true;
  }

  onSectorFocus() {
    this.filtersForm.get(FilterFormNames.SectorOrDoorRadio).setValue(this.sectorRadioValue);
    this.filtersForm.get(FilterFormNames.OneDoorNumber).reset();
    this.filtersForm.get(FilterFormNames.DoorNumberInitial).reset();
    this.filtersForm.get(FilterFormNames.DoorNumberFinal).reset();
    this.hasDoorFocus = false;
    this.hasOneDoorFocus = false;
    this.hasSectorFocus = true;
  }

  onDoorsFocus() {
    this.filtersForm.get(FilterFormNames.SectorOrDoorRadio).setValue(this.doorRadioValue);
    this.filtersForm.get(FilterFormNames.OneDoorNumber).reset();
    this.filtersForm.get(FilterFormNames.SectorNumber).reset();
    this.hasOneDoorFocus = false;
    this.hasSectorFocus = false;
    this.hasDoorFocus = true;
  }

  private applyFilters() {
    const filtersValues = this.filtersForm.value;
    const gridCriteria: Partial<DoorPlanFilterCriteria> = {};

    this.addSectorFilter(gridCriteria, filtersValues);
    this.addDoorFilter(gridCriteria, filtersValues);
    this.addLaneTypeFilter(gridCriteria, filtersValues);
    this.addLoadLevelFilter(gridCriteria, filtersValues);
    this.addPriorityFilter(gridCriteria, filtersValues);

    const moveToFilterArray = this.getDynamicFilterValue(this.moveToFilterControls);
    if (moveToFilterArray.length) {
      gridCriteria.firstLoadPointSicCodes = moveToFilterArray;
    }

    const closeToFilterArray = this.getDynamicFilterValue(this.closeToFilterControls);
    if (closeToFilterArray.length) {
      gridCriteria.farthestLoadPointSicCodes = closeToFilterArray;
    }

    const destinationFilterArray = this.getDynamicFilterValue(this.DestinationsFilterControls);
    if (destinationFilterArray.length) {
      gridCriteria.scoDoorPlanDestinations = destinationFilterArray;
    }

    if (Object.keys(gridCriteria).length) {
      this.hasFilters = true;
      this.filterCriteria.emit(gridCriteria);
    }
  }

  private addSectorFilter(criteria: Partial<DoorPlanFilterCriteria>, filtersValues: any): void {
    if (
      filtersValues[FilterFormNames.SectorOrDoorRadio] === this.sectorRadioValue &&
      filtersValues[FilterFormNames.SectorNumber]
    ) {
      criteria.sector = filtersValues[FilterFormNames.SectorNumber];
    }
  }

  private addDoorFilter(criteria: Partial<DoorPlanFilterCriteria>, filtersValues: any): void {
    const hasDoorInitial =
      filtersValues[FilterFormNames.DoorNumberInitial] &&
      filtersValues[FilterFormNames.DoorNumberInitial].length >= 1 &&
      filtersValues[FilterFormNames.DoorNumberInitial].length <= 4;

    const hasDoorFinal =
      filtersValues[FilterFormNames.DoorNumberFinal] &&
      filtersValues[FilterFormNames.DoorNumberFinal].length >= 1 &&
      filtersValues[FilterFormNames.DoorNumberFinal].length <= 4;

    if ((filtersValues[FilterFormNames.SectorOrDoorRadio] === this.doorRadioValue && hasDoorInitial) || hasDoorFinal) {
      if (hasDoorInitial && hasDoorFinal) {
        let initialDoor = filtersValues[FilterFormNames.DoorNumberInitial];
        let finalDoor = filtersValues[FilterFormNames.DoorNumberFinal];

        if (filtersValues[FilterFormNames.DoorNumberInitial].length < 4) {
          initialDoor = _.padStart(initialDoor, 4, '0');

          this.filtersForm.get(FilterFormNames.DoorNumberInitial).setValue(initialDoor);
        }

        if (filtersValues[FilterFormNames.DoorNumberFinal].length < 4) {
          finalDoor = _.padStart(finalDoor, 4, '0');

          this.filtersForm.get(FilterFormNames.DoorNumberFinal).setValue(finalDoor);
        }

        if (initialDoor === finalDoor) {
          criteria.oneDoorNbr = initialDoor;
        } else {
          criteria.doorNumberFrom = initialDoor;
          criteria.doorNumberTo = finalDoor;
        }
      } else if (hasDoorInitial) {
        const pickedDoor = this.processPickedDoor(filtersValues[FilterFormNames.DoorNumberInitial], criteria);

        this.filtersForm.get(FilterFormNames.DoorNumberInitial).setValue(pickedDoor);
        this.filtersForm.get(FilterFormNames.DoorNumberFinal).setValue(pickedDoor);

        criteria.oneDoorNbr = pickedDoor;
      } else {
        const pickedDoor = this.processPickedDoor(filtersValues[FilterFormNames.DoorNumberFinal], criteria);

        this.filtersForm.get(FilterFormNames.DoorNumberInitial).setValue(pickedDoor);
        this.filtersForm.get(FilterFormNames.DoorNumberFinal).setValue(pickedDoor);

        criteria.oneDoorNbr = pickedDoor;
      }
    }

    if (
      filtersValues[FilterFormNames.SectorOrDoorRadio] === this.oneDoorRadioValue &&
      filtersValues[FilterFormNames.OneDoorNumber] &&
      filtersValues[FilterFormNames.OneDoorNumber].length >= 1 &&
      filtersValues[FilterFormNames.OneDoorNumber].length <= 4
    ) {
      const pickedDoor = this.processPickedDoor(filtersValues[FilterFormNames.OneDoorNumber], criteria);

      this.filtersForm.get(FilterFormNames.OneDoorNumber).setValue(pickedDoor);

      criteria.oneDoorNbr = pickedDoor;
    }
  }

  private addLaneTypeFilter(criteria: Partial<DoorPlanFilterCriteria>, filtersValues: any): void {
    const doorTypes = [];

    if (filtersValues[FilterFormNames.TypeXpo]) {
      doorTypes.push(LaneTypeApp.XPO);
    }

    if (filtersValues[FilterFormNames.TypeExc]) {
      doorTypes.push(LaneTypeApp.EXC);
    }

    if (filtersValues[FilterFormNames.TypeSupp]) {
      doorTypes.push(LaneTypeApp.SUP);
    }
    if (doorTypes && doorTypes.length) {
      criteria.doorTypes = doorTypes;
    }
  }

  private addLoadLevelFilter(criteria: Partial<DoorPlanFilterCriteria>, filtersValues: any): void {
    const loadLevels = [];

    if (filtersValues[FilterFormNames.LoadLevel2Fac]) {
      loadLevels.push(LoadLevelsEnum.SECOND_FAC);
    }

    if (filtersValues[FilterFormNames.LoadLevel3Fac]) {
      loadLevels.push(LoadLevelsEnum.THIRD_FAC);
    }

    if (filtersValues[FilterFormNames.LoadLevel4Fac]) {
      loadLevels.push(LoadLevelsEnum.FOURTH_FAC);
    }

    if (filtersValues[FilterFormNames.LoadLevelFinalDest]) {
      loadLevels.push(LoadLevelsEnum.FINAL_DEST);
    }

    if (loadLevels && loadLevels.length) {
      criteria.loadLevels = loadLevels;
    }
  }

  private addPriorityFilter(criteria: Partial<DoorPlanFilterCriteria>, filtersValues: any): void {
    const priorities = [];
    if (filtersValues[FilterFormNames.PriorityOne]) {
      priorities.push('1');
    }

    if (filtersValues[FilterFormNames.PriorityTwo]) {
      priorities.push('2');
    }

    if (priorities && priorities.length) {
      criteria.priorities = priorities;
    }
  }

  private processPickedDoor(pickedDoor: string, gridCriteria: Partial<DoorPlanFilterCriteria>): string {
    let _pickedDoor = pickedDoor;

    if (_pickedDoor.length < 4) {
      _pickedDoor = _.padStart(_pickedDoor, 4, '0');
    }

    return _pickedDoor;
  }

  private getDynamicFilterValue(controls: FormArray): string[] {
    const filterArray = [];
    if (!controls || !controls.value) {
      return filterArray;
    }
    controls.value.forEach((control) => {
      if (control.selected) {
        filterArray.push(control.sicCd);
      }
    });
    return filterArray;
  }

  private initFiltersForm() {
    this.filtersForm = new FormGroup({
      [FilterFormNames.SectorOrDoorRadio]: new FormControl('1'),
      [FilterFormNames.OneDoorNumber]: new FormControl(null),
      [FilterFormNames.SectorNumber]: new FormControl(null),
      [FilterFormNames.DoorNumberInitial]: new FormControl(null),
      [FilterFormNames.DoorNumberFinal]: new FormControl(null),
      [FilterFormNames.TypeXpo]: new FormControl(null),
      [FilterFormNames.TypeExc]: new FormControl(null),
      [FilterFormNames.TypeSupp]: new FormControl(null),
      [FilterFormNames.PriorityOne]: new FormControl(null),
      [FilterFormNames.PriorityTwo]: new FormControl(null),
      [FilterFormNames.MoveTo]: new FormArray([]),
      [FilterFormNames.CloseTo]: new FormArray([]),
      [FilterFormNames.Destinations]: new FormArray([]),
      [FilterFormNames.LoadLevel2Fac]: new FormControl(null),
      [FilterFormNames.LoadLevel3Fac]: new FormControl(null),
      [FilterFormNames.LoadLevel4Fac]: new FormControl(null),
      [FilterFormNames.LoadLevelFinalDest]: new FormControl(null),
    });
  }

  private onSectorOrDoorRadioChange() {
    this.filtersForm
      .get(FilterFormNames.SectorOrDoorRadio)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((optionSelected: string) => {
        if (!_.isEmpty(optionSelected)) {
          switch (optionSelected) {
            case this.oneDoorRadioValue:
              this.filtersForm.get(FilterFormNames.DoorNumberInitial).reset();
              this.filtersForm.get(FilterFormNames.DoorNumberFinal).reset();
              this.filtersForm.get(FilterFormNames.SectorNumber).reset();
              break;
            case this.sectorRadioValue:
              this.filtersForm.get(FilterFormNames.OneDoorNumber).reset();
              this.filtersForm.get(FilterFormNames.DoorNumberInitial).reset();
              this.filtersForm.get(FilterFormNames.DoorNumberFinal).reset();
              break;
            case this.doorRadioValue:
              this.filtersForm.get(FilterFormNames.OneDoorNumber).reset();
              this.filtersForm.get(FilterFormNames.SectorNumber).reset();
              break;
          }

          this.focusTextBoxByRadioSelected(optionSelected);
        }
      });
  }

  private focusTextBoxByRadioSelected(optionSelected) {
    switch (optionSelected) {
      case this.oneDoorRadioValue:
        this.hasDoorFocus = false;
        this.hasSectorFocus = false;
        this.hasOneDoorFocus = true;
        break;
      case this.sectorRadioValue:
        this.hasOneDoorFocus = false;
        this.hasDoorFocus = false;
        this.hasSectorFocus = true;
        break;
      case this.doorRadioValue:
        this.hasOneDoorFocus = false;
        this.hasSectorFocus = false;
        this.hasDoorFocus = true;
        break;
    }
  }

  private cleanFilters(filtersCriteria: DoorPlanConditionalFilterCriteria) {
    if (!filtersCriteria.doorSectorNbr) {
      this.filtersForm.get(FilterFormNames.SectorNumber).reset();
    }

    if (!filtersCriteria.doorNbr) {
      this.filtersForm.get(FilterFormNames.OneDoorNumber).reset();
      this.filtersForm.get(FilterFormNames.DoorNumberInitial).reset();
      this.filtersForm.get(FilterFormNames.DoorNumberFinal).reset();
    }

    if (!filtersCriteria.priorityNbr) {
      this.filtersForm.get(FilterFormNames.PriorityOne).reset();
      this.filtersForm.get(FilterFormNames.PriorityTwo).reset();
    }

    if (!filtersCriteria.laneTypeCd) {
      this.filtersForm.get(FilterFormNames.TypeXpo).reset();
      this.filtersForm.get(FilterFormNames.TypeExc).reset();
      this.filtersForm.get(FilterFormNames.TypeSupp).reset();
    }

    if (!filtersCriteria.loadLevel) {
      this.filtersForm.get(FilterFormNames.LoadLevel2Fac).reset();
      this.filtersForm.get(FilterFormNames.LoadLevel3Fac).reset();
      this.filtersForm.get(FilterFormNames.LoadLevel4Fac).reset();

      this.filtersForm.get(FilterFormNames.LoadLevelFinalDest).reset();
    }

    if (!filtersCriteria.firstLoadPointSicCode) {
      this.clearDynamicFilter(this.moveToFilterControls);
    }

    if (!filtersCriteria.farthestLoadPointSicCode) {
      this.clearDynamicFilter(this.closeToFilterControls);
    }

    if (!filtersCriteria.scoDoorPlanDestination) {
      this.clearDynamicFilter(this.DestinationsFilterControls);
    }
  }

  private clearDynamicFilter(controlArray: FormArray) {
    for (let i = 0; i < controlArray.length; i++) {
      if (controlArray.at(i)) {
        const group = controlArray.at(i) as FormGroup;
        group.controls[DynamicFilterFields.SELECTED].setValue(false);
      }
    }
  }

  private closePopover() {
    this.collapseAllPanels();
    this.doorPlanFiltersPopover.closeMenu();
  }

  private collapseAllPanels() {
    this.expansionPanels.forEach((panel) => {
      panel.close();
    });
  }
}
