import { Pipe, PipeTransform } from '@angular/core';

import { TrailerStatusCd } from '@xpo-ltl/sdk-common';

import { TrailerStatusEnum } from '@shared/enums/assign-trailer/trailer-status.enum';

@Pipe({
  name: 'trailerStatus',
})
export class TrailerStatusPipe implements PipeTransform {
  transform(value: TrailerStatusCd): string {
    return TrailerStatusEnum[value] || value;
  }
}
