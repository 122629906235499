import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doorNumber',
})
export class DoorNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) {
      return null;
    }
    const leadingZerosRegex = new RegExp('^[0]+', 'g');
    return value.replace(leadingZerosRegex, '');
  }
}
