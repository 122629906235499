import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { DoorPlanPriorityEnum } from '@app/shared/enums/door-plan';
import { DoorPlanItem } from '../../../shared/models/door-plan';

@Component({
  selector: 'app-door-plan-item-inline',
  templateUrl: './door-plan-item-inline.component.html',
  styleUrls: ['./door-plan-item-inline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-item-inline',
  },
})
export class DoorPlanItemInlineComponent implements OnInit {
  @Input()
  doorPlanItem: DoorPlanItem;
  @Input()
  showDoorNumber = true;
  @Input()
  showIndicators = true;
  @Input()
  prefixLabel: string;

  priorityIndicator: string;
  hssIcon: string;
  isEmpty: boolean;
  showPriorityIndicator: boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.doorPlanItem) {
      this.priorityIndicator = this.doorPlanItem.getPriorityIndicator();
      this.showPriorityIndicator = this.priorityIndicator === DoorPlanPriorityEnum.P2;
      this.isEmpty = this.doorPlanItem.isEmpty();
      this.hssIcon = this.doorPlanItem.getLaneTypeIcon();
    }
  }
}
