import { Equipment, EquipmentStatusRemark, TrailerLoadStatusDoorRemark } from '@xpo-ltl/sdk-dockoperations';

import { BulkUpdateEquipment } from '../models/bulk-update-equipment.model';
import { BulkUpdateTrailer } from '../models/bulk-update-trailer.model';

export class EquipmentHelper {
  // #region Maps to EquipmentStatusRemark

  static mapEquipmentStatusRemark(equipment: Equipment): EquipmentStatusRemark {
    const equipmentStatusRemark = new EquipmentStatusRemark();
    equipmentStatusRemark.currentSic = equipment.currentSic;
    equipmentStatusRemark.equipmentId = equipment.equipmentId;
    equipmentStatusRemark.equipmentNbr = `${equipment.equipmentIdPrefix}-${equipment.equipmentIdSuffixNbr}`;
    return equipmentStatusRemark;
  }

  static mapEquipmentWithData(equipment: Equipment, bulkUpdateEquipment: Partial<BulkUpdateEquipment>) {
    const equipmentStatusRemark = this.mapEquipmentStatusRemark(equipment);
    if (bulkUpdateEquipment) {
      if (bulkUpdateEquipment.equipmentStatusCd) {
        equipmentStatusRemark.currentStatus = bulkUpdateEquipment.equipmentStatusCd;
      }
      if (bulkUpdateEquipment.equipmentRemarks) {
        equipmentStatusRemark.equipmentRemarks = bulkUpdateEquipment.equipmentRemarks;
      }
    }
    return equipmentStatusRemark;
  }

  // #region Map to TrailerLoadStatusDoorRemark

  static mapTrailerStatusRemark(equipment: Equipment): TrailerLoadStatusDoorRemark {
    const trailerLoadStatusDoorRemark = new TrailerLoadStatusDoorRemark();
    trailerLoadStatusDoorRemark.equipmentId = equipment.equipmentId;
    return trailerLoadStatusDoorRemark;
  }

  static mapTrailerWithData(
    equipment: Equipment,
    bulkUpdateTrailer: Partial<BulkUpdateTrailer>
  ): TrailerLoadStatusDoorRemark {
    const trailerLoadStatusDoorRemark = this.mapTrailerStatusRemark(equipment);
    if (bulkUpdateTrailer) {
      if (bulkUpdateTrailer.trailerStatusCd) {
        trailerLoadStatusDoorRemark.newTrailerStatus = bulkUpdateTrailer.trailerStatusCd;
        trailerLoadStatusDoorRemark.overheadStatusOverrideInd = false;
      }
      if (bulkUpdateTrailer.equipmentRemarks) {
        trailerLoadStatusDoorRemark.equipmentRemarks = bulkUpdateTrailer.equipmentRemarks;
      }
      if (bulkUpdateTrailer.doorNumber) {
        trailerLoadStatusDoorRemark.doorNbr = bulkUpdateTrailer.doorNumber;
      }
    }
    return trailerLoadStatusDoorRemark;
  }
  // #endregion
}
