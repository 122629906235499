import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { Unsubscriber, XpoLtlSicSwitcherComponent } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sic-switcher-wrapper',
  templateUrl: './sic-switcher-wrapper.component.html',
  styleUrls: ['./sic-switcher-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'sic-switcher-wrapper',
  },
})
export class SicSwitcherWrapperComponent implements AfterViewInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  @ViewChild(XpoLtlSicSwitcherComponent) sicSwitcher: XpoLtlSicSwitcherComponent;

  @Input() title = 'SIC';
  @Input() tooltipText = 'Clear';
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() floatLabel = 'always';
  @Input() sicCd: string;

  @Output()
  sicChange = new EventEmitter<string>();
  previousSic: string;
  sicSwitcherCleared = false;

  constructor() {}

  ngAfterViewInit(): void {
    this.initClosePanelListener();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
  private initClosePanelListener() {
    this.sicSwitcher.sicAutocomplete.closed.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (!this.sicCd) {
        this.sicCd = this.previousSic;
      }
    });
  }

  handleSicChanged(sic: string): void {
    if (!sic || sic === this.previousSic) {
      return;
    }
    this.sicChange.emit(sic);
  }

  removeSic(event: Event): void {
    event.stopPropagation();
    this.previousSic = this.sicCd;
    this.sicCd = '';
    this.sicSwitcher.focus();
  }
}
