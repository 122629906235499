import { Moment } from 'moment';
import * as moment from 'moment-timezone';

const timezone = 'America/Los_Angeles'; // PST PDT

export class DateHelper {
  /**
   * Format date, based on default application timezone
   * @default format = 'MM-DD-YYYY'
   */
  static getFormatDate(date: Date | Moment, format: string = 'MM-DD-YYYY'): string {
    return moment(date).format(format);
  }

  static getFormatDateFromSting(date: String, format: string = 'MM-DD-YYYY'): Moment {
    return moment(date, format);
  }

  static getDayBefore(date: Date): Date {
    const dayBefore = new Date(date);
    dayBefore.setDate(dayBefore.getDate() - 1);
    return dayBefore;
  }

  /**
   * Return today date, but without time
   */
  static getToday(): Date {
    const todayDateTime = new Date();
    const currentYear = todayDateTime.getFullYear();
    const currentMonth = todayDateTime.getMonth();
    const currentDate = todayDateTime.getDate();
    const todayDate = new Date(currentYear, currentMonth, currentDate);
    return todayDate;
  }

  static addDays(date: Date, daysToAdd): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() + daysToAdd);
    return dateToReturn;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const dateMs = date.getTime();
    const msToAdd = minutesToAdd * 6000;
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static addMilliseconds(date: Date, msToAdd: number): Date {
    const dateMs = date.getTime();
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static getDifferenceInMinutes(dateFrom: Date, dateTo: Date): number {
    const dateFromMs = dateFrom.getTime();
    const dateToMs = dateTo.getTime();
    const differenceMs = dateToMs - dateFromMs;
    if (!differenceMs || differenceMs <= 0) {
      return 0;
    }
    return Math.round(differenceMs / 6000);
  }

  static getFormatDateTime(date: Date | Moment, format?: string): string {
    return moment(date)
      .tz(timezone)
      .format(format);
  }
}
