const completed = 'green';
const inProgress = 'blue';
const idle = 'darkgrey';

export const TrailerStatusColors = {
  APPT: inProgress,
  ARIV: completed,
  CARV: completed,
  CLDK: inProgress,
  CLDV: completed,
  CLOS: completed,
  DROP: completed,
  EMTY: idle,
  LDCU: inProgress,
  LDDK: inProgress,
  OVHD: idle,
  PDRT: inProgress,
  TRAP: idle,
  UNLC: inProgress,
  UNLD: inProgress,
};
