import { FormArray, FormGroup } from '@angular/forms';

import { MultipleFreightFlowPathFormNames } from '../enums/multiple-freight-flow-path-form-names.enum';

export function selectedValuesValidator(formArray: FormArray): { [key: string]: boolean } | null {
  const selectedFFPaths = formArray.controls.filter(
    (group: FormGroup) => group.value[MultipleFreightFlowPathFormNames.SELECTED]
  );

  if (!selectedFFPaths.length) {
    return { selected: true };
  }

  return null;
}
