import { Injectable } from '@angular/core';

import {
  XpoBoardData,
  XpoBoardDataFetchState,
  XpoBoardDataSource,
  XpoBoardState,
  XpoFilterCriteria,
} from '@xpo-ltl/ngx-ltl-board';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { DoorPreference, Equipment, LoadLaneDestination } from '@xpo-ltl/sdk-dockoperations';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { DoorPlanListService } from '@app/door-plan-list/services/door-plan-list-service.service';
import { XpoBoardStateSourcesEnum } from '@shared/enums/board';
import { Route } from '@shared/models/route.model';
import { DoorPlanKpis } from '../../interfaces/door-plan-kpis.interface';
import { DoorPlanConditionalFilterCriteria, DoorPlanFilterCriteria, DoorPlanItem } from '../../models/door-plan';
import { CurrentDoorPlanProfileService } from '../current-door-plan-profile/current-door-plan-profile.service';
import { DockOperationsService } from '../dock-operations-service/dock-operations.service';
import { DoorPlanBulkSelection } from '../door-plan-bulk-selection/door-plan-bulk-selection';
import { DoorPlanFilterService } from '../door-plan-filter/door-plan-filter.service';
import { DoorPlanKpisService } from '../door-plan-kpis/door-plan-kpis.service';
import { DoorPlanService } from '../door-plan-service/door-plan.service';
import { NavbarFiltersService } from '../navbar-filters-service/filters-service.service';
import { RouteInfoService } from '../route-info/route-info.service';
import { TrailerInfoService } from '../trailer-info/trailer-info.service';

@Injectable({
  providedIn: 'root',
})
export class DoorPlanDataSourceService extends XpoBoardDataSource<XpoBoardData<DoorPlanItem[]>> {
  doorPlanUpdateTimestamp: Date;

  private readonly _rowDataOddSubject = new BehaviorSubject<DoorPlanItem[]>([]);
  private readonly _rowDataEvenSubject = new BehaviorSubject<DoorPlanItem[]>([]);
  rowDataOdd$ = this._rowDataOddSubject.asObservable();
  rowDataEven$ = this._rowDataEvenSubject.asObservable();
  private readonly _filteredDoorPlanSubject = new BehaviorSubject<DoorPlanItem[]>([]);
  filteredDoorPlan$ = this._filteredDoorPlanSubject.asObservable();
  private readonly _unfilteredDoorPlanSubject = new BehaviorSubject<DoorPlanItem[]>([]);
  unfilteredDoorPlans$ = this._unfilteredDoorPlanSubject.asObservable();
  private readonly _doorPlanWithPreferencesSubject = new BehaviorSubject<DoorPlanItem[]>([]);
  currentDoorPlanWithPreferences$ = this._doorPlanWithPreferencesSubject.asObservable();

  private readonly _onFilterChange: BehaviorSubject<DoorPlanConditionalFilterCriteria> = new BehaviorSubject<
    DoorPlanConditionalFilterCriteria
  >(null);
  onFilterChange$: Observable<DoorPlanConditionalFilterCriteria>;

  constructor(
    private doorPlanService: DoorPlanService,
    private filtersService: NavbarFiltersService,
    private doorPlanFilterService: DoorPlanFilterService,
    private currentDoorPlanProfileService: CurrentDoorPlanProfileService,
    private doorPlanKpisService: DoorPlanKpisService,
    private trailerInfoService: TrailerInfoService,
    private doorPlanBulkSelection: DoorPlanBulkSelection,
    private routeInfoService: RouteInfoService,
    private dockOpsService: DockOperationsService,
    private doorPlanListService: DoorPlanListService
  ) {
    super();

    this.onFilterChange$ = this._onFilterChange.asObservable().pipe(filter((data) => data !== null));

    this.initRefreshListener();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData<DoorPlanItem[]>> {
    if (state.source === XpoBoardStateSourcesEnum.FILTER_CHANGE) {
      this._onFilterChange.next(state.criteria as DoorPlanConditionalFilterCriteria);
    }

    if (
      !this.currentDoorPlanProfileService.getCurrentDoorPlanProfileId() &&
      !this.currentDoorPlanProfileService.canShowProfile()
    ) {
      return of(new XpoBoardData(state, [], 0, this.pageSize));
    }

    return this.getDoorPlan$().pipe(
      tap(() => (this.doorPlanUpdateTimestamp = new Date())),
      tap((scoDoorPlans) => this.doorPlanFilterService.setFilterData(scoDoorPlans)),
      tap((scoDoorPlans) => this._unfilteredDoorPlanSubject.next(scoDoorPlans)),
      map((scoDoorPlans) => this.filterDoorPlans(scoDoorPlans, state.criteria)),
      switchMap((scoDoorPlans) =>
        combineLatest([
          this.getLoadLanes$(),
          this.getDoorPlanWithPreferences$(),
          this.getTrailerInformation$().pipe(switchMap(() => this.getRoutesInformation$())),
        ]).pipe(mapTo(scoDoorPlans))
      ),
      tap((scoDoorPlans) => this.dispatchDoorPlansToGrids(scoDoorPlans)),
      tap(() => this.doorPlanBulkSelection.resetSelectedDoors()),
      tap((scoDoorPlans) => this._doorPlanWithPreferencesSubject.next(scoDoorPlans)),
      map((scoDoorPlans) => new XpoBoardData(state, scoDoorPlans, scoDoorPlans.length, this.pageSize))
    );
  }

  initRefreshListener(): void {
    this.doorPlanListService.refreshData$.pipe(switchMap(() => this.state$.pipe(take(1)))).subscribe((state) => {
      if (state.dataFetchState !== XpoBoardDataFetchState.Loading) {
        this.refresh();
      }
    });
  }

  /** Update Door Plans without calling the API */
  updateData(doorPlans: DoorPlanItem[]): void {
    const criteria = this.stateCache.criteria;
    const filteredDoorPlans = this.filterDoorPlans(doorPlans, criteria);
    this.dispatchDoorPlansToGrids(filteredDoorPlans);
  }

  resetDoorPlans() {
    this._rowDataEvenSubject.next([]);
    this._rowDataOddSubject.next([]);
    this._filteredDoorPlanSubject.next([]);
    this._unfilteredDoorPlanSubject.next([]);
  }

  setCriteria(criteria: Partial<DoorPlanFilterCriteria>) {
    const conditionalFilter = DoorPlanConditionalFilterCriteria.createFromFilterCriteria(criteria);
    this.setState({
      source: XpoBoardStateSourcesEnum.FILTER_CHANGE,
      changes: ['criteria'],
      criteria: conditionalFilter,
    });
  }

  private getDoorPlan$(): Observable<DoorPlanItem[]> {
    return this.currentDoorPlanProfileService.currentDoorPlanProfile$.pipe(
      switchMap((planProfile) =>
        this.doorPlanService.getDoorPlanById(planProfile.doorPlanProfileId, this.filtersService.selectedSic, false)
      )
    );
  }

  getLoadLanes$(): Observable<DoorPlanItem[]> {
    const profileId = this.currentDoorPlanProfileService.getCurrentDoorPlanProfileId();
    const loadLane$ = this.dockOpsService
      .listDockShipmentSummaries(
        this.filtersService.selectedSic,
        this.filtersService.selectedShift,
        this.currentDoorPlanProfileService.getDoorPlanProfileDate()
      )
      .pipe(
        map((loadLanes) => this.populateLoadLaneIntoDoorPlans(this._filteredDoorPlanSubject.value, loadLanes)),
        tap((scoDoorPlans) => this.calculateKpis(scoDoorPlans)),
        catchError(() => of(this.populateLoadLaneIntoDoorPlans(this._filteredDoorPlanSubject.value, null)))
      );
    return profileId ? loadLane$ : of(this.populateLoadLaneIntoDoorPlans(this._filteredDoorPlanSubject.value, null));
  }

  private getDoorPlanWithPreferences$(): Observable<DoorPlanItem[]> {
    return this.doorPlanService
      .getListDockDoorPreferences(
        this.filtersService.selectedSic,
        this.filtersService.selectedShift,
        this.currentDoorPlanProfileService.getCurrentDoorPlanProfileType()
      )
      .pipe(
        map((doorPreferences) =>
          this.populateDockPreferencesIntoDoorPlans(this._filteredDoorPlanSubject.value, doorPreferences)
        ),
        catchError(() => of(this.populateDockPreferencesIntoDoorPlans(this._filteredDoorPlanSubject.value, null)))
      );
  }

  private getTrailerInformation$(): Observable<DoorPlanItem[]> {
    const selectedSic = this.filtersService.selectedSic;
    if (!selectedSic) {
      return of(this.populateTrailerInfoToDoors(this._filteredDoorPlanSubject.value, null, selectedSic));
    }
    return this.trailerInfoService.getTrailerInfo(selectedSic, false, false).pipe(
      map((equipments) =>
        this.populateTrailerInfoToDoors(this._filteredDoorPlanSubject.value, equipments, selectedSic)
      ),
      catchError(() => of(this.populateTrailerInfoToDoors(this._filteredDoorPlanSubject.value, null, selectedSic)))
    );
  }

  private getRoutesInformation$(): Observable<DoorPlanItem[]> {
    const selectedSic = this.filtersService.selectedSic;
    const selectedShift = this.filtersService.selectedShift;

    if (selectedSic && selectedShift === ShiftCd.INBOUND) {
      return this.routeInfoService.getRoutes(selectedSic, this.trailerInfoService.allTrailers, false, false).pipe(
        map((routes) => this.populateRouteInfoToDoors(this._filteredDoorPlanSubject.value, routes)),
        catchError(() => of(null))
      );
    }

    return of(null);
  }

  private filterDoorPlans(doorPlans: DoorPlanItem[], criteria: XpoFilterCriteria): DoorPlanItem[] {
    // Save all doorPlans before the filtering
    this._filteredDoorPlanSubject.next(doorPlans);
    const filterCriteria = new DoorPlanConditionalFilterCriteria(criteria);
    if (filterCriteria.hasValues()) {
      return doorPlans.filter((doorPlan) => filterCriteria.matchFilters(doorPlan));
    }
    return doorPlans;
  }

  private dispatchDoorPlansToGrids(doorPlans: DoorPlanItem[]): void {
    const sortedDoorPlans = doorPlans.sort((a, b) => Number(a.displaySequenceNbr) - Number(b.displaySequenceNbr));
    const evenFullDoorPlansByDisplaySequenceNbr = sortedDoorPlans.filter((plan) => plan.isEven());
    const oddFullDoorPlansByDisplaySequenceNbr = sortedDoorPlans.filter((plan) => plan.isOdd());
    this._filteredDoorPlanSubject.next(sortedDoorPlans);
    this._rowDataEvenSubject.next(evenFullDoorPlansByDisplaySequenceNbr);
    this._rowDataOddSubject.next(oddFullDoorPlansByDisplaySequenceNbr);
  }

  private populateLoadLaneIntoDoorPlans(
    doorPlans: DoorPlanItem[],
    loadLanesDestinations: LoadLaneDestination[]
  ): DoorPlanItem[] {
    if (!loadLanesDestinations?.length) {
      return doorPlans.map((doorPlan) => {
        doorPlan.updateFromLoadLaneDestinations(null);
        return doorPlan;
      });
    }
    return doorPlans.map((doorPlan) => {
      if (!doorPlan.isEmpty()) {
        doorPlan.updateFromLoadLaneDestinations(loadLanesDestinations);
      }
      return doorPlan;
    });
  }

  private populateDockPreferencesIntoDoorPlans(
    doorPlans: DoorPlanItem[],
    dockDoorPreferences: DoorPreference[]
  ): DoorPlanItem[] {
    if (dockDoorPreferences?.length) {
      return doorPlans.map((doorPlan) => {
        const matchedDockDoorPreference = dockDoorPreferences.find((dockDoor) => dockDoor.doorNbr === doorPlan.doorNbr);
        doorPlan.addDoorPreferences(matchedDockDoorPreference);
        return doorPlan;
      });
    }
  }

  private populateTrailerInfoToDoors(
    doorPlanItems: DoorPlanItem[],
    equipmentData: Equipment[],
    sic: string
  ): DoorPlanItem[] {
    if (!equipmentData?.length) {
      return doorPlanItems.map((doorPlan: DoorPlanItem) => {
        doorPlan.addTrailerInfo(null, sic);
        return doorPlan;
      });
    }
    return doorPlanItems.map((doorPlan: DoorPlanItem) => {
      const equipment = equipmentData.find((eq: Equipment) => doorPlan.doorNbr === eq.trailerLoad.evntDoor);
      doorPlan.addTrailerInfo(equipment, sic);
      return doorPlan;
    });
  }

  private populateRouteInfoToDoors(doorPlanItems: DoorPlanItem[], routes: Route[]): DoorPlanItem[] {
    if (routes?.length) {
      const filteredRoutes = routes.filter((route) => !!route.plannedDoor);

      return doorPlanItems.map((doorPlan: DoorPlanItem) => {
        const doorPlanRoutes = filteredRoutes.filter((elem: Route) => doorPlan.doorNbr === elem.plannedDoor);
        doorPlan.addRouteInfo(doorPlanRoutes);
        return doorPlan;
      });
    }
  }

  private calculateKpis(doorPlans: DoorPlanItem[]): void {
    const kpis: DoorPlanKpis = {
      remainingCubeLane: 0,
      remainingWeightLane: 0,
      remainingBillCountLane: 0,
      remainingMMLane: 0,
      totalCubeLane: 0,
      totalWeightLane: 0,
      totalBillCountLane: 0,
      totalMMLane: 0,
    };

    doorPlans.forEach((doorPlan) => {
      if (doorPlan?.priorityNbr === '1' && doorPlan.loadLaneInfo) {
        kpis.remainingCubeLane += doorPlan.loadLaneInfo.remainingCubeLane || 0;
        kpis.remainingWeightLane += doorPlan.loadLaneInfo.remainingWeightLane || 0;
        kpis.remainingBillCountLane += doorPlan.loadLaneInfo.remainingBillCountLane || 0;
        kpis.remainingMMLane += doorPlan.loadLaneInfo.remainingMMLane || 0;
        kpis.totalCubeLane += doorPlan.loadLaneInfo.totalCubeLane || 0;
        kpis.totalWeightLane += doorPlan.loadLaneInfo.totalWeightLane || 0;
        kpis.totalBillCountLane += doorPlan.loadLaneInfo.totalBillCountLane || 0;
        kpis.totalMMLane += doorPlan.loadLaneInfo.totalMMLane || 0;
      }
    });

    this.doorPlanKpisService.setDoorPlanKpis(kpis);
  }
}
