import { NgModule } from '@angular/core';
import { XpoFooterComponent } from './xpo-footer.component';

@NgModule({
  declarations: [XpoFooterComponent],
  imports: [],
  exports: [XpoFooterComponent],
  providers: [],
  bootstrap: [XpoFooterComponent],
})
export class XpoFooterModule {}
