import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { XpoAuthenticationService, XpoAuthEventHandler } from '@xpo/ngx-auth';
import { UserManager } from 'oidc-client';

@Injectable()
export class XpoAuthenticationServiceAlwaysLoggedIn extends XpoAuthenticationService {
  constructor(manager: UserManager, router: Router, authEventListener: XpoAuthEventHandler) {
    super(manager, router, authEventListener);

    const user = sessionStorage.getItem('oidc.user:https://login-sandbox.authxpo.com:ltl-dockops');
    this.setUser(JSON.parse(user));
  }

  isLoggedIn() {
    // ALWAYS logged in for Cypress tests since Cypress did the login for us
    return true;
  }
}
