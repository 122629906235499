import { Injectable } from '@angular/core';

import { DataOptions } from '@xpo-ltl/data-api';
import { DoorPlanActionCd, ShiftCd } from '@xpo-ltl/sdk-common';
import {
  CreateDoorPlanPath,
  CreateDoorPlanResp,
  CreateDoorPlanRqst,
  DeriveLoadLanesByDockPlanPath,
  DeriveLoadLanesByDockPlanQuery,
  DockDoorPlan,
  DockOperationsApiService,
  ListHeadloadDestinationsByDoorPlanProfilePath,
  ListHeadloadDestinationsByDoorPlanProfileQuery,
  UpdateDoorPlansByActionPath,
  UpdateDoorPlansByActionRqst,
} from '@xpo-ltl/sdk-dockoperations';
import _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DateHelper } from '@shared/classes/date-helper';
import { AppCacheKeys } from '@shared/enums/app-cache-keys.enum';
import { AppRoutesLabels } from '@shared/enums/app-routes.enum';
import { LoadLevelsEnum } from '@shared/enums/load-lane';
import { IloadLaneFilters } from '@shared/interfaces';
import { DoorPlanConditionalFilterCriteria, DoorPlanItem, DoorPlanProfile } from '@shared/models/door-plan';
import { AppCacheService } from '../cache-service/cache.service';
import { DoorPlanService } from '../door-plan-service/door-plan.service';
import { TransactionTimestampInterceptor } from '../interceptors/transaction-timestamp.interceptor';

@Injectable({
  providedIn: 'root',
})
export class LoadLaneSummaryService {
  constructor(
    private dockOperationsApiService: DockOperationsApiService,
    private cacheService: AppCacheService,
    private doorPlanService: DoorPlanService
  ) {}

  deriveLoadLanesByDockPlan(
    sicCd: string,
    shiftCd: ShiftCd,
    llValues: IloadLaneFilters,
    useCache = false
  ): Observable<DoorPlanItem[]> {
    if (!sicCd || !shiftCd || !llValues.date) {
      return of([]);
    }
    const planDate = DateHelper.getFormatDate(llValues.date, 'YYYY-MM-DD');

    const cacheKey = `${AppCacheKeys.LOAD_LANES_BY_SIC}-${shiftCd}-${sicCd}-${planDate}`;
    const cachedResponse = this.cacheService.getCacheValue(cacheKey);
    if (useCache && cachedResponse) {
      return of(cachedResponse);
    }

    const pathParams = new DeriveLoadLanesByDockPlanPath();
    pathParams.shiftCd = shiftCd;
    pathParams.sicCd = sicCd;

    const queryParams = new DeriveLoadLanesByDockPlanQuery();
    queryParams.planDates = [planDate];
    queryParams.thresholdWeight = llValues.thresholdWeight ? Number(llValues.thresholdWeight) : null;
    queryParams.thresholdCubePct = llValues.thresholdCube ? Number(llValues.thresholdCube) : null;
    queryParams.includeDoorPlanInd = true;
    queryParams.includeOptimalLoadLanesInd = llValues.includeOptimalLoadLanesInd || false;
    queryParams.remainingToLoadShipmentsInd = llValues.remainingToLoadShipmentsInd
      ? llValues.remainingToLoadShipmentsInd
      : null;

    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.dockOperationsApiService.deriveLoadLanesByDockPlan(pathParams, queryParams, dataOptions).pipe(
      map((response) => {
        if (!response || !response.loadLanes) {
          return [];
        }
        return response.loadLanes
          .map((loadLane) => DoorPlanItem.createFromLoadLane(loadLane))
          .sort((a, b) => a.getLoadLevelOrder() - b.getLoadLevelOrder());
      }),
      tap((response) => this.cacheService.setCacheValue(cacheKey, response)),
      catchError(() => of([]))
    );
  }

  addDoorToLane(
    doorPlanProfileId: number,
    emptyDoorNbr: string,
    doorPlanItem: DoorPlanItem,
    confirmActionInd = false
  ): Observable<CreateDoorPlanResp> {
    const doorPlan = new DockDoorPlan();
    doorPlan.doorNbr = emptyDoorNbr;
    doorPlan.priorityNbr = '1';
    doorPlan.moveToSicCd = doorPlanItem.firstLoadPointSicCode;
    doorPlan.closeToSicCd = doorPlanItem.farthestLoadPointSicCode;
    doorPlan.doorPlanDestinations = doorPlanItem.getDockDoorPlanDestinations(false);
    doorPlan.applicationId = AppRoutesLabels.LOAD_LANE_SUMMARY;

    const rqst: CreateDoorPlanRqst = {
      doorPlan,
      confirmActionInd,
    };

    const path: CreateDoorPlanPath = {
      doorPlanProfileId,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService
      .createDoorPlan(rqst, path, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((response) => {
          if (response && response.warningMessage) {
            // So it can be handle in error function
            throw response.warningMessage;
          }
          return response;
        })
      );
  }

  updateDoorToLane(
    doorPlanProfileId: number,
    fromDoorNbr: string,
    toDoorNbr: string,
    errorOverrideInd = false,
    confirmActionInd = false
  ): Observable<CreateDoorPlanResp> {
    const rqst: UpdateDoorPlansByActionRqst = {
      supervisorEmplId: '',
      errorOverrideInd,
      confirmActionInd,
    };

    const path: UpdateDoorPlansByActionPath = {
      doorPlanProfileId,
      doorPlanActionCd: DoorPlanActionCd.MOVE,
      fromDoorNbr,
      toDoorNbr,
    };

    const options: DataOptions = {
      toastOnError: true,
      loadingOverlayEnabled: true,
    };

    return this.dockOperationsApiService
      .updateDoorPlansByAction(rqst, path, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map((response) => {
          if (response && response.warningMessage) {
            // So it can be handle in error function
            throw response.warningMessage;
          }
          return response;
        })
      );
  }

  getHeadLoadsOpportunities(
    sicCd: string,
    shiftCd: ShiftCd,
    doorPlanProfile: DoorPlanProfile
  ): Observable<DoorPlanItem[]> {
    if (!sicCd || !shiftCd) {
      return of([]);
    }
    const pathParams = new ListHeadloadDestinationsByDoorPlanProfilePath();
    pathParams.doorPlanProfileId = doorPlanProfile.doorPlanProfileId;

    const queryParams = new ListHeadloadDestinationsByDoorPlanProfileQuery();
    queryParams.listInfo = null;

    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    const headLoads$ = this.dockOperationsApiService
      .listHeadloadDestinationsByDoorPlanProfile(pathParams, queryParams, dataOptions)
      .pipe(
        map((response) => {
          if (!response || !response.headloads) {
            return [];
          }
          return response.headloads
            .map((headloads) => DoorPlanItem.createFromLoadLane(headloads))
            .sort((a, b) => a.getLoadLevelOrder() - b.getLoadLevelOrder());
        }),
        map((doorPlanItems) =>
          doorPlanItems.filter((doorPlanItem) => this.filterVisibleLoadLaneSummary(doorPlanItem, sicCd))
        ),
        catchError(() => of([]))
      );

    return combineLatest([headLoads$, this.doorPlanService.getAllActiveDoors(sicCd, false)]).pipe(
      map(([headloads, allActiveDoors]) => this.doorPlanService.sectorWorkaround(headloads, allActiveDoors)),
      catchError(() => of([]))
    );
  }

  getDeepLoadingOpportunities(
    sicCd: string,
    shiftCd: ShiftCd,
    thresholdCubePct: number,
    thresholdWeight: number,
    date: Date
  ): Observable<DoorPlanItem[]> {
    if (!sicCd || !shiftCd) {
      return of([]);
    }
    const planDate = DateHelper.getFormatDate(date, 'YYYY-MM-DD');
    const pathParams = new DeriveLoadLanesByDockPlanPath();
    pathParams.shiftCd = shiftCd;
    pathParams.sicCd = sicCd;

    const queryParams = new DeriveLoadLanesByDockPlanQuery();
    queryParams.planDates = [planDate];
    queryParams.thresholdWeight = thresholdWeight ? Number(thresholdWeight) : null;
    queryParams.thresholdCubePct = thresholdCubePct ? Number(thresholdCubePct) : null;
    queryParams.includeDoorPlanInd = true;
    queryParams.includeOptimalLoadLanesInd = true;
    queryParams.remainingToLoadShipmentsInd = true;

    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };

    return this.dockOperationsApiService.deriveLoadLanesByDockPlan(pathParams, queryParams, dataOptions).pipe(
      map((response) => {
        if (!response || !response.loadLanes) {
          return [];
        }
        return response.loadLanes
          .map((loadLane) => DoorPlanItem.createFromLoadLane(loadLane))
          .sort((a, b) => a.getLoadLevelOrder() - b.getLoadLevelOrder());
      }),
      map((doorPlanItems) =>
        doorPlanItems.filter((doorPlanItem) => this.filterVisibleLoadLaneSummary(doorPlanItem, sicCd))
      ),
      catchError(() => of([]))
    );
  }

  private filterVisibleLoadLaneSummary(doorPlan: DoorPlanItem, sicCd: string): boolean {
    if (
      doorPlan.farthestLoadPointSicCode === sicCd ||
      doorPlan.loadLaneInfo?.loadLevel === LoadLevelsEnum.FIRST_FAC ||
      doorPlan.loadLaneInfo.pupCount === 0
    ) {
      return false;
    }
    return true;
  }
}
