export enum Shifts {
  Outbound = 'O',
  DayReship = 'D',
  Inbound = 'I',
  NightFAC = 'N',
}

export enum Shift {
  O = 'O',
  D = 'D',
  I = 'I',
  F = 'N',
}

export enum FreightFlowShift {
  Outbound = 'O',
  DayReship = 'D',
  Inbound = 'I',
  NightFAC = 'F',
}

export enum shiftCodesMap {
  O = 'Outbound',
  D = 'DayReship',
  I = 'Inbound',
  N = 'NightFAC',
}
