import { Injectable } from '@angular/core';

import { DataOptions } from '@xpo-ltl/data-api';
import { CityOperationsApiService, ListPnDRoutesPath, ListPnDRoutesQuery } from '@xpo-ltl/sdk-cityoperations';
import { Equipment } from '@xpo-ltl/sdk-dockoperations';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Route } from '@shared/models/route.model';
import { TransactionTimestampInterceptor } from '../interceptors/transaction-timestamp.interceptor';

@Injectable({
  providedIn: 'root',
})
export class RouteInfoService {
  private lastSicChecked: string = null;

  private readonly _routesSubject = new BehaviorSubject<Route[]>([]);
  routes$ = this._routesSubject.asObservable();

  constructor(private cityOperationsApiService: CityOperationsApiService) {}

  setRoutes(routes: Route[]): void {
    this._routesSubject.next(routes);
  }

  getRoutes(
    sicCd: string,
    trailers: Equipment[] = [],
    useCache = true,
    loadingOverlayEnabled = true
  ): Observable<Route[]> {
    if (!sicCd) {
      return of(null);
    }
    if (useCache && sicCd === this.lastSicChecked) {
      return this.routes$;
    }

    const path: ListPnDRoutesPath = {
      sicCd,
    };
    const queryParams = new ListPnDRoutesQuery();

    const options: DataOptions = {
      toastOnError: false,
      loadingOverlayEnabled,
    };

    return this.cityOperationsApiService
      .listPnDRoutes(path, queryParams, options, TransactionTimestampInterceptor.useTransactionTimestampHeader)
      .pipe(
        map(({ routes }) => {
          return routes.map((dockRoute) => {
            let trailerInfo: Equipment;

            if (dockRoute.equipmentIdPrefix && dockRoute.equipmentIdSuffixNbr && dockRoute.plannedDoor) {
              trailerInfo = trailers.find(
                (eq) =>
                  eq.equipmentIdPrefix === dockRoute.equipmentIdPrefix &&
                  eq.equipmentIdSuffixNbr === dockRoute.equipmentIdSuffixNbr
              );
            }

            return Route.createComposedRoute(dockRoute, trailerInfo);
          });
        }),
        tap((routes) => {
          this.setRoutes(routes);
          this.lastSicChecked = sicCd;
        })
      );
  }
}
