export enum AppCacheKeys {
  ACTIVE_SICS = 'active-sics',
  ACTIVE_DOORS_BY_SIC = 'active-doors-by-sic',
  LOAD_LANES_BY_SIC = 'load-lanes-by-sic',
  DOOR_PLAN_REPAIR = 'door-plan-repair',
  REQUIREMENTS = 'requirements',
  RECOMMENDATIONS = 'recommendations',
  CONFIRMED_FIX = 'confirmed-fix',
  TTL = 'time-to-live',
}

export enum AppCacheTtl {
  EXPIRES_TODAY = 'expires-today',
}
