import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TrailerLoadingWarningTable } from '@shared/enums/trailer-loading-warning-table.enum';

@Component({
  selector: 'app-trailer-loading-warning',
  templateUrl: './trailer-loading-warning.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./trailer-loading-warning.component.scss'],
  host: {
    class: 'trailer-loading-warning',
  },
})
export class TrailerLoadingWarningDialogComponent implements OnInit {
  displayedColumns: string[];
  readonly columnNames = TrailerLoadingWarningTable;

  constructor(
    public dialogRef: MatDialogRef<TrailerLoadingWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [this.columnNames.position, this.columnNames.destination, this.columnNames.door];
  }

  triggerAction(response = false): void {
    this.dialogRef.close(response);
  }
}
