import { ShiftCd } from '@xpo-ltl/sdk-common';
import { FreightFlowShift, Shift, Shifts } from '../enums/shifts';

export class ShiftHelper {
  /** Return the ShiftCd base on the letter (O, I, D, F) */
  static getValue(key: Shift): ShiftCd {
    return Object.keys(Shifts).find((shiftCd) => Shifts[shiftCd] === key) as ShiftCd;
  }

  /** Return all the ShiftCd */
  static getList(): ShiftCd[] {
    return [ShiftCd.OUTBOUND, ShiftCd.DAY_RESHIP, ShiftCd.INBOUND, ShiftCd.NIGHT_FAC];
  }

  /** Return shift code abbreviation used in freight flow paths */
  static getFreightFlowShift(value: ShiftCd): FreightFlowShift {
    return FreightFlowShift[value];
  }
}
