import { LoadLevelsEnum } from '@app/shared/enums/load-lane';
import { XpoFilterCriteria } from '@xpo-ltl/ngx-ltl-board';
import { LaneTypeApp } from '../../enums/lane-type.enum';

export class DoorPlanFilterCriteria implements XpoFilterCriteria {
  sector: string;
  doorNumberFrom: string;
  doorNumberTo: string;
  oneDoorNbr: string;
  priorities: string[];
  doorTypes: LaneTypeApp[];
  firstLoadPointSicCodes: string[];
  farthestLoadPointSicCodes: string[];
  scoDoorPlanDestinations: string[];
  loadLevels: LoadLevelsEnum[];

  constructor(data?: Partial<DoorPlanFilterCriteria>) {
    if (data) {
      this.sector = data.sector;
      this.oneDoorNbr = data.oneDoorNbr;
      this.doorNumberFrom = data.doorNumberFrom;
      this.doorNumberTo = data.doorNumberTo;
      this.doorTypes = data.doorTypes;
      this.priorities = data.priorities;
      this.firstLoadPointSicCodes = data.firstLoadPointSicCodes;
      this.farthestLoadPointSicCodes = data.farthestLoadPointSicCodes;
      this.scoDoorPlanDestinations = data.scoDoorPlanDestinations;
      this.loadLevels = data.loadLevels;
    }
  }
}
