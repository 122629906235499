import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ActiveSic } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AddUpdatePlanEvent } from '@app/door-plan-common/interfaces';
import { DoorPlanActionsEnum } from '@shared/enums/door-plan';
import { MenuOptions } from '@shared/interfaces/menu-options.interface';
import { DoorPlanItem, DoorPlanProfile, GTAction } from '@shared/models/door-plan';
import { DoorPlanService } from '@shared/services/door-plan-service/door-plan.service';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service/filters-service.service';
import { FreightFlowApiService, LocationEmbargo } from '@xpo-ltl-2.0/sdk-freightflow';
import { XpoContextMenuComponent, XpoContextMenuItem, XpoContextMenuResult } from '../../../context-menu/public_api';
import { DoorPlanContextMenuHelper } from './door-plan-context-menu-helper';

@Component({
  selector: 'app-door-plan-context-menu',
  templateUrl: './door-plan-context-menu.component.html',
  styleUrls: ['./door-plan-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'door-plan-context-menu',
  },
})
export class DoorPlanContextMenuComponent implements OnDestroy, AfterViewInit {
  private unsubscriber = new Unsubscriber();

  @ViewChild(XpoContextMenuComponent, { static: true }) contextMenu: XpoContextMenuComponent<DoorPlanItem>;
  @ViewChild('contextHeaderTpl', { static: true }) contextHeaderTpl: TemplateRef<any>;
  @ViewChild('contextMenuAddUpdatePlanTpl', { static: true }) contextMenuAddUpdatePlanTpl: TemplateRef<any>;
  @ViewChild('contextSubmenuTpl', { static: true }) contextSubmenuTpl: TemplateRef<any>;
  @ViewChild('emptyMenuItemExcludedTpl', { static: true }) emptyMenuItemExcludedTpl: TemplateRef<any>;
  @ViewChild('emptyMenuItemHoldTpl', { static: true }) emptyMenuItemHoldTpl: TemplateRef<any>;
  @ViewChild('emptyMenuItemEmbargoTpl', { static: true }) emptyMenuItemEmbargoTpl: TemplateRef<any>;

  @Output()
  itemSelected = new EventEmitter<GTAction>();

  @Input()
  doorPlanItems: DoorPlanItem[];
  @Input()
  moveToSicCodes$: Observable<ActiveSic[]>;
  @Input()
  doorPlanProfile: DoorPlanProfile;

  mergeDoorPlanItems: DoorPlanItem[];
  menusItems: { [key: string]: XpoContextMenuItem<DoorPlanItem>[] };
  menuOptions: MenuOptions = {};
  mergeOptionsLoading = false;
  embargoSics: LocationEmbargo[];

  constructor(
    private navbarFiltersService: NavbarFiltersService,
    private doorPlanService: DoorPlanService,
    private ref: ChangeDetectorRef,
    private freightFlow: FreightFlowApiService
  ) {}

  ngAfterViewInit(): void {
    this.initMenuOptions();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private initMenuOptions(): void {
    this.menusItems = DoorPlanContextMenuHelper.getMenus(
      this.contextSubmenuTpl,
      this.contextMenuAddUpdatePlanTpl,
      this.emptyMenuItemExcludedTpl,
      this.emptyMenuItemHoldTpl,
      this.emptyMenuItemEmbargoTpl
    );
  }

  private onActionSelected(action: GTAction): void {
    this.itemSelected.emit(action);
  }

  private getDoorItemsOnMergeOptions(doorPlanItem: DoorPlanItem): void {
    this.mergeOptionsLoading = true;
    this.doorPlanService
      .getDoorPlanMergeOptions(
        doorPlanItem.doorNbr,
        this.navbarFiltersService.selectedSic,
        this.navbarFiltersService.selectedShift,
        this.doorPlanProfile.profileTypeCd
      )
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(
        (doorPlanMergeOptions) => {
          this.mergeDoorPlanItems = this.doorPlanItems.filter(
            (doorPlan) => !!doorPlanMergeOptions.find((mergeOption) => mergeOption.doorNbr === doorPlan.doorNbr)
          );
          const mergeMenuOption = this.menuOptions.items.find(
            (item) => item.name === DoorPlanActionsEnum.PERFORM_MERGE
          );
          mergeMenuOption.disable = !this.mergeDoorPlanItems?.length;
          this.mergeOptionsLoading = false;
          this.ref.detectChanges();
        },
        () => {
          // If GetMergeOptions fails or it is empty we use "Same Move To"
          this.mergeDoorPlanItems = this.doorPlanItems.filter(
            (dpItem) => dpItem.firstLoadPointSicCode === doorPlanItem.firstLoadPointSicCode
          );
          this.mergeOptionsLoading = false;
        }
      );
  }

  onMenuItemSelected(result: XpoContextMenuResult<DoorPlanItem>): void {
    const action = result.menuItem.name as DoorPlanActionsEnum;
    const gtAction: GTAction = {
      action,
      data: {
        firstRow: result.contextData,
        secondRow: null,
      },
    };
    this.onActionSelected(gtAction);
  }

  onMenuItemSelectedAddUpdatePlan(doorPlanItem: DoorPlanItem, addUpdateEvent: AddUpdatePlanEvent): void {
    const action = doorPlanItem.isEmpty() ? DoorPlanActionsEnum.PERFORM_ADD : DoorPlanActionsEnum.PERFORM_UPDATE;
    const gtAction: GTAction = {
      action,
      data: {
        firstRow: doorPlanItem,
        secondRow: null,
        additionalData: addUpdateEvent,
      },
    };
    this.onActionSelected(gtAction);
  }

  onSubItemClick(
    menuItem: XpoContextMenuItem<DoorPlanItem>,
    doorPlanItem1: DoorPlanItem,
    doorPlanItem2: DoorPlanItem
  ): void {
    const action = menuItem.name as DoorPlanActionsEnum;
    const gtAction: GTAction = {
      action,
      data: {
        firstRow: doorPlanItem1,
        secondRow: doorPlanItem2,
      },
    };
    this.onActionSelected(gtAction);
  }

  isMergeMenuItem(menuItem: XpoContextMenuItem<DoorPlanItem>): boolean {
    return menuItem.name === DoorPlanActionsEnum.PERFORM_MERGE;
  }

  openMenu(event: MouseEvent, doorPlanItem: DoorPlanItem): void {
    let menuOptions: MenuOptions;

    if (doorPlanItem.isEmpty()) {
      menuOptions = {};
      menuOptions.items = this.getEmptyDoorMenuOptions(doorPlanItem);
    } else {
      this.getDoorItemsOnMergeOptions(doorPlanItem);
      let menuKey = `priority${doorPlanItem.priorityNbr === '1' ? '1' : '2'}`;
      if (!this.doorPlanProfile.isStaticDoorPlanProfile() && !doorPlanItem.staticMatchInd) {
        menuKey += 'NonStaticDoor';
        if (doorPlanItem.doorPreference?.embargoInd) {
          menuKey += 'Embargoed';
        }
      } else if (this.doorPlanProfile.isStaticDoorPlanProfile()) {
        menuKey += 'StaticDoor';
      }
      menuOptions = {
        header: this.contextHeaderTpl,
        items: this.menusItems[menuKey],
      };
    }

    this.menuOptions = menuOptions;
    this.contextMenu.openMenu(event, doorPlanItem);
  }

  private getEmptyDoorMenuOptions(doorPlanItem: DoorPlanItem): XpoContextMenuItem<DoorPlanItem>[] {
    const staticProfile = this.doorPlanProfile.isStaticDoorPlanProfile(); // doorPlanItem.doorPreference?.profileTypeCd
    if (doorPlanItem.doorPreference?.embargoInd) {
      this.getEmbargoCodes();
      return this.menusItems.embargoDoor;
    } else if (doorPlanItem.doorPreference?.trapInd) {
      return this.menusItems.emptyDoorTrapDoor;
    } else if (doorPlanItem.doorPreference?.stepSaverExcludeInd) {
      return this.menusItems.excludedDoor;
    } else if (
      [ShiftCd.OUTBOUND, ShiftCd.DAY_RESHIP].includes(this.navbarFiltersService.selectedShift) &&
      !staticProfile
    ) {
      return this.menusItems.emptyDoorNonValidShiftExclude;
    } else if (
      [ShiftCd.OUTBOUND, ShiftCd.DAY_RESHIP].includes(this.navbarFiltersService.selectedShift) &&
      staticProfile
    ) {
      return this.menusItems.emptyDoorNonValidShiftExcludeStatic;
    } else if (staticProfile) {
      return this.menusItems.emptyDoorValidShiftExcludeStatic;
    } else {
      return this.menusItems.emptyDoorValidShiftExclude;
    }
  }

  private getEmbargoCodes(): void {
    this.freightFlow
      .listEmbargoLocations({ shipmentTypeCd: 'L', embargoDateTime: null })
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((locations) => {
        this.embargoSics = locations.locationEmbargos;
      });
  }
}
