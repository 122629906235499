import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { NavbarFiltersService } from '@shared/services/navbar-filters-service';

@Component({
  selector: 'xpo-footer',
  templateUrl: './xpo-footer.component.html',
  styleUrls: ['./xpo-footer.component.scss'],
})
export class XpoFooterComponent implements OnInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  readonly defaultMailLink = 'mailto:ltledgedoorplansupport@xpo.com?subject=Edge door plan feedback';
  trainingResourcesLink: string;
  releaseNotesLink: string;
  buildVersion: string;
  year: string;
  mailToLink: string;

  constructor(private configManagerService: ConfigManagerService, private filterService: NavbarFiltersService) {}

  ngOnInit() {
    this.buildVersion = this.configManagerService.getSetting('buildVersion');
    this.year = new Date().getFullYear().toString();
    this.trainingResourcesLink = this.configManagerService.getSetting(ConfigManagerProperties.trainingResourcesLink);
    this.releaseNotesLink = this.configManagerService.getSetting(ConfigManagerProperties.releaseNotesLink);
    this.mailToLink = this.defaultMailLink;
    this.filterWatcher();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private filterWatcher() {
    combineLatest([this.filterService.selectedSic$, this.filterService.selectedShift$])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([sic, shift]) => {
        this.mailToLink = this.defaultMailLink + ` - ${sic || ''} ${shift || ''}`;
      });
  }
}
