import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadLeg } from '@xpo-ltl-2.0/sdk-freightflow';

@Component({
  selector: 'freight-flow-load-leg',
  templateUrl: './freight-flow-load-leg.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FreightFlowPathLegComponent implements OnInit {
  @Input() loadLeg: LoadLeg;
  @Input() legIndex: number;
  @Input() previousLeg: LoadLeg;
  @Input() isLastLeg: boolean = false;

  showSic: boolean = false;
  showLastDestinationSic: boolean = false;

  ngOnInit() {
    if (this.isLastLeg) {
      this.showLastDestinationSic = this.showLastDestinationLeftContent();
    } else {
      this.showSic = this.showLoadLegLeftContent();
    }
  }

  showLoadLegLeftContent(): boolean {
    return !(
      this.legIndex > 0 &&
      ((this.previousLeg.moveLegs.length < 2 && this.previousLeg.originSicCd === this.loadLeg.originSicCd) ||
        (this.previousLeg.moveLegs.length > 1 &&
          this.previousLeg.moveLegs[this.previousLeg.moveLegs.length - 1].originSicCd === this.loadLeg.originSicCd))
    );
  }

  showLastDestinationLeftContent(): boolean {
    return !(
      (this.loadLeg.moveLegs.length < 2 && this.loadLeg.originSicCd === this.loadLeg.destSicCd) ||
      (this.loadLeg.moveLegs.length > 1 &&
        this.loadLeg.moveLegs[this.loadLeg.moveLegs.length - 1].originSicCd === this.loadLeg.destSicCd)
    );
  }
}
