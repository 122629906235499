import { Injectable } from '@angular/core';

import moment, { Moment } from 'moment';

import { AppCacheTtl } from '@app/shared/enums/app-cache-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class AppCacheService {
  private cacheObject: Object = {};

  constructor() {}

  /**
   * Sets a cache value to the Application Cache Service
   *
   * @param {string} key The key you want to associate with the value you are caching
   * @param {*} value The value you want to cache
   * @memberof AppCacheService
   */
  setCacheValue(key: string, value: any, expiration?: AppCacheTtl): void {
    this.cacheObject[key] = value;

    // TODO: If new cache values are neded, improve ttl handling and move it to it's own function.
    if (expiration === AppCacheTtl.EXPIRES_TODAY) {
      this.cacheObject[`${key}-expiration`] = moment().endOf('day');
    }
  }

  /**
   * Returns the value of a cached key based on the key provided
   *
   * @param {*} key The key of the cache you want to be returned
   * @returns {*} The value based on the key given
   * @memberof AppCacheService
   */
  getCacheValue(key): any {
    const cacheTTL: Moment = this.cacheObject[`${key}-expiration`];
    if (!cacheTTL || cacheTTL.isAfter(moment())) {
      return this.cacheObject[key];
    } else {
      return null;
    }
  }

  /**
   * Purges the value and removes the key from the cache
   *
   * @param {string} key The key you want removed from the cache along with its value
   * @memberof AppCacheService
   */
  purgeCacheValue(key: string): void {
    delete this.cacheObject[key];
  }
}
