import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-active-profile',
  templateUrl: './active-profile.component.html',
  styleUrls: ['./active-profile.component.scss'],
})
export class ActiveProfileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ActiveProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  triggerAction(response = false): void {
    this.dialogRef.close(response);
  }
}
